import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../Store/hooks";
import {
  TOtherMemberDetails,
  TTwoAdultMemberDetails,
} from "../../../../../types/Health/ProposalDeatail/TProposalDetail";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import Footer from "../../../../../Component/Footer/Footer";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { GET_DATE_VALIDATION } from "../../../../../SupportingFiles/HealthHelper";
import { CLIENTS } from "../../../../../URLCollection/Clients";

const AdultTwoDetails = ({
  fieldsUpdateState,
  validateForm,
  memberFields,
  occupationList,
  relationshipList
}: {
  fieldsUpdateState: Function;
  validateForm: Function;
  memberFields: TTwoAdultMemberDetails;
  occupationList: any
  relationshipList: any
}) => {
  const navigate = useNavigate();
  const {
    PAGE_STATUS,
    DROPDOWN_DATA,
    INSURED_MEMBER_DETAILS,
    SELECTED_QUOTE_DATA,
    ADDON_DETAILS
  } = useAppSelector((state) => state.Health);

  const DATA = useAppSelector((state) => state.CommonDropdownData);
  const { NIVA_BUPA_OCCUPATION } = useAppSelector(
    (state) => state.CommonDropdownData
  );
  const EDUCATION = [
    { label: "Matric", value: "Matric" },
    { label: "Non-Matric", value: "Non-Matric" },
    { label: "Graduate", value: "Graduate" },
    { label: "Post-Graduate", value: "Post-Graduate" },
    { label: "Professional Course", value: "Professional Course" },
  ];
  return (
    <Box>
      <h5 className="sectionTitle">
        2<sup>nd</sup> Adult Details
      </h5>
      <Grid container spacing={3}>

        {
          SELECTED_QUOTE_DATA.productDetails.id == EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.NIVA_BUPA_Senior_First_Platinum_Floater && INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED ?
            <Grid xs={3}>
              <SelectDropdown
                disabled={PAGE_STATUS}
                class_name="inputField"
                title="Relationship with Proposer"
                value={memberFields.relationship.value}
                attrName={"relationship"}
                value_update={fieldsUpdateState}
                data={relationshipList}
                warn_status={memberFields.relationship.warning}
                error_message="Select Relationship"
              />
            </Grid >
            : SELECTED_QUOTE_DATA.productDetails.id == EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
              SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.NIVA_BUPA_Senior_First_Platinum_Floater && !INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED
              ?
              <Grid xs={3}>
                <SelectDropdown
                  disabled={PAGE_STATUS}
                  class_name="inputField"
                  title="Relationship with Proposer"
                  value={memberFields.relationship.value}
                  attrName={"relationship"}
                  value_update={fieldsUpdateState}
                  data={INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED ||
                    INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Self"
                    ? [{ value: "Spouse", label: "Spouse" }]
                    : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Father"
                      ? [{ value: "Mother", label: "Mother" }]
                      : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Mother"
                        ? [{ value: "Father", label: "Father" }]
                        : SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Plan && ADDON_DETAILS.health_hospital_cash_benefit
                          ? [{ value: "Spouse", label: "Spouse" }]
                          : relationshipList
                  }
                  warn_status={memberFields.relationship.warning}
                  error_message="Select Relationship"
                />

              </Grid>
              : SELECTED_QUOTE_DATA.productDetails.id == EProductId.NIVA_BUPA_REASSURE_BRONZE ||
                SELECTED_QUOTE_DATA.productDetails.id == EProductId.NIVA_BUPA_REASSURE_PLATINUM ||
                SELECTED_QUOTE_DATA.productDetails.id == EProductId.NIVA_BUPA_REASSURE_TITANIUM
                ?
                <Grid xs={3}>
                  <SelectDropdown
                    disabled={PAGE_STATUS}
                    class_name="inputField"
                    title="Relationship with Proposer"
                    value={memberFields.relationship.value}
                    attrName={"relationship"}
                    value_update={fieldsUpdateState}
                    data={relationshipList}
                    warn_status={memberFields.relationship.warning}
                    error_message="Select Relationship"
                  />
                </Grid >
                :
                <Grid xs={3}>
                  <SelectDropdown
                    disabled={PAGE_STATUS}
                    class_name="inputField"
                    title="Relationship with Proposer"
                    value={memberFields.relationship.value}
                    attrName={"relationship"}
                    value_update={fieldsUpdateState}
                    data={INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.IS_PROPOSER_INSURED ||
                      INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Self"
                      ? [{ value: "Spouse", label: "Spouse" }]
                      : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Father"
                        ? [{ value: "Mother", label: "Mother" }]
                        : INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.relationship.value === "Mother"
                          ? [{ value: "Father", label: "Father" }]
                          : SELECTED_QUOTE_DATA.productDetails.id === EProductId.HDFC_HEALTH_Plan && ADDON_DETAILS.health_hospital_cash_benefit
                            ? [{ value: "Spouse", label: "Spouse" }]
                            : relationshipList
                    }
                    warn_status={memberFields.relationship.warning}
                    error_message="Select Relationship"
                  />
                </Grid>
        }

        <Grid xs={6}>
          <RKTextField
            validation_type="NAME"
            class_name="inputField"
            title={"Full Name"}
            value={memberFields.name.value}
            attrName={"name"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.name.warning}
            error_message="Enter Full Name"
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={3}>
          <DatePicker
            class_name="inputField"
            title={"DOB"}
            value={memberFields.dob.value}
            attrName={"dob"}
            value_update={fieldsUpdateState}
            error_message="Select DOB"
            warn_status={memberFields.dob.warning}
            date_validation_type={
              GET_DATE_VALIDATION(
                SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                `${SELECTED_QUOTE_DATA.productDetails.id}`
              ).validation_type
            }
            min_date={
              GET_DATE_VALIDATION(
                SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                `${SELECTED_QUOTE_DATA.productDetails.id}`
              ).min_date
            }
            max_date={
              GET_DATE_VALIDATION(
                SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                `${SELECTED_QUOTE_DATA.productDetails.id}`
              ).max_date
            }
            default_date={
              SELECTED_QUOTE_DATA.productDetails.id === 2843
                ? subYears(new Date(), 56)
                : subYears(new Date(), 18)
            }
          />
        </Grid>
        <Grid xs={3} className="weightField">
          <span className="weightIcon"></span>
          <RKTextField
            class_name="inputField"
            validation_type="NUMBER"
            max_length={3}
            title={"Weight (Kg)"}
            value={memberFields.weight.value}
            attrName={"weight"}
            value_update={fieldsUpdateState}
            warn_status={memberFields.weight.warning}
            error_message={"Enter Weight"}
          />
        </Grid>
        <Grid xs={5} className="heightField">
          <span className="heightIcon"></span>
          <SelectDropdown
            class_name="inputField mr-2"
            title="Feet"
            value={memberFields.heightFeet.value}
            attrName={"heightFeet"}
            error_message="Select Height Feet"
            value_update={fieldsUpdateState}
            data={DATA.HEIGHT_FEET}
            warn_status={memberFields.heightFeet.warning}
          />
          <SelectDropdown
            class_name="inputField"
            title="Inches"
            error_message="Select Height Inches"
            value={memberFields.heightInches.value}
            attrName={"heightInches"}
            value_update={fieldsUpdateState}
            data={DATA.HEIGHT_INCH}
            warn_status={memberFields.heightInches.warning}
          />
        </Grid>
        {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.NIVA_BUPA ? (
          <>
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Occupation"
                value={memberFields?.occupation.value}
                attrName={["occupation"]}
                value_update={fieldsUpdateState}
                data={occupationList}
                warn_status={memberFields?.occupation.warning}
                error_message="Select Occupation Status"
              />
            </Grid>
            <Grid xs={6} md={5} lg={4}>
              <SelectDropdown
                class_name="inputField"
                title="Qualification"
                value={memberFields?.qualification.value}
                attrName={["qualification"]}
                value_update={fieldsUpdateState}
                data={EDUCATION}
                warn_status={memberFields?.qualification.warning}
                error_message="Select Qualification Status"
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </Grid>
      <Footer
        attrName={"pageStatus"}
        textName={PAGE_STATUS === true ? "Update" : "Continue"}
        value={1}
        forward={() => validateForm()}
        backward={() => navigate(-1)}
      />
    </Box>
  );
};

export default AdultTwoDetails;
