import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { subDays, subMonths } from "date-fns";
import subYears from "date-fns/subYears";
import { useEffect, useState } from "react";
import {
  FORMAT_DATE_DD_MM_YYYY,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import moment from "moment";

/**
 * DatePicker component to display a mobile-friendly date picker.
 *
 * @param title - The title or label for the DatePicker.
 * @param value - The current value of the DatePicker.
 * @param value_update - A function to update the value of the DatePicker.
 * @param attrName - The attribute name used to identify the DatePicker.
 * @param error_message - An optional error message to display when there is an error.
 * @param warn_status - A boolean indicating whether to display a warning status.
 * @param class_name - The CSS class name for styling purposes.
 * @param min_date - The minimum number of years to subtract from the current date (default: 100).
 * @param max_date - The maximum number of years to subtract from the current date (default: 18).
 * @param dateValidation - A boolean indicating whether to validate the selected date within a range (default: false).
 * @returns The DatePicker component.
 */
const DatePicker = ({
  title,
  value,
  value_update,
  attrName,
  error_message = "Select",
  warn_status,
  class_name = "",
  min_date = 15,
  max_date = 18,
  date_validation_type = "YEARS",
  disabled = false,
  default_date = new Date(),
  tooltip = "",
}: {
  title?: string;
  value: any;
  attrName: any;
  value_update: Function;
  error_message?: string;
  warn_status?: boolean;
  class_name?: string;
  min_date?: number;
  max_date?: number;
  date_validation_type?: "YEARS" | "MONTHS" | "DAYS" | "DATE";
  disabled?: boolean;
  default_date?: Date;
  tooltip?: string;
}) => {
  const [minDate, setMinDate] = useState<Date>(new Date());
  const [maxDate, setMaxDate] = useState<Date>(new Date());
  const [dateValidation, setDateValidation] = useState<boolean>(false);

  useEffect(() => {
    if (!isEmpty(min_date) && !isEmpty(max_date)) {
      setDateValidation(true);
    } else {
      setDateValidation(false);
    }
  }, [min_date, max_date]);

  useEffect(() => {
    switch (date_validation_type) {
      case "YEARS":
        setMinDate(subYears(new Date(), min_date));
        break;
      case "MONTHS":
        setMinDate(subMonths(new Date(), min_date));
        break;
      case "DAYS":
        setMinDate(subDays(new Date(), min_date));
        break;

      default:
        break;
    }

    switch (date_validation_type) {
      case "YEARS":
        setMaxDate(subYears(new Date(), max_date));
        break;
      case "MONTHS":
        setMaxDate(subMonths(new Date(), max_date));
        break;
      case "DAYS":
        setMaxDate(subDays(new Date(), max_date));
        break;

      default:
        break;
    }
  }, [min_date, max_date, dateValidation]);

  const [localValue, setLocalValue] = useState<Date | null>(value);

  useEffect(() => {
    const momentDate = moment(FORMAT_DATE_DD_MM_YYYY(value), "DD-MM-YYYY");
    const final_date = momentDate.isValid() ? momentDate.toDate() : null;
    setLocalValue(final_date);
  }, [value]);

  return (
    <div className={`datepicker ${class_name}`}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          disabled={disabled}
          label={title}
          value={localValue}
          onChange={(selectedDate) => {
            value_update(attrName, FORMAT_DATE_DD_MM_YYYY(`${selectedDate}`));
          }}
          format="dd-MM-yyyy"
          // renderInput={(params) => (
          //   <React.Fragment>
          //     <TextField fullWidth {...params} error={false} />
          //     {!isEmpty(tooltip) ? (
          //       <Tooltip
          //         title={tooltip}
          //         sx={{
          //           position: "absolute",
          //           left: "-23px",
          //           top: "7px",
          //           background: "#fff",
          //         }}>
          //         <IconButton
          //           aria-label="info"
          //           color="primary"
          //           style={{ marginLeft: 8 }}>
          //           <InfoOutlinedIcon
          //             style={{ fontSize: 16, color: "black" }}
          //           />
          //         </IconButton>
          //       </Tooltip>
          //     ) : null}
          //   </React.Fragment>
          // )}
          closeOnSelect
          minDate={dateValidation ? minDate : undefined} // Minimum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
          maxDate={dateValidation ? maxDate : undefined} // Maximum date validation managed by the props; using subYears function to subtract a specified number of years from the current date
        />
        {warn_status ? <span className="error">{error_message}</span> : null}
      </LocalizationProvider>
    </div>
  );
};

export default DatePicker;
