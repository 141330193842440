import { createSlice } from "@reduxjs/toolkit";
import { TERM_REDUCERS } from "./TermReducers";
import { TTermSlice } from "../../../types/TTermSlice";

const initialState: TTermSlice = {
  REDIRECTED: false,
  redirection_url: "",
  LOADER: false,
  QUOTE_LOAD_FIRST_TIME: true,
  CUS_TYPE: false,
  PROPOSER_DATA: {
    pincode: { value: "", warning: false },
    qualification: { value: "", warning: false },
    diabetic: false,
    premium_loader: false,
    sum_assured: { value: "", warning: false },
    education: { value: "", warning: false },
    is_proposer_is_insured_member: true,
    full_name: { value: "", warning: false },
    dob: { value: "", warning: false },
    gender: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    aadhar_number: { value: "", warning: false },
    emergency_number: { value: "", warning: false },
    marital_status: { value: "", warning: false },
    annual_income: { value: "", warning: false },
    occupation: { value: "", warning: false },
    occupation_desc: { value: "", warning: false },
    org_description: { value: "", warning: false },
    org_name: { value: "", warning: false },
    my_profession: { value: "", warning: false },
    industry_type: { value: "", warning: false },
    industry_type_desc: { value: "", warning: false },
    org_type: { value: "", warning: false },
    org_type_desc: { value: "", warning: false },
    obj_of_buying_policy: { value: "", warning: false },
    pan_no: { value: "", warning: false },
    nationality: "indian",
    insured_name: { value: "", warning: false },
    insured_mobile: { value: "", warning: false },
    insured_email: { value: "", warning: false },
    insured_gender: { value: "", warning: false },
    insured_dob: { value: "", warning: false },
    insured_occupation: { value: "", warning: false },
    insured_qualification: { value: "", warning: false },
    insured_pincode: { value: "", warning: false },
    corporate_email_id: { value: "", warning: false },
    isSalariedDiscount: { value: false, warning: false },
    isSalariedConsent: { value: false, warning: false },
    lifeMileStoneDiscount: { value: false, warning: false },
    lifeMileStoneType: { value: "", warning: false },
    lifeMileStonedate: { value: "", warning: false },
    housewifePayerRelation: { value: "", warning: false },
    housewifePayerStatus: true,
  },
  QUOTE_FIELDS_DATA: {
    payMode: "12",
    sortBy: "Low-High",
    payUpto: "75",
    monthlyPayout: "40000",
    coverageUpto: "75",
    sumAssured: "2500000",
    planType: "1",
    income_period: "10",
  },
  DROPDOWN_DATA: {
    buyingPolicy: [],
    indsType: [],
    insuranceComp: [],
    myProf: [],
    orgType: [],
    EDUCATION_MASTER: [],
    MAX_ANNUAL_INCOME: [],
    MAX_SUM_ASSURED: [],
    PLAN_TYPE: [
      { value: "1", label: "Pure Life Cover" },
      { value: "2", label: "Income Replacement" },
      { value: "3", label: "Return of Premium" },
      { value: "4", label: "Whole Life" },
    ],
    ANNUAL_INCOME: [],
    INCOME_PERIOD: [],
    GENDER: [
      { label: "Male", value: "M" },
      { label: "Female", value: "F" },
    ],
    SMOKE: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],
    AGE: [],
    PAY_MODE_DATA: [
      { value: "12", label: "Monthly" },
      { value: "4", label: "Quarterly" },
      { value: "2", label: "Half-yearly" },
      { value: "1", label: "Yearly" },
      { value: "5", label: "Single Pay" },
    ],
    COVERAGE_UPTO: [],
    PAY_UPTO: [],
    SORT_BY: [
      { label: "Low-High", value: "Low-High" },
      { label: "High-Low", value: "High-Low" },
    ],
    MONTHLY_PAYOUT: [],
    SUM_ASSURED: [],
    OCCUPATION_DATA: [
      {
        value: "07",
        label: "Salaried",
      },
      {
        value: "08",
        label: "Self-employed",
      },
    ],
    QUALIFICATION_DATA: [],
    state: [],
    vaccine: [],
    modeTravel: [],
    purposeTravel: [],
    typeResidence: [],
    PREGNANCY_WEEKS: [],
    AADHAR_OPTIONS_DATA: [
      { value: "aadhar_no", label: "Aadhar No" },
      { value: "virtual_id", label: "Virtual ID" },
    ],
    IT_PROOF_MASTER: [
      { value: "pancard", label: "PAN" },
      { value: "form 60", label: "FORM 60" },
    ],
    IDENTITY_PROOF_MASTER: [],
    BENEFICIARY_RELATIONSHIP: [
      { value: "Daughter", label: "Daughter" },
      { value: "Son", label: "Son" },
      { value: "Wife", label: "Wife" },
    ],
    TRUSTEE_TYPE: [
      { value: "Entity", label: "Entity" },
      { value: "Individual", label: "Individual" },
    ],
    eia: [],
    genderData: [
      { value: "M", label: "Male" },
      { value: "F", label: "Female" },
    ],
    maritalStatusData: [],
    nomineeRelationshipData: [],
    occupationData: [],
    annualIncomeData: [
      { value: "2 Lakhs", label: "2 Lakhs" },
      { value: "3 Lakhs", label: "3 Lakhs" },
      { value: "4 Lakhs", label: "4 Lakhs" },
      { value: "5 Lakhs", label: "5 Lakhs" },
      { value: "6 Lakhs", label: "6 Lakhs" },
    ],
    heightFeetData: [
      { value: "1", label: "1 Feet" },
      { value: "2", label: "2 Feet" },
      { value: "3", label: "3 Feet" },
      { value: "4", label: "4 Feet" },
      { value: "5", label: "5 Feet" },
      { value: "6", label: "6 Feet" },
      { value: "7", label: "7 Feet" },
    ],
    heightInchesData: [
      { value: "1", label: "1 Inch" },
      { value: "2", label: "2 Inch" },
      { value: "3", label: "3 Inch" },
      { value: "4", label: "4 Inch" },
      { value: "5", label: "5 Inch" },
      { value: "6", label: "6 Inch" },
      { value: "7", label: "7 Inch" },
      { value: "8", label: "8 Inch" },
      { value: "9", label: "9 Inch" },
      { value: "10", label: "10 Inch" },
      { value: "11", label: "11 Inch" },
    ],
    insurance_company: [],
    alcohol: [],
    tobacco: [],
    addressProf: [{ value: "Adhr", label: "Aadhar card" }],
    ageProf: [],
    identityProf: [],
    incomeProf: [],
    income_tax_proof: [{ value: "pancard", label: "Pan Card" }],
    country: [],
    HDFC_CKYC_OCCUPATION: [
      { value: "Service - Public Sector", label: "Service - Public Sector" },
      {
        value: "Service - Goverment Sector",
        label: "Service - Goverment Sector",
      },
      { value: "Service - Private Sector", label: "Service - Private Sector" },
    ],
    HDFC_MEDICAL_DESIGNATION: [
      { value: "OCCUP_AVVP_CD", label: "AVP" },
      { value: "OCCUP_AGAO_CD", label: "Agriculture Farmer" },
      { value: "OCCUP_AUDI_CD", label: "Auditor" },
      { value: "OCCUP_ADAA_CD", label: "Business analyst" },
      { value: "OCCUP_CEOF_CD", label: "CEO" },
      { value: "OCCUP_PRON_CD", label: "Chartered Accountant" },
      { value: "OCCUP_DIR_CD", label: "Director" },
      { value: "OCCUP_DOCT_CD", label: "Doctor" },
      { value: "OCCUP_ENGR_CD", label: "Engineer" },
      { value: "OCCUP_ADMV_CD", label: "General manager" },
      { value: "OCCUP_HEAY_CD", label: "Head Of Department" },
      { value: "OCCUP_MGR_CD", label: "Manager" },
      { value: "OCCUP_MGRD_CD", label: "Managing director" },
      { value: "OCCUP_OTHR_CD", label: "Other Occupation" },
      { value: "OCCUP_BOND_CD", label: "Properietor" },
      { value: "OCCUP_SENT_CD", label: "Senior Accountant" },
      { value: "OCCUP_SRMR_CD", label: "Senior Manager" },
      { value: "OCCUP_SOFE_CD", label: "Software Engineer" },
      { value: "OCCUP_SWPR_CD", label: "Software Professional" },
      { value: "OCCUP_STDN_CD", label: "Student" },
      { value: "OCCUP_SINS_CD", label: "Sub Inspector" },
      { value: "OCCUP_SYAN_CD", label: "System Analyst" },
      { value: "OCCUP_ADM1_CD", label: "Vice president" },
    ],
    AGE_AT_DIAGNOSIS: [],
    COMPANY_BELONGS: [],
    CONSUME_ALCOHOL: [
      { value: "AT_BEER", label: "Beer" },
      { value: "AT_WINE", label: "Wine" },
      { value: "AT_SPIRIT", label: "Spirit" },
      { value: "AT_OTH", label: "Other" },
    ],
    HDFC_ALIVE: [
      { value: "FAD_ALIVE", label: "Alive" },
      { value: "FAD_DESD", label: "Deceased" },
    ],
    HDFC_COUNTRIES: [],
    HDFC_CURRENT_AGE: [],
    HDFC_DISEASE: [],
    HOBBIES: [],
    MAY_UNDERGO_MEDICAL_EXAMINATION: [
      { value: "MEL_RES", label: "Residence" },
      { value: "MEL_WP", label: "Workplace" },
    ],
    NATURE_OF_WORK: [
      { value: "NATW_ADMIN", label: "Administrator" },
      { value: "NATW_CLRC", label: "Clerical" },
      { value: "NATW_LABOR", label: "Manual" },
      { value: "NATW_MANG", label: "Manager" },
      { value: "NATW_MECH", label: "Mechanical" },
      { value: "NATW_SPVR", label: "Supervisor" },
    ],
    RELATION_OF_LIFE_TO_BE_ASSURED: [
      { value: "MGM_BRO", label: "Brother" },
      { value: "MGM_FATH", label: "Father" },
      { value: "MGM_MOTH", label: "Mother" },
      { value: "MGM_SIS", label: "Sister" },
    ],
    SELECT_ANY_SIMULTANEOUS: [
      { value: "SAD_RLP", label: "Reviving lapsed policies" },
      { value: "SAD_SSA", label: "Submitting Simultaneous Applications" },
      { value: "SAD_NOT", label: "Neither of these" },
    ],
    TOBACCO_PRODUCT: [
      { value: "TT_BD", label: "Bidi" },
      { value: "TT_CGR", label: "Cigar" },
      { value: "TT_CGRT", label: "Cigarette" },
      { value: "TT_CT", label: "Chewing Tobaccoher" },
      { value: "TT_NRC", label: "Narcotics" },
    ],
    HDFC_HEIGHT: [],
    HDFC_WEIGHT: [],
    have_you_suffered_from_one: [
      {
        value: "Diabetes/ high blood sugar/ sugar in urine",
        label: "Diabetes/ high blood sugar/ sugar in urine",
      },
      {
        value: "High blood pressure/ hypertension",
        label: "High blood pressure/ hypertension",
      },
      { value: "Heart disease", label: "Heart disease" },
      { value: "Stroke", label: "Stroke" },
    ],
    have_you_suffered_from_two: [
      {
        value: "Respiratory disorders, Arthritis",
        label: "Respiratory disorders, Arthritis",
      },
      {
        value: "Arthritis",
        label: "Arthritis",
      },
      { value: "Back problems", label: "Back problems" },
      { value: "Tuberculosis", label: "Tuberculosis" },
      {
        value: "Any recurrent medical condition/ disability",
        label: "Any recurrent medical condition/ disability",
      },
    ],

    have_you_suffered_from_three: [
      { value: "Liver disorder", label: "Liver disorder" },
      { value: "Kidney disorder", label: "Kidney disorder" },
      {
        value: "Disorder of the digestive system",
        label: "Disorder of the digestive system",
      },
      { value: "Abnormality of thyroid", label: "Abnormality of thyroid" },
      { value: "Blood disorder", label: "Blood disorder" },
    ],

    have_you_suffered_from_four: [
      { value: "Epilepsy", label: "Epilepsy" },
      {
        value: "Any nervous disorder or mental condition",
        label: "Any nervous disorder or mental condition",
      },
      {
        value: "Paralysis or multiple sclerosis",
        label: "Paralysis or multiple sclerosis",
      },
      {
        value: "Depression or psychiatric disorder",
        label: "Depression or psychiatric disorder",
      },
      { value: "Cancer or a tumor", label: "Cancer or a tumor" },
    ],

    have_you_suffered_from_five: [
      { value: "Dengue", label: "Dengue" },
      { value: "Swine Flu", label: "Swine Flu" },
      { value: "Encephalitis", label: "Encephalitis" },
    ],
  },
  ADD_FORM: {
    annualIncome: { value: "", warning: false },
    smoke: "N",
    gender: { value: "M", warning: false },
    age: { value: "", warning: false },
    pincode: { value: "", warning: false },
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
  },
  ADD_FORM_RESPONSE: {
    quote_no: "",
    module: "",
    insurance_type: "",
    sum_assured: "",
    pincode: "",
    annual_income: "",
    name: "",
    email: "",
    mobile: "",
    city: "",
    state: "",
    max_sum_assured: 180000000,
    gender: "M",
    smoker: "Y",
    term: "",
    age: "21",
    dob_date: "",
    dob_month: "",
    dob_year: "",
    cover_upto: 75,
    frequency: 12,
    income: "6000000",
    cover_pay_upto: "75",
    policy_covers: 1,
    monthly_payout: 40000,
    income_period: 15,
    increasing_level: 0,
    utm_medium: "",
    utm_source: "",
  },
  SELECTED_QUOTE_DATA: {
    documentURL: "",
    transaction_id: "",
    appno: "",
    premiumDetails: {
      income_period: "",
      modal_factor: "",
      product_desc: "",
      term: "",
      pay_term: "",
      sum_assured: 0,
      wop_rider_premium: 0,
      wop_rider_gst: 0,
      wopPremium: 0,
      ci_rider_premium: 0,
      ci_rider_gst: 0,
      ciPremium: 0,
      acc_rider_premium: 0,
      acc_rider_gst: 0,
      accPremium: 0,
      income: "",
      premium: 0,
      riders: {
        TF001: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF003: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF002: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF004: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF005: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
      },
      premiumAmount: 0,
      Total_basePremium: 0,
      Total_taxAmount: 0,
      taxAmount: "",
      finalPremium: 0,
      annunalTargetPremium: 0,
      product_id: 0,
      product_code: "",
      cover_pay_upto: "",
      frequency: "",
      smoker: "",
      cover_upto: "",
      policy_covers: 0,
    },
    companyDetails: {
      company_code: "",
      name: "",
      short_desc: "",
      logo: "",
      claim_ratio: "",
    },
    SpecialFeatureLists: [],
    productDetailLists: null,
    buyOnlineLink: {
      method: "",
      buy_online_link: "",
      redirection_type: "",
    },
    productDetails: {
      id: 0,
      product_name: "",
      brochure: "",
      product_code: "",
      rate_calc_method: "",
      proposal_method: "",
      tax_calc_method: "",
      discount_calc_method: "",
      rating: 0,
      product_desc: "",
      buy_online_code: "",
      special_feature_codes: "",
      prem_calc_action: "",
      tax_calc_action: "",
      discount_calc_action: "",
      frequency: "0",
      term_form_validation: {
        modal_factor: 0,
        min_sum_assured: 0,
        max_sum_assured: 0,
        min_age: 0,
        max_age: 0,
        min_term: 0,
        max_term: 0,
        min_p_cess_age: 0,
        max_p_cess_age: 0,
      },
      CompanyProductMaps: {
        company_code: "",
      },
    },
    sumAssured: "",
    QuoteNo: "",
  },
  RIDERS_BENEFITS: {
    riders_benefits_response: {
      redirectionUrl: "",
      base_rate: 0,
      modal_factor: "",
      conversion_factor: 0,
      premiumAmount: 0,
      finalPremium: 0,
      pay_term: "",
      term: "",
      appno: "",
      quote_id: "",
      uwtype: "",
      policyPdf: "",
      riders: {
        TF001: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 0,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF002: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 427,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF003: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 427,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF004: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 427,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
        TF005: {
          sum_assured: 0,
          min_sa_percentage: 0,
          max_sa_percentage: 0,
          sa_percentage: 0,
          interval: 0,
          premium: 427,
          min_sum_assured: 0,
          max_sum_assured: 0,
          description: "",
          free: 0,
          rider_pay_term: "",
          rider_term: "",
          show_sum_insured: "",
          status: "",
        },
      },
      quote_no: "",
      sum_insured: "",
      frequency: "",
    },
    local_value: {
      tf001Status: false,
      tf001Value: "1000000",
      tf002Status: false,
      tf002Value: "1000000",
      tf003Status: false,
      tf003Value: "1000000",
      tf004Status: false,
      tf004Value: "1000000",
      tf005Status: false,
      tf005Value: "1000000",
      BNF001Status: false,
      BNF002Status: false,
      BNF003Status: false,
    },
  },
};

const ageData = [];
for (let i = 18; i <= 65; i++) {
  ageData.push({
    label: `${i}`,
    value: `${i} Years`,
  });
}

initialState.DROPDOWN_DATA.AGE = ageData;

let incomePeriodData = [];
for (let i = 1; i <= 30; i++) {
  incomePeriodData.push({
    label: `${i} Years`,
    value: `${i}`,
  });
}

initialState.DROPDOWN_DATA.INCOME_PERIOD = incomePeriodData;

let annualIncome_data = [];
for (let incomeValue = 200000; incomeValue <= 10000000; incomeValue += 100000) {
  let label;

  if (incomeValue === 10000000) {
    label = "1 Crore";
  } else {
    label = `${incomeValue / 100000} Lakh`;
  }

  annualIncome_data.push({ label: label, value: `${incomeValue}` });
}
initialState.DROPDOWN_DATA.ANNUAL_INCOME = annualIncome_data;

export const TermSlice = createSlice({
  name: "Term",
  initialState,
  reducers: TERM_REDUCERS,
});

export default TermSlice.reducer;
