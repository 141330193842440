import { useEffect, useState } from "react";
import { EMotorCompCode } from "../../Enum/EMotorCompCode";
import PaymentConfNew from "../../Page/Desktop/Common/PaymentConf/PaymentConf_New";
import PaymentConfGoDigit from "../../Page/Desktop/Common/PaymentConfGoDigit/PaymentConfGoDigit";
import MPaymentConf from "../../Page/Mobile/Common/MPaymentConf/MPaymentConf";
import {
  PREMIUM_CONFIRMATION_SERVICES,
  TPaymentConf,
} from "../../Services/PremiumConf/PremiumConfServices";
import { GET_CURRENT_DATE } from "../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { SendEventToApp } from "../../SupportingFiles/SendEventToApp";
import { CLIENTS } from "../../URLCollection/Clients";
import {
  pushDataLayer,
  pushMotorAdditionalDataLayer,
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../utils/analytics";
import { useAppSelector } from "../../Store/hooks";

export type TPaymentResponse = {
  error: boolean;
  policy_no: string;
  policy_status: string;
  document_url: string;
  payment_status: string;
  customer_name: string;
  proposal_no: string;
  plan: string;
  logo: string;
};

function PaymentConfContainer() {
  const isMobile = useIsMobile();
  const [response, setResponse] = useState<TPaymentResponse>({
    policy_no: "",
    policy_status: "",
    document_url: "",
    payment_status: "",
    customer_name: "",
    proposal_no: "",
    logo: "",
    plan: "",
    error: false,
  });
  const [paymentStatus, setPaymentStatus] = useState<boolean>(true);
  const [KYCStatus, setKYCStatus] = useState<boolean>(true);
  const [companyCode, setCompanyCode] = useState<string>("");
  const { Car, TW, Health, Term } = useAppSelector((state) => state);
  const [insuranceType, setInsuranceType] = useState<string | null>("");
  const [loader, setLoader] = useState<boolean>(true);

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);

    const quote_no = params.get("quote_no");
    const product_code = params.get("product_code");
    const company_code = params.get("company_code");
    const transaction_id = params.get("transaction_id");
    const insurance_type = params.get("insurance_type");
    const utm_medium = params.get("utm_medium");
    const utm_source = params.get("utm_source");

    setInsuranceType(insurance_type);

    if (
      quote_no &&
      product_code &&
      company_code &&
      transaction_id &&
      insurance_type
    ) {
      let data: TPaymentConf;

      data = {
        quote_no: quote_no,
        product_code: product_code,
        company_code: company_code,
        transaction_id: transaction_id,
        insurance_type: insurance_type,
        payment_date: GET_CURRENT_DATE(),
        utm_medium: utm_medium ?? "",
        utm_source: utm_source ?? "",
      };

      setCompanyCode(company_code);
      GET_PREMIUM_CONFIRMATION(data);
    } else {
      // toast.error
    }
  }, []);

  const GET_PREMIUM_CONFIRMATION = (params: TPaymentConf) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res?.results;
      const error = results?.error;
      const message = results?.message;
      const response = results?.response;

      if (error) {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorAdditionalDataLayer(
            "jioinsure_flow_policies",
            "failure",
            `${response?.policy_status}`,
            `${params?.insurance_type.toLowerCase()}`,
            `${response?.company_desc}`,
            params?.insurance_type === "CAR"
              ? Car.REDIRECTED
                ? "JFS app"
                : "Insure website"
              : params?.insurance_type === "TW"
              ? TW.REDIRECTED
                ? "JFS app"
                : "Insure website"
              : ""
          );
          const eventProps = {
            action: "payment confirmation status",
            status: "failure",
            message: `${response?.policy_status}`,
            type: `${params?.insurance_type.toLowerCase()}`,
            planName: `${response?.company_desc}`,
            EntrySource:
              params?.insurance_type === "CAR"
                ? Car.REDIRECTED
                  ? "JFS app"
                  : "Insure website"
                : params?.insurance_type === "TW"
                ? TW.REDIRECTED
                  ? "JFS app"
                  : "Insure website"
                : "",
          };
          sendEventToNativeApp("jioinsure_flow_policies", eventProps);
        }
      } else {
        if (window.location.hostname === CLIENTS.JIO) {
          pushMotorAdditionalDataLayer(
            "jioinsure_flow_policies",
            "success",
            "na",
            `${params?.insurance_type.toLowerCase()}`,
            `${response?.company_desc}`,
            params?.insurance_type === "CAR"
              ? Car.REDIRECTED
                ? "JFS app"
                : "Insure website"
              : params?.insurance_type === "TW"
              ? TW.REDIRECTED
                ? "JFS app"
                : "Insure website"
              : ""
          );
          const eventProps = {
            action: "payment confirmation status",
            status: "success",
            message: "na",
            type: `${params?.insurance_type.toLowerCase()}`,
            planName: `${response?.company_desc}`,
            EntrySource:
              params?.insurance_type === "CAR"
                ? Car.REDIRECTED
                  ? "JFS app"
                  : "Insure website"
                : params?.insurance_type === "TW"
                ? TW.REDIRECTED
                  ? "JFS app"
                  : "Insure website"
                : "",
          };
          sendEventToNativeApp("jioinsure_flow_policies", eventProps);
        }

        setResponse({ ...response, error: error });
        setKYCStatus(`${response?.kyc_status}`.toLowerCase() !== "not_done");
      }

      if (error) {
        setPaymentStatus(false);
      } else {
        setPaymentStatus(
          `${response.payment_status}`.toLowerCase() === "success" ||
            `${response.policy_status}`.toLowerCase() === "success" ||
            `${response.policy_status}`.toLowerCase() === "issued" ||
            `${response.policy_status}`.toLowerCase() === "under writing" ||
            response.payment_status === ""
        );
      }
    };
    const onError = (err: any) => {
      setLoader(false);
      console.log(`${err}`);
    };

    PREMIUM_CONFIRMATION_SERVICES.GET_PREMIUM_CONF(onSuccess, onError, params);
  };

  const DOWNLOAD_POLICY = () => {
    window.open(response?.document_url, "_blank");

    SendEventToApp({
      type: "launchbrowsertab",
      value: `${response?.document_url}`,
    });
  };

  return (
    <>
      {isMobile ? (
        <MPaymentConf
          paymentResponse={response}
          paymentStatus={paymentStatus}
          DOWNLOAD_POLICY={DOWNLOAD_POLICY}
          loader={loader}
        />
      ) : (
        <>
          {companyCode === EMotorCompCode.GO_DIGIT ? (
            <PaymentConfGoDigit
              paymentStatus={paymentStatus}
              paymentResponse={response}
              DOWNLOAD_POLICY={DOWNLOAD_POLICY}
              KYCStatus={KYCStatus}
            />
          ) : (
            <PaymentConfNew
              paymentStatus={paymentStatus}
              paymentResponse={response}
              DOWNLOAD_POLICY={DOWNLOAD_POLICY}
              loader={loader}
            />
          )}
        </>
      )}
    </>
  );
}

export default PaymentConfContainer;
