import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import ICICIManualKYC from "../../../Page/Desktop/CKYC/ICICI/ICICIManualKYC";
import MICICIManualKYC from "../../../Page/Mobile/CKYC/ICICI/MICICIManualKYC";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { TW_ROUTES } from "../../../Router/Path/TWRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { TWSlice } from "../../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TManualCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import {
  pushDataLayer,
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";
import { CLIENTS } from "../../../URLCollection/Clients";

function ICICIManualKYCContainer() {
  const [loader, setLoader] = useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const REDUX_HEAD = useAppSelector((state) => state);
  const { TW, CKYC, Car, Health } = useAppSelector((state) => state);
  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const [formDetails, setFormDetails] = useState<TManualCKYCForm>({
    customer_type: { value: "Individual", warning: false },
    dob: { value: "", warning: false },
    doc_type_id_proof: { value: "", warning: false },
    doc_no_id_proof: { value: "", warning: false },
    doc_id_proof_image: { value: "", warning: false },
    full_name: { value: "", warning: false },
    gender: { value: "", warning: false },
    doc_address_proof_image: { value: "", warning: false },
    doc_type_address_proof: { value: "", warning: false },
  });
  const [pageStatus, setPageStatus] = useState<string>("");

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");

    if (type) {
      setPageStatus(type);
    }
  }, []);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else if (
      attrName === "doc_address_proof_image" ||
      attrName === "doc_id_proof_image"
    ) {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: false },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  useEffect(() => {
    setFormDetails((prev) => ({
      ...prev,
      customer_type: REDUX_HEAD.CKYC.VERIFY_KYC_FORM.customer_type,
    }));
  }, []);

  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
  }, []);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};

    switch (pageStatus) {
      case "CAR":
        const car_params = {
          company_code: `${REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, car_params);
        break;
      case "TW":
        const tw_params = {
          company_code: `${REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, tw_params);
        break;
      case "TERM":
        break;
      case "HP":
        const health_params = {
          company_code: `${REDUX_HEAD.Health.SELECTED_QUOTE_DATA.CompanyDetails.company_code}`,
          list_for: TYPE,
        };
        CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, health_params);
        break;

      default:
        break;
    }
  };

  const validateForm = () => {
    let data: TManualCKYCForm = { ...formDetails };

    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: { ...data.dob, warning: isEmpty(data.dob.value) },
      doc_type_id_proof: {
        ...data.doc_type_id_proof,
        warning: isEmpty(data.doc_type_id_proof.value),
      },
      doc_no_id_proof: {
        ...data.doc_no_id_proof,
        warning: isEmpty(data.doc_no_id_proof.value),
      },
      doc_id_proof_image: {
        ...data.doc_id_proof_image,
        warning: isEmpty(data.doc_id_proof_image.value),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: { ...data.gender, warning: isEmpty(data.gender.value) },
      doc_type_address_proof: {
        ...data.doc_type_address_proof,
        warning: isEmpty(data.doc_type_address_proof.value),
      },
      doc_address_proof_image: {
        ...data.doc_address_proof_image,
        warning: isEmpty(data.doc_address_proof_image.value),
      },
    };

    setFormDetails(data);

    let hasError =
      data.full_name.warning ||
      data.doc_type_id_proof.warning ||
      data.doc_no_id_proof.warning ||
      data.doc_id_proof_image.warning ||
      data.customer_type.warning ||
      data.dob.warning ||
      data.doc_type_address_proof.warning ||
      data.doc_address_proof_image.warning ||
      data.doc_type_address_proof.warning ||
      data.doc_address_proof_image.warning ||
      data.doc_id_proof_image.warning;

    if (data.customer_type.value !== "Organization") {
      hasError = hasError || data.gender.warning;
    }

    if (
      data.doc_id_proof_image.warning ||
      data.doc_address_proof_image.warning
    ) {
      toast.error("Please attach documents");
    }

    if (!hasError) {
      MANUAL_CKYC_DETAILS(data);
    }
  };

  const MANUAL_CKYC_DETAILS = (data: TManualCKYCForm) => {
    const onSuccess = (res: any) => {
      setLoader(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details failure",
              `${message}`,
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),

              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details failure",
              message: `${message}`,
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          const kycManualURL = response.kycManualURL;
          setShowManualPopup(true);
        } else {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details success",
              "na",
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),

              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details success",
              message: "na",
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        const message = results.message;
        toast.error(`${message}`);
        setShowManualPopup(false);
      }
    };

    const onError = (err: any) => {
      setLoader(false);
      console.log(err);
    };

    setLoader(true);
    switch (pageStatus) {
      case "TW":
        let tw_dto = {
          quote_no: TW.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_id_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          insuranceType: "TW",
          correlationId:
            REDUX_HEAD.TW.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
              .correlationId,
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          section: "premium_details",
          insurance_type: pageStatus,
        };

        CKYCServcies.ICICI_MANUAL_KYC(onSuccess, onError, tw_dto);
        break;

      case "CAR":
        let car_dto = {
          quote_no: Car.ADD_FORM_RESPONSE.quote_no,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_id_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          insuranceType: "CAR",
          correlationId:
            REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
              .correlationId,
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          section: "premium_details",
          insurance_type: pageStatus,
        };

        CKYCServcies.ICICI_MANUAL_KYC(onSuccess, onError, car_dto);
        break;
      case "HP":
        let health_dto = {
          quote_no: Health.ADD_FORM_RESPONSE.quote_no,
          product_code: Health.SELECTED_QUOTE_DATA.productDetails.product_code,
          cusType: data.customer_type.value,
          kycCusName: data.full_name.value,
          dobForKyc: data.dob.value,
          docNameForKyc: data.doc_type_id_proof.value,
          docIDNoForKyc: data.doc_no_id_proof.value,
          genderForKyc: data.gender.value,
          insuranceType: "health",
          correlationId:
            REDUX_HEAD.Car.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails
              .correlationId,
          ckycdetails: "doc",
          docNameForKyc1: data.doc_type_address_proof.value,
          indetityDocument: data.doc_id_proof_image.value,
          addressDocument: data.doc_address_proof_image.value,
          section: "premium_details",
          product_id: Health.SELECTED_QUOTE_DATA.productDetails.id,
        };

        CKYCServcies.ICICI_MANUAL_KYC(onSuccess, onError, health_dto);
        break;
      default:
        break;
    }
  };

  const CONTINUE_TO_PROPOSAL = () => {
    switch (pageStatus) {
      case "TW":
        dispatch(
          TWSlice.actions.BULK_UPDATE({
            ...TW,
            OWNER_DETAILS: {
              ...TW.OWNER_DETAILS,
              name: {
                value: CKYC.CKYC_DETAILS.kycCustomerName,
                warning: false,
              },
              gender: {
                value: CKYC.VERIFY_KYC_FORM.gender.value,
                warning: false,
              },
              dob: {
                value: CKYC.CKYC_DETAILS.kycDOB,
                warning: false,
              },
              mobile: {
                value: TW.ADD_FORM.mobile.value,
                warning: false,
              },
            },
            ADDRESS_DETAILS: {
              ...TW.ADDRESS_DETAILS,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: `${CKYC.CKYC_DETAILS.kycAddress2}, ${CKYC.CKYC_DETAILS.kycAddress3}`,
                warning: false,
              },
              pincode: {
                value: CKYC.CKYC_DETAILS.kycPincode,
                warning: false,
              },
              state: CKYC.CKYC_DETAILS.kycState,
              city: CKYC.CKYC_DETAILS.kycCity,
            },
          })
        );

        navigate(
          `${TW_ROUTES.PROPOSAL_PAGE}/${TW_ROUTES.OWNER_DETAILS}?type=TW&companyCode=${TW?.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${TW?.ADD_FORM_RESPONSE.quote_no}&product_id=${TW?.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
        break;
      case "CAR":
        dispatch(
          CarSlice.actions.BULK_UPDATE({
            ...Car,
            OWNER_DETAILS: {
              ...Car.OWNER_DETAILS,
              name: {
                value: CKYC.CKYC_DETAILS.kycCustomerName,
                warning: false,
              },
              gender: {
                value: CKYC.VERIFY_KYC_FORM.gender.value,
                warning: false,
              },
              dob: {
                value: CKYC.CKYC_DETAILS.kycDOB,
                warning: false,
              },
              mobile: {
                value: Car.ADD_FORM.mobile.value,
                warning: false,
              },
            },
            ADDRESS_DETAILS: {
              ...Car.ADDRESS_DETAILS,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: `${CKYC.CKYC_DETAILS.kycAddress2}, ${CKYC.CKYC_DETAILS.kycAddress3}`,
                warning: false,
              },
              pincode: {
                value: CKYC.CKYC_DETAILS.kycPincode,
                warning: false,
              },
              state: CKYC.CKYC_DETAILS.kycState,
              city: CKYC.CKYC_DETAILS.kycCity,
            },
          })
        );
        navigate(
          `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.OWNER_DETAILS}?type=CAR&companyCode=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${Car?.ADD_FORM_RESPONSE.quote_no}&product_id=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
        );
        break;
      case "HP":
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              PROPOSER_DETAILS: {
                ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
                name: {
                  value: CKYC.CKYC_DETAILS.kycCustomerName,
                  warning: false,
                },
                dob: {
                  value: CKYC.CKYC_DETAILS.kycDOB,
                  warning: false,
                },
                mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
                emergencyPhone: { value: "", warning: false },
                email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
                aadhar: { value: "", warning: false },
                gender: {
                  value: VERIFY_KYC_FORM.gender.value,
                  warning: false,
                },
                maritalStatus: { value: "", warning: false },
                weight: { value: "", warning: false },
                heightFeet: { value: "", warning: false },
                heightInches: { value: "", warning: false },
                occupation: { value: "", warning: false },
                relationship: { value: "", warning: false },
                proposer_name: { value: "", warning: false },
              },
            },
            ADDRESS_DETAILS: {
              ...Health.ADDRESS_DETAILS,
              pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
              city: CKYC.CKYC_DETAILS.kycCity,
              state: CKYC.CKYC_DETAILS.kycState,
              addressLineOne: {
                value: CKYC.CKYC_DETAILS.kycAddress1,
                warning: false,
              },
              addressLineTwo: {
                value: CKYC.CKYC_DETAILS.kycAddress2,
                warning: false,
              },
              isMailingAddressSame: true,
              mailingPinCode: { value: "", warning: false },
              mailingCity: "",
              mailingState: "",
              mailingAddressLineOne: { value: "", warning: false },
              mailingAddressLineTwo: { value: "", warning: false },
            },
          })
        );

        navigate(
          // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
          `${`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}`}`
        );

        break;
      default:
        break;
    }
  };
  return (
    <>
      {isMobile ? (
        <MICICIManualKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      ) : (
        <ICICIManualKYC
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          setShowManualPopup={setShowManualPopup}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          loader={loader}
        />
      )}
    </>
  );
}

export default ICICIManualKYCContainer;
