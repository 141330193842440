import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import Footer from "../../../../../Component/Footer/Footer";
import { useAppSelector } from "../../../../../Store/hooks";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TBank_Details_HDFC } from "../../../../../types/Health/THealthSlice";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import { subYears } from "date-fns";

const BankDetails = ({
  Bank_Details_HDFC,
  updateBankDetailHDFC,
  disbaledButton,
  validateForm,
}: {
  Bank_Details_HDFC: TBank_Details_HDFC
  updateBankDetailHDFC: Function;
  validateForm: Function;
  disbaledButton: boolean;
}) => {
  const {
    PAGE_STATUS,
  } = useAppSelector((state) => state.Health);
console.log("bank details")
  return (
    <>
      <Box>
        <h5 className="sectionTitle">Bank Details</h5>
        <Grid container spacing={3}>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Name In Bank Account"}
              value={Bank_Details_HDFC?.Name_In_Bank_Account?.value}
              attrName={"Name_In_Bank_Account"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Name_In_Bank_Account?.warning}
              error_message={"Enter Bank Account Name"}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Bank Account No"}
              value={Bank_Details_HDFC?.Bank_Account_No?.value}
              attrName={"Bank_Account_No"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Bank_Account_No?.warning}
              error_message={"Enter Bank Account Number"}
              max_length={21}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Bank Name"}
              value={Bank_Details_HDFC?.Bank_Name?.value}
              attrName={"Bank_Name"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Bank_Name?.warning}
              error_message={"Enter Bank Name"}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"IFSC Code"}
              value={Bank_Details_HDFC?.IFSC_Code?.value}
              attrName={"IFSC_Code"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.IFSC_Code?.warning}
              error_message={
                isEmpty(Bank_Details_HDFC?.IFSC_Code?.value)
                  ? "Enter IFSC Code"
                  : "Enter Valid IFSC Code"
              }
              max_length={11}
            />
          </Grid>

          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Cheque No"}
              value={Bank_Details_HDFC?.Cheque_No?.value}
              attrName={"Cheque_No"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Cheque_No?.warning}
              error_message={"Enter Cheque No"}
              max_length={21}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
            <DatePicker
              class_name="inputField"
              title={"Cheque Date"}
              value={Bank_Details_HDFC?.Cheque_Date?.value}
              attrName={"Cheque_Date"}
              date_validation_type="YEARS"
              min_date={100}
              max_date={18}
              default_date={subYears(new Date(), 18)}
              value_update={updateBankDetailHDFC}
              error_message={"Enter Cheque Date"}
              warn_status={Bank_Details_HDFC?.Cheque_Date?.warning}
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Cheque Amount For"}
              value={Bank_Details_HDFC?.Cheque_Amount_For?.value}
              attrName={"Cheque_Amount_For"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Cheque_Amount_For?.warning}
              error_message={"Enter Cheque Amount For"}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"MICR Code"}
              value={Bank_Details_HDFC?.MICR_Code?.value}
              attrName={"MICR_Code"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.MICR_Code?.warning}
              error_message={"Enter MICR Code"}
              max_length={21}
              validation_type="NUMBER"
            />
          </Grid>
          <Grid xs={6}>
            <RKTextField
              class_name="inputField"
              title={"Branch Name"}
              value={Bank_Details_HDFC?.Branch_Name?.value}
              attrName={"Branch_Name"}
              value_update={updateBankDetailHDFC}
              warn_status={Bank_Details_HDFC?.Branch_Name?.warning}
              error_message={"Enter Branch Name"}
              max_length={21}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={3}>
          <Grid xs={12}>
            <FormControlLabel
              className="proposalCheckBoxWrapper"
              control={
                <Checkbox
                  disabled={PAGE_STATUS}
                  defaultChecked={false}
                  value={false}
                  onChange={(e: any, v: any) => {
                    updateBankDetailHDFC("DispatchFlag", v);
                  }}
                />
              }
              label="Additionally, by ticking the check box we understand that you wish to have a physical copy of your policy."
            />
          </Grid>
        </Grid> */}
        <Footer
          attrName={"pageStatus"}
          textName={PAGE_STATUS ? "Update" : "Continue"}
          value={1}
          value_update={() => { }}
          forward={() => {
            validateForm();
          }}
          disableButton={disbaledButton}
        />
      </Box>
    </>

  )
}

export default BankDetails