import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import * as React from "react";
import { useAppSelector } from "../../Store/hooks";
import { isEmpty } from "../../SupportingFiles/HelpingFunction";
import { CLIENTS } from "../../URLCollection/Clients";
import { CRM_DOMAIN, FRONTEND_DOMAIN } from "../../URLCollection/Domain";
import "../Navbar/Navbar.scss";

const Navbar: React.FC = () => {
  const [logoSrc, setLogoSrc] = React.useState<string>("");
  const { TW, Car } = useAppSelector((state) => state);

  const imagePaths = {
    [CLIENTS.AMAZE]: [
      "../images/clients/bimastreet.svg",
      "./images/clients/bimastreet.svg",
    ],
    [CLIENTS.JIO]: ["../images/clients/jio.svg", "./images/clients/jio.svg"],
    [CLIENTS.MOTILAL]: [
      "../images/clients/motilal.svg",
      "./images/clients/motilal.svg",
    ],
    [CLIENTS.MOTILAL_PHP]: [
      "../images/clients/motilal.svg",
      "./images/clients/motilal.svg",
    ],
    [CLIENTS.MUTHOOT]: [
      "../images/clients/muthoot.svg",
      "./images/clients/muthoot.svg",
    ],
    [CLIENTS.CLICKPOLICY]: [
      "../images/clients/1clickpolicy_logo.svg",
      "./images/clients/1clickpolicy_logo.svg",
    ],
    [CLIENTS.POLICYPARIVAAR]: [
      "../images/clients/policyparivaar-logo.svg",
      "./images/clients/policyparivaar-logo.svg",
    ],
    [CLIENTS.UNISON]: [
      "../images/clients/unison-logo.svg",
      "./images/clients/unison-logo.svg",
    ],
    [CLIENTS.BESECURED]: [
      "../images/clients/beScured_logo.svg",
      "./images/clients/beScured_logo.svg",
    ],
    [CLIENTS.WALNNUT]: [
      "../images/clients/jb-boda-logo.svg",
      "./images/clients/jb-boda-logo.svg",
    ],
    [CLIENTS.AHALIA]: [
      "../images/clients/policyfirst-logo.svg",
      "./images/clients/policyfirst-logo.svg",
    ],
    // Add more clients as needed
  };

  React.useEffect(() => {
    const hostname = window.location.hostname;
    const navbar = document.querySelector(".proposalNavbar");

    if (navbar !== null) {
      const paths = imagePaths[hostname];

      if (paths) {
        paths.forEach((path) => {
          const img = new Image();
          img.src = path;
          img.onload = () => setLogoSrc(path);
        });
      } else {
        console.warn(`No logo paths defined for hostname: ${hostname}`);
      }
    }
  }, [window.location.hostname]);

  const handleLogoClick = () => {
    if (window.location.hostname === CLIENTS.JIO) {
      return;
    }
    if (
      isEmpty(TW.ADD_FORM_RESPONSE.utm_medium) &&
      isEmpty(Car.ADD_FORM_RESPONSE.utm_medium)
    ) {
      window.location.href = FRONTEND_DOMAIN;
    } else {
      window.location.href = CRM_DOMAIN;
    }
  };

  return (
    <Box className="proposalNavbar">
      <Grid container columnSpacing={3} paddingTop={0} paddingBottom={0}>
        <Grid xs={12}>
          <Box
            sx={{ width: "220px", height: "44px", cursor: "pointer" }}
            display="flex"
            alignItems="center">
            {TW.REDIRECTED || Car.REDIRECTED ? (
              <h5 style={{ fontSize: "16px" }}>
                {TW.REDIRECTED ? "Two Wheeler Insurance" : "Car Insurance"}
              </h5>
            ) : (
              <img
                onClick={handleLogoClick}
                src={logoSrc}
                alt="Brand Logo"
                style={{ height: "44px", maxWidth: "100%", width: "100%" }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Navbar;
