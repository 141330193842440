import { Box, Checkbox, FormControlLabel } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { subYears } from "date-fns";
import CKYCDetailsPopup from "../../../../../Component/CKYCDetailsPopup/CKYCDetailsPopup";
import DatePicker from "../../../../../Component/FieldTypes/DatePicker/DatePicker";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../../../../../Component/FieldTypes/SelectDropdown/SelectDropdown";
import MFooter from "../../../../../Component/MFooter/MFooter";
import MemberRadioButton from "../../../../../Component/MemberRadioButton/MemberRadioButton";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { HealthSlice } from "../../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../../Store/hooks";
import { GET_DATE_VALIDATION } from "../../../../../SupportingFiles/HealthHelper";
import { isEmpty } from "../../../../../SupportingFiles/HelpingFunction";
import { TAdultOneDetails } from "../../../../../types/Health/ProposalDeatail/TProposalDetail";
import { THealthNomineeDetails } from "../../../../../types/Health/THealthSlice";
import { TDropdownData } from "../../../../../types/DropdownData/DropdownData";

export default function MAdultOneDetails({
  proposerFields,
  nomineeDetails,
  fieldsUpdateState,
  validateForm,
  handleClick,
  adultOneDetails,
  kycOpen,
  setKycOpen,
  loader,
  occupationList,
  relationshipList,
  maritalStatusList,
  nomineeRealtionshipList,
}: {
  proposerFields: TAdultOneDetails;
  adultOneDetails: TAdultOneDetails;
  nomineeDetails: THealthNomineeDetails;
  fieldsUpdateState: Function;
  validateForm: Function;
  handleClick: Function;
  kycOpen: boolean;
  setKycOpen: Function;
  loader: boolean;
  occupationList: TDropdownData[];
  relationshipList: TDropdownData[];
  maritalStatusList: TDropdownData[];
  nomineeRealtionshipList: TDropdownData[];
}) {
  const dispatch = useAppDispatch();
  const {
    ADD_FORM,
    DROPDOWN_DATA,
    PAGE_STATUS,
    NOMINEE_RELATIONSHIP_STATUS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);
  const { GENDER, HEIGHT_FEET, HEIGHT_INCH, OCCUPATION, NIVA_BUPA_OCCUPATION } =
    useAppSelector((state) => state.CommonDropdownData);
  const hdfcProductIds = [
    ECompanyCode.HDFC_HEALTH,
    ECompanyCode.HDFC_HEALTH,
    EProductId.HDFC_HEALTH,
    EProductId.HDFC_HEALTH_Global,
    EProductId.HDFC_HEALTH_Plan,
  ];

  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);

  console.log(adultOneDetails, 'adultOneDetails')

  const annual_income_niva_bupa = [];
  for (
    let incomeValue = 100000;
    incomeValue <= 10000000;
    incomeValue += 100000
  ) {
    let label;
    if (incomeValue === 10000000) {
      label = "1 Crore";
    } else {
      label = `${incomeValue / 100000} Lakh`;
    }
    annual_income_niva_bupa.push({ label: label, value: `${incomeValue}` });
  }

  let ANNUAL_INCOME = [
    {
      label: "1 Lakh",
      value: "100000",
    },
    {
      label: "2 Lakh",
      value: "200000",
    },
    {
      label: "3 Lakh",
      value: "300000",
    },
    {
      label: "4 Lakh",
      value: "400000",
    },
    {
      label: "5 Lakh",
      value: "500000",
    },
    {
      label: "6 Lakh",
      value: "600000",
    },
    {
      label: "7 Lakh",
      value: "700000",
    },
    {
      label: "8 Lakh",
      value: "800000",
    },
    {
      label: "9 Lakh",
      value: "900000",
    },
    {
      label: "10 Lakh",
      value: "1000000",
    },
  ];
  return (
    <Box>
      <CKYCDetailsPopup open={kycOpen} setOpen={setKycOpen} loader={loader} />
      {proposerFields.IS_PROPOSER_INSURED ? (
        <h5 className="sectionTitle">
          1<sup>st</sup> Adult Details
        </h5>
      ) : (
        <h5 className="sectionTitle">Propser Details</h5>
      )}

      <>
        {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
          ECompanyCode.CARE ||
          (SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.STAR &&
            ADD_FORM.spouse_status &&
            ADD_FORM.children !== 0) ? null : (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            marginBottom={"12px"}>
            <Grid xs={12}>
              <FormControlLabel
                className="proposalCheckBoxWrapper"
                control={
                  <Checkbox
                    disabled={PAGE_STATUS}
                    defaultChecked={proposerFields.IS_PROPOSER_INSURED}
                    value={proposerFields.IS_PROPOSER_INSURED}
                    onClick={() => handleClick()}
                  />
                }
                label="Proposer is also an insured member."
              />
            </Grid>
          </Grid>
        )}
      </>
      {!proposerFields.IS_PROPOSER_INSURED ? (
        <>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            marginBottom={"12px"}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title="Full Name"
                value={proposerFields.name.value}
                attrName="name"
                value_update={fieldsUpdateState}
                warn_status={false}
                error_message={
                  proposerFields.name.value === "" ? "Enter Full Name" : ""
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={proposerFields.dob.value}
                attrName={"dob"}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={proposerFields.dob.warning}
                date_validation_type={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).validation_type
                }
                min_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).min_date
                }
                max_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).max_date
                }
                default_date={
                  SELECTED_QUOTE_DATA.productDetails.id === 2843
                    ? subYears(new Date(), 56)
                    : subYears(new Date(), 18)
                }
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={proposerFields.gender.value}
                attrName="gender"
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={proposerFields.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title="Mobile"
                value={ADD_FORM.mobile.value}
                attrName="mobile"
                value_update={fieldsUpdateState}
                warn_status={proposerFields.mobile.warning}
                error_message={
                  isEmpty(proposerFields.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter valid mobile number"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                class_name="inputField"
                title="Email"
                value={proposerFields.email.value}
                attrName="email"
                value_update={fieldsUpdateState}
                warn_status={proposerFields.email.warning}
                error_message={
                  isEmpty(proposerFields.email.value)
                    ? "Enter Email"
                    : "Enter valid email"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={12}
                class_name="inputField"
                title="Aadhar"
                value={proposerFields.aadhar.value}
                attrName="aadhar"
                value_update={fieldsUpdateState}
                warn_status={proposerFields.aadhar.warning}
                error_message={
                  isEmpty(proposerFields.aadhar.value)
                    ? "Enter Aadhar"
                    : "Enter valid Aadhar"
                }
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title="Emergency Phone"
                value={proposerFields.emergencyPhone.value}
                attrName="emergencyPhone"
                value_update={fieldsUpdateState}
                warn_status={proposerFields.emergencyPhone.warning}
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                validation_type="ALPHANUMERIC"
                class_name="inputField"
                title={"PAN"}
                value={proposerFields.pan_card.value}
                attrName={["pan_card"]}
                value_update={fieldsUpdateState}
                warn_status={proposerFields.pan_card.warning}
                error_message={
                  isEmpty(proposerFields.pan_card.value)
                    ? "Enter PAN"
                    : "Enter Valid Pan "
                }
                max_length={10}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            marginBottom={"12px"}>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Full Name"}
                value={
                  proposerFields.name.value
                    ? proposerFields.name.value
                    : CKYC_DETAILS.kycCustomerName
                }
                attrName={["name", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.name.warning}
                error_message={
                  "Enter Full Name"
                }
                disabled={true}
              />
            </Grid>
            <Grid xs={6}>
              <DatePicker
                class_name="inputField"
                title={"DOB"}
                value={SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.HDFC_HEALTH ||
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                  ECompanyCode.STAR ||
                  (SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.CIGNA &&
                    adultOneDetails.dob.value) || SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.ADITY_BIRLA && CKYC_DETAILS.kycDOB ? CKYC_DETAILS.kycDOB : adultOneDetails.dob.value}
                attrName={["dob", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                error_message="Enter Dob"
                warn_status={adultOneDetails.dob.warning}
                date_validation_type={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).validation_type
                }
                min_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).min_date
                }
                max_date={
                  GET_DATE_VALIDATION(
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code,
                    `${SELECTED_QUOTE_DATA.productDetails.id}`
                  ).max_date
                }
                default_date={subYears(new Date(), 18)}
                disabled={
                  SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.HDFC_HEALTH ||
                    SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                    ECompanyCode.STAR ||
                    (SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                      ECompanyCode.CIGNA &&
                      adultOneDetails.dob.value) || SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                      ECompanyCode.ADITY_BIRLA && CKYC_DETAILS.kycDOB
                    ? true
                    : false
                }
              />
            </Grid>
            <Grid xs={6}>
              <SelectDropdown
                class_name="inputField"
                title="Gender"
                value={adultOneDetails.gender.value}
                attrName={["gender", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={GENDER}
                warn_status={adultOneDetails.gender.warning}
                error_message="Select Gender"
              />
            </Grid>
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Mobile"}
                value={ADD_FORM.mobile.value}
                attrName={["mobile", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.mobile.warning}
                error_message={
                  isEmpty(adultOneDetails.mobile.value)
                    ? "Enter Mobile Number"
                    : "Enter valid mobile number"
                }
                disabled={true}
              />
            </Grid>
            {
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.ADITY_BIRLA &&
                CKYC_DETAILS.kycEmail ? <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={CKYC_DETAILS.kycEmail}
                  attrName={["email", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  warn_status={adultOneDetails.email.warning}
                  error_message={
                    isEmpty(adultOneDetails.email.value)
                      ? "Enter Email"
                      : "Enter Valid Email"
                  }
                  disabled={true}
                />
              </Grid> : <Grid xs={6}>
                <RKTextField
                  class_name="inputField"
                  title={"Email"}
                  value={adultOneDetails.email.value}
                  attrName={["email", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  warn_status={adultOneDetails.email.warning}
                  error_message={
                    isEmpty(adultOneDetails.email.value)
                      ? "Enter Email"
                      : "Enter Valid Email"
                  }
                />
              </Grid>
            }
            {
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
                ECompanyCode.ADITY_BIRLA &&
                CKYC_DETAILS.kycAadhaar ?
                <Grid xs={6}>
                  <RKTextField
                    validation_type="NUMBER"
                    max_length={12}
                    class_name="inputField"
                    title={"Aadhar"}
                    value={CKYC_DETAILS.kycAadhaar}
                    attrName={["aadhar", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    warn_status={adultOneDetails.aadhar.warning}
                    error_message={
                      isEmpty(CKYC_DETAILS.kycAadhaar)
                        ? "Enter Aadhar"
                        : "Enter Valid Aadhar"
                    }
                    disabled={true}
                  />
                </Grid>
                : <Grid xs={6}>
                  <RKTextField
                    validation_type="NUMBER"
                    max_length={12}
                    class_name="inputField"
                    title={"Aadhar"}
                    value={adultOneDetails.aadhar.value}
                    attrName={["aadhar", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    warn_status={adultOneDetails.aadhar.warning}
                    error_message={
                      isEmpty(adultOneDetails.aadhar.value)
                        ? "Enter Aadhar"
                        : "Enter Valid Aadhar"
                    }
                  />
                </Grid>
            }
            <Grid xs={6}>
              <RKTextField
                validation_type="NUMBER"
                max_length={10}
                class_name="inputField"
                title={"Emergency Phone"}
                value={adultOneDetails.emergencyPhone.value}
                attrName={["emergencyPhone", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.emergencyPhone.warning}
                error_message={
                  adultOneDetails.emergencyPhone.value.toString().length < 11
                    ? "Enter Valid Phone Number"
                    : ""
                }
              />
            </Grid>
            {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.CIGNA ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH) ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ADITY_BIRLA &&
              CKYC_DETAILS.kycPAN ? (
              <Grid xs={6}>
                <RKTextField
                  validation_type="ALPHANUMERIC"
                  class_name="inputField"
                  title={"PAN"}
                  value={CKYC_DETAILS.kycPAN}
                  attrName={["pan_card", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  warn_status={adultOneDetails.pan_card.warning}
                  error_message={
                    isEmpty(adultOneDetails.pan_card.value)
                      ? "Enter PAN"
                      : "Enter Valid Pan "
                  }
                  alphabet_only={true}
                  max_length={10}
                  disabled={true}
                />
              </Grid>
            ) : (
              <Grid xs={6}>
                <RKTextField
                  validation_type="ALPHANUMERIC"
                  class_name="inputField"
                  title={"PAN"}
                  value={adultOneDetails.pan_card.value}
                  attrName={["pan_card", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  warn_status={adultOneDetails.pan_card.warning}
                  error_message={
                    isEmpty(adultOneDetails.pan_card.value)
                      ? "Enter PAN"
                      : "Enter Valid Pan "
                  }
                  alphabet_only={true}
                  max_length={10}
                />
              </Grid>
            )}
            <Grid xs={6} className="weightField">
              <span className="weightIcon"></span>
              <RKTextField
                validation_type="NUMBER"
                max_length={3}
                class_name="inputField"
                title={"Weight (Kg)"}
                value={adultOneDetails.weight.value}
                attrName={["weight", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                warn_status={adultOneDetails.weight.warning}
                error_message="Enter Weight"
              />
            </Grid>
            <Grid xs={12} className="heightField">
              <span className="heightIcon"></span>
              <SelectDropdown
                class_name="inputField mr-2"
                title="Feet"
                value={adultOneDetails.heightFeet.value}
                attrName={["heightFeet", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_FEET}
                warn_status={adultOneDetails.heightFeet.warning}
                error_message="Select Height Feet"
              />
              <SelectDropdown
                class_name="inputField"
                title="Inches"
                value={adultOneDetails.heightInches.value}
                attrName={["heightInches", "adultOneDetails"]}
                value_update={fieldsUpdateState}
                data={HEIGHT_INCH}
                warn_status={adultOneDetails.heightInches.warning}
                error_message="Select Height Inches"
              />
            </Grid>
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code !==
              ECompanyCode.STAR ? (
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Marital Status"
                  value={adultOneDetails.maritalStatus.value}
                  attrName={["maritalStatus", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  // data={DROPDOWN_DATA.MARTIAL}
                  data={maritalStatusList}
                  warn_status={adultOneDetails.maritalStatus.warning}
                  error_message="Select Marital Status"
                />
              </Grid>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.STAR ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ||
              hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id) ? (
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Occupation"
                  value={adultOneDetails.occupation.value}
                  attrName={["occupation", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  // data={DROPDOWN_DATA.OCCUPATION}
                  data={occupationList}
                  warn_status={adultOneDetails.occupation.warning}
                  error_message="Select Occupation Status"
                />
              </Grid>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.productDetails.id ===
              EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ||
              hdfcProductIds.includes(SELECTED_QUOTE_DATA.productDetails.id) ? (
              <>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Annual Income"
                    value={adultOneDetails?.annual_income?.value}
                    attrName={["annual_income", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={ANNUAL_INCOME}
                    warn_status={adultOneDetails?.annual_income?.warning}
                    error_message="Select Annual Income"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.NIVA_BUPA ? (
              <Grid xs={12}>
                <SelectDropdown
                  class_name="inputField"
                  title="Occupation"
                  value={adultOneDetails.occupation.value}
                  attrName={["occupation", "adultOneDetails"]}
                  value_update={fieldsUpdateState}
                  // data={OCCUPATION}
                  data={occupationList}
                  warn_status={adultOneDetails.occupation.warning}
                  error_message="Select Occupation Status"
                />
              </Grid>
            ) : (
              <></>
            )}
            {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ICIC ? (
              <>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Occupation"
                    value={adultOneDetails.occupation.value}
                    attrName={["occupation", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={OCCUPATION}
                    warn_status={adultOneDetails.occupation.warning}
                    error_message="Select Occupation Status"
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Annual Income"
                    value={adultOneDetails?.annual_income?.value}
                    attrName={["annual_income", "adultOneDetails"]}
                    value_update={fieldsUpdateState}
                    data={ANNUAL_INCOME}
                    warn_status={adultOneDetails?.annual_income?.warning}
                    error_message="Select Occupation Status"
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>

          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            marginBottom={"12px"}>
            <Grid xs={12}>
              <hr />
              <h6>Nominee Details</h6>
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={2}
            rowSpacing={3}
            justifyContent="center">
            {ADD_FORM.spouse_status ? (
              <Grid xs={6}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id={"ADULT_2"}
                  label_name="Adult 2"
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value={NOMINEE_RELATIONSHIP_STATUS === "ADULT_2"}
                  value_update={() => {
                    dispatch(
                      HealthSlice.actions.CHANGE_NOMINEE_STATUS("ADULT_2")
                    );
                  }}
                />
              </Grid>
            ) : null}
            {ADD_FORM.spouse_status ? (
              <Grid xs={6}>
                <MemberRadioButton
                  checked={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  class_name="adult"
                  input_name="ksdgkj"
                  input_id="OTHER"
                  label_name="Other"
                  value={NOMINEE_RELATIONSHIP_STATUS === "OTHER"}
                  input_type="radio"
                  attrName={"nomineDetail"}
                  value_update={() => {
                    dispatch(
                      HealthSlice.actions.CHANGE_NOMINEE_STATUS("OTHER")
                    );
                  }}
                />
              </Grid>
            ) : null}
          </Grid>

          {NOMINEE_RELATIONSHIP_STATUS === "OTHER" ? (
            <Box>
              <Grid container spacing={3}>
                <Grid xs={12}>
                  <RKTextField
                    validation_type="NAME"
                    class_name="inputField"
                    title={"Full Name"}
                    value={nomineeDetails.full_name.value}
                    attrName={["nomineeDetails", "full_name"]}
                    value_update={fieldsUpdateState}
                    warn_status={nomineeDetails.full_name.warning}
                    error_message="Enter Nominee Full Name"
                  />
                </Grid>
                <Grid xs={12}>
                  <DatePicker
                    class_name="inputField"
                    title={"DOB"}
                    value={nomineeDetails.dob.value}
                    attrName={["nomineeDetails", "dob"]}
                    date_validation_type="YEARS"
                    min_date={100}
                    max_date={18}
                    default_date={subYears(new Date(), 18)}
                    value_update={fieldsUpdateState}
                    error_message="Enter Nominee Date of birth"
                    warn_status={nomineeDetails.dob.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField"
                    title="Relationship"
                    value={nomineeDetails?.relationship?.value}
                    attrName={["nomineeDetails", "relationship"]}
                    value_update={fieldsUpdateState}
                    // data={DROPDOWN_DATA.NOMINEE_RELATIONSHIP}
                    data={nomineeRealtionshipList}
                    warn_status={nomineeDetails?.relationship?.warning}
                    error_message="Enter Nominee RelationShip"
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>
      )}

      {/* Footer */}

      <MFooter
        attrName={"pageStatus"}
        textName={PAGE_STATUS ? "Update" : "Continue"}
        value={1}
        value_update={() => { }}
        forward={() => {
          validateForm();
        }}
      />
    </Box>
  );
}
