import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import AddonDetails from "../../../Page/Desktop/Health/CIGNA/AddonDetails";
import { TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE } from "../../../types/Health/ProposalDeatail/TCIGNAAddon/TCIGNAAddon";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import { Box } from "@mui/material";
import MAddonDetails from "../../../Page/Mobile/Health/CIGNA/MAddonDetails";

export default function AddonDetailsContainerPrimeAdvantage() {
  const isMobile = useIsMobile();
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const { Health } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [addonStatus, setAddonStatus] =
    useState<TADDON_DETAILS_CIGNA_PRIME_ADVANTAGE>(
      ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE
    );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    updatePreiumApi();
    dispatch(
      HealthSlice.actions.ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE(addonStatus)
    );
  }, [addonStatus]);

  const updateMasterState = (attrName: any, value: any) => {
    setAddonStatus((prev) => ({
      ...prev,
      [attrName]: value,
    }));
  };

  console.log(addonStatus, "addonStatus");

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS_CIGNA_PRIME_ADVANTAGE: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));

    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      gender: ADD_FORM.gender.value,
      addon: {
        critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
        outpatient: addonStatus.outpatient === true ? "Yes" : "No", //Mandatory
        cumulative: addonStatus.cumulative === true ? "Yes" : "No",
        healthAddSumInsured: addonStatus.healthAddSumInsured, // dropdwon(20000,30000,50000)
        non_medical: addonStatus.non_medical === true ? "Yes" : "No",
        personal_accident: addonStatus.personal_accident === true ? "Yes" : "No",
        prime_enhance: addonStatus.prime_enhance === true ? "Yes" : "No",
        room_rent: addonStatus.room_rent === true ? "Yes" : "No",
        supreme_bonus: addonStatus.supreme_bonus === true ? "Yes" : "No",
        surplus_benifit: addonStatus.surplus_benifit === true ? "Yes" : "No",
        premium_management: addonStatus.premium_management === true ? "Yes" : "No",
      },
    };
    HEALTH_PROPOSAL_SERVICES.getPeriumByAddonCignaPrimeAdvantage(
      onSuccess,
      onError,
      param
    );
  };

  const validate = () => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setLoader(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    setLoader(true);
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      section: "add_ons_details",
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      critical_illness: addonStatus.critical_illness === true ? "Yes" : "No",
      outpatient: "Yes", //Mandatory
      cumulative: addonStatus.cumulative === true ? "Yes" : "No",
      healthAddSumInsured: addonStatus.healthAddSumInsured, // dropdwon(20000,30000,50000)
      non_medical: addonStatus.non_medical === true ? "Yes" : "No",
      personal_accident: addonStatus.personal_accident === true ? "Yes" : "No",
      prime_enhance: addonStatus.prime_enhance === true ? "Yes" : "No",
      room_rent: addonStatus.room_rent === true ? "Yes" : "No",
      supreme_bonus: addonStatus.supreme_bonus === true ? "Yes" : "No",
      surplus_benifit: addonStatus.surplus_benifit === true ? "Yes" : "No",
      premium_management: addonStatus.premium_management === true ? "Yes" : "No",
    };
    if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
        // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
      );
    }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  return (
    <>
      {" "}
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}{" "}
      {isMobile ? <MAddonDetails
        isLoading={loader}
        addonStatus={addonStatus}
        updateMasterState={updateMasterState}
        validate={validate}
        PAGE_STATUS={PAGE_STATUS}
      /> : (

        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
        />
      )}
    </>
  );
}
