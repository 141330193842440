import { subYears } from "date-fns";
import { ECompanyCode } from "../Services/Enum/EHome";
import { THealthAddForm } from "../types/Health/THealthSlice";
import { calculateAgeInDays, FORMAT_DATE_DD_MM_YYYY } from "./HelpingFunction";
import { EICICIProductID } from "../Enum/EICICIProductID";

export const HealthFeatClass = (feature_code: string) => {
  const healthFeatureMapping: { [key: string]: string } = {
    HF001: "discount",
    HF002: "preexisting",
    HF003: "discount",
    HF004: "treatment",
    HF005: "recharge",
    HF006: "extrapremium",
    HF007: "hospitalized",
    HF008: "healthcheckup",
    HF009: "hospital",
    HF010: "hospitalized",
    HF011: "medicines",
    HF012: "treatment",
    HF013: "hospitalized",
    HF014: "hospitalized",
    HF015: "hospitalized",
    HF016: "treatments",
    HF017: "treatment",
    HF018: "bonus",
    HF019: "extrapremium",
    HF020: "hospitalized",
    HF021: "preexisting",
    HF022: "hospitalized",
    HF023: "lungs",
    HF024: "maternity",
    HF025: "pregnancy",
    HF026: "maternity",
    HF027: "maternity",
    HF028: "insulin",
    HF029: "wounds",
    HF030: "amount",
    HF031: "discount",
    HF032: "healthcheckup",
    HF033: "bloodsugar",
    HF034: "extrapremium",
    HF035: "accident",
    HF036: "accident",
    HF037: "healthcheckup",
    HF038: "eyes",
    HF039: "handicaped",
    HF040: "treatment",
    HF041: "extrapremium",
    HF042: "hospitalized",
    HF043: "pregnancy",
    HF044: "pregnancy",
    HF045: "medicalhistory",
    HF046: "maternity",
    HF047: "healthcheckup",
    // HF048: "value_for_HF002",
    // HF049: "value_for_HF002",
  };

  if (feature_code in healthFeatureMapping) {
    return healthFeatureMapping[feature_code];
  }

  return feature_code;
};

export function GET_DATE_VALIDATION(
  company_code: string,
  product_id: string
): {
  validation_type: "DAYS" | "YEARS" | "MONTHS" | undefined;
  min_date: number;
  max_date: number;
} {
  switch (company_code) {
    // case ECompanyCode.HDFC_HEALTH:
    //   return { validation_type: "DAYS", min_date: 16801, max_date: 6575 };

    case ECompanyCode.HDFC_HEALTH:
      return {
        validation_type: "DAYS",
        min_date:
          calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 66)}`)
          ) - 1,
        max_date: calculateAgeInDays(
          FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
        ),
      };

    case ECompanyCode.STAR:
      if (`${product_id}` === "187") {
        return { validation_type: "YEARS", min_date: 99, max_date: 18 };
      } else if (`${product_id}` === "180") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else if (`${product_id}` === "197") {
        return { validation_type: "YEARS", min_date: 65, max_date: 18 };
      } else {
        return {
          validation_type: "DAYS",
          min_date: 75 * 365,
          max_date: 60 * 365,
        };
      }

    case ECompanyCode.NIVA_BUPA:
      if (
        `${product_id}` === "2592" ||
        `${product_id}` === "2593" ||
        `${product_id}` === "2594"
      ) {
        return {
          validation_type: "DAYS",
          min_date:
            calculateAgeInDays(
              FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 66)}`)
            ) - 1,
          max_date: calculateAgeInDays(
            FORMAT_DATE_DD_MM_YYYY(`${subYears(new Date(), 18)}`)
          ),
        };
      } else {
        return {
          validation_type: "DAYS",
          min_date: 75 * 365,
          max_date: 61 * 365,
        };
      }

    case ECompanyCode.ICIC:
      if (`${product_id}` === "2843") {
        return { validation_type: "YEARS", min_date: 85, max_date: 56 };
      } else if (`${product_id}` === EICICIProductID.HAP) {
        return {
          validation_type: "YEARS",
          min_date: 65,
          max_date: 18,
        };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    case ECompanyCode.CARE:
      if (`${product_id}` === "443") {
        return { validation_type: "YEARS", min_date: 99, max_date: 61 };
      } else {
        return {
          validation_type: "YEARS",
          min_date: 99,
          max_date: 18,
        };
      }

    default:
      return { validation_type: "YEARS", min_date: 100, max_date: 18 };
  }
}

export const child_gender = (
  child_no: "1" | "2" | "3" | "4",
  formData: THealthAddForm
) => {
  const { son_count, daughter_count } = formData;
  switch (child_no) {
    case "1":
      if (son_count.value > 0) {
        return "M";
      } else if (daughter_count.value > 0) {
        return "F";
      }
      break;

    case "2":
      if (son_count.value > 1) {
        return "M";
      } else if (daughter_count.value > 1) {
        return "F";
      }
      break;

    case "3":
      if (son_count.value > 2) {
        return "M";
      } else if (daughter_count.value > 2) {
        return "F";
      }
      break;

    case "4":
      if (son_count.value > 3) {
        return "M";
      } else if (daughter_count.value > 3) {
        return "F";
      }
      break;

    default:
      break;
  }
};
