import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./Footer.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../Store/hooks";
import { TWSlice } from "../../Store/Slice_Reducer/TwoWheeler/TWSlice";
import CustomButton from "../CustomButton/CustomButton";
import { CAR_ROUTES } from "../../Router/Path/CarRoutes";
import { useEffect, useState } from "react";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import { TFieldData } from "../../types/Common/TFieldData";
import {
  isEmpty,
  validateEmail,
  validateMobileNumber,
} from "../../SupportingFiles/HelpingFunction";
import { HEALTH_PROPOSAL_SERVICES } from "../../Services/HealthServices/HealthPropsalServices";
import { toast } from "react-toastify";
const Footer = ({
  forward,
  value,
  disableButton,
  attrName,
  value_update,
  textName = "Continue",
  loader = false,
}: {
  forward?: Function;
  value?: any;
  backward?: Function;
  attrName?: any;
  value_update?: any;
  disableButton?: boolean;
  textName?: string;
  loader?: boolean;
}) => {
  const path = window.location.pathname;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { TW, Car, Health } = useAppSelector((state) => state);
  const { CUS_TYPE } = useAppSelector((state) => state.Car);
  //  const {DAta } = useAppSelector(
  //    (state) => state.Health?.ADD_FORM_RESPONSE?.
  //  );
  const [previewDetail, setPreviewDetail] = useState("");
  const [linkLoad, setLinkLoad] = useState<boolean>(false);
  useEffect(() => {
    // Get the current URL
    const url = window.location.href;

    // Create a URL object
    const urlObj = new URL(url);

    // Get the pathname
    const pathname = urlObj.pathname;

    // Split the pathname and get the last segment
    const segments = pathname.split("/");
    const desiredSegment = segments[segments.length - 1]; // This will be "preview-details"

    // Set the state
    setPreviewDetail(desiredSegment);
  }, []);
  const [param, setParam] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    const fullURL = window.location.href;
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");
    const payment = params.get("cus_type");
    setUrl(fullURL);
    setType(type || "");
    setParam(payment || "");
  }, []);

  const [shareURLData, setShareURLData] = useState<{
    email: TFieldData;
    mobile: TFieldData;
  }>({
    email: { value: "", warning: false },
    mobile: { value: "", warning: false },
  });
  const [showPaymentPopup, setShowPaymentPopup] = useState<boolean>(false);

  const handleDetails = (attrName: any, value: any) => {
    if (attrName === "email") {
      setShareURLData((prev) => ({
        ...prev,
        email: { value: value, warning: !validateEmail(value) },
      }));
    } else if (attrName === "mobile") {
      setShareURLData((prev) => ({
        ...prev,
        mobile: { value: value, warning: !validateMobileNumber(value) },
      }));
    }
  };

  const PAYMENT_LINK = () => {
    setLinkLoad(true);
    let data = { ...shareURLData };
    if (!isEmpty(data.email.value)) {
      data = {
        ...data,
        email: { ...data.email, warning: !validateEmail(data.email.value) },
      };
    } else {
      data = {
        ...data,
        email: { ...data.email, warning: false },
      };
    }
    if (!isEmpty(data.mobile.value)) {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
      };
    } else {
      data = {
        ...data,
        mobile: {
          ...data.mobile,
          warning: false,
        },
      };
    }

    setShareURLData(data);
    // Determine if there's an error
    const hasError = data.email.warning || data.mobile.warning;

    if (isEmpty(data.email.value) && isEmpty(data.mobile.value)) {
      toast.error("Please fill mandatory fields.");
      setLinkLoad(false);
      return;
    }

    if (hasError) {
      setLinkLoad(false);
      return;
    }

    const onSuccess = (res: any) => {
      setLinkLoad(false);
      console.log("result", res);
      setShowPaymentPopup(false);
      setShareURLData({
        email: { value: "", warning: false },
        mobile: { value: "", warning: false },
      });
      toast.success("Link sent successfully");
    };

    const onError = (err: any) => {
      console.log("error", err);
      setLinkLoad(false);
    };

    let param = {
      customerName:
        type === "CAR"
          ? `${Car?.OWNER_DETAILS?.name?.value}`
          : type === "TW"
            ? `${TW?.OWNER_DETAILS?.name?.value}`
            : `${Health?.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS.proposer_name.value}`,
      email: data.email.value,
      number: data.mobile.value,
      planName:
        type === "CAR"
          ? "Car Insurance"
          : type === "TW"
            ? "Tw Insurance"
            : "Health Insurance",
      insurer:
        type === "CAR"
          ? `${Car?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails?.short_desc}`
          : type === "TW"
            ? `${TW?.SELECTED_QUOTE_DATA?.quotationDetail?.CompanyDetails?.short_desc}`
            : `${Health?.SELECTED_QUOTE_DATA?.CompanyDetails?.short_desc}`,
      paymentLink: `${url}&cus_type=user`,
    };

    HEALTH_PROPOSAL_SERVICES.PAYMENT_LINK(onSuccess, onError, param);
  };

  const showShareLink =
    (!isEmpty(Car.ADD_FORM_RESPONSE.utm_medium) ||
      !isEmpty(TW.ADD_FORM_RESPONSE.utm_medium) ||
      !isEmpty(Health.ADD_FORM_RESPONSE.utm_medium)) &&
    isEmpty(param);
  console.log("showShareLink", window.history.length);

  const CUS = Car?.CUS_TYPE || TW?.CUS_TYPE || Health.CUS_TYPE;

  // const [canGoBack, setCanGoBack] = useState(false);
  // console.log("window.history.state?.idx", window.history.state?.idx);
  // useEffect(() => {
  //   if (window.history.state?.idx > 0) {
  //     setCanGoBack(true);
  //   } else {
  //     setCanGoBack(false);
  //   }
  // }, []);
  const location = useLocation();
  // const [canGoBack, setCanGoBack] = useState(false);

  // useEffect(() => {
  //   if (window.history.state?.idx > 0 && location.pathname !== "/") {
  //     setCanGoBack(true);
  //   } else {
  //     setCanGoBack(false);
  //   }
  // }, [location.pathname]);
  return (
    <Box
      className={`proposalFooter paymentLinkPage ${previewDetail === "preview-details" ? "paymentLinkPage" : ""
        }`}
    >
      <Modal open={showPaymentPopup} className="modalWrapper">
        <Box className="modalContent xsWidth kycPopups">
          <Grid container spacing={2}>
            <Grid xs={12} textAlign="center">
              <h4 className="popup-heading mb-2 mt-6">Enter Details</h4>
              <p className="grey_p">Enter Details to proceed</p>
              <Link
                to={""}
                className="close-button"
                onClick={() => {
                  setShowPaymentPopup(false);
                  setShareURLData({
                    email: { value: "", warning: false },
                    mobile: { value: "", warning: false },
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} textAlign="center">
            <Grid xs={12}>
              <RKTextField
                class_name="inputField mb-6"
                title={"Proposer Email"}
                value={shareURLData.email.value}
                attrName={"email"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.email.value)
                    ? "Enter Valid E-mail"
                    : "Enter E-mail"
                }
                warn_status={shareURLData.email.warning}
              />
            </Grid>
            <Grid xs={12}>
              <RKTextField
                class_name="inputField"
                title={"Proposer Mobile"}
                value={shareURLData.mobile.value}
                attrName={"mobile"}
                value_update={handleDetails}
                error_message={
                  !isEmpty(shareURLData.mobile.value)
                    ? "Enter Valid Mobile No."
                    : "Enter Mobile No."
                }
                warn_status={shareURLData.mobile.warning}
                validation_type="NUMBER"
                max_length={10}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={3} marginTop={"24px"}>
            <Grid xs={12} style={{ textAlign: "center" }}>
              <CustomButton
                text_name="Submit"
                class_name="regularPrimaryBtn mb-3"
                onClickFunction={PAYMENT_LINK}
                loading={linkLoad}
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Grid container spacing={3}>
        <Grid xs={8} xsOffset={3} className="footerInner" alignItems="center">

          <Link
            to={""}
            className="backStep"
            onClick={() => {
              navigate(-1);
              dispatch(TWSlice.actions.Home_loader(true));
            }}
          >
            <ArrowBackIcon /> {value === 0 ? " Back to Quotes" : "Back"}
          </Link>

          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <CustomButton
              text_name={
                textName !== "Continue"
                  ? textName
                  : path ===
                    `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.PREVIEW_DEATILS}`
                    ? "Make Payment"
                    : textName
              }
              class_name="regularPrimaryBtn"
              onClickFunction={() => {
                if (forward) {
                  forward();
                } else {
                  value_update && value_update(attrName, value + 1);
                }
              }}
              loading={loader}
              disabled={disableButton}
            />
            {/* cus-type=user */}
            {showShareLink && !CUS ? (
              <CustomButton
                text_name={"Share Link"}
                class_name="regularPrimaryBtn ml-3"
                onClickFunction={() => {
                  setShowPaymentPopup(true);
                }}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
