import { TCareMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareMedicalQuestion";

export const OPTIMA_SECURE_INITIAL_VALUE = (): TCareMedicalQuestion => {
  return {
    MQ01: {
      main_question: {
        id: "MQ01",
        icon_class: "tobaco",
        title: "Existing Diseases",
        description:
          "Has an ailment or disability or deformity including due to accident or congenital disease?",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",

            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",

            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",

            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "",
            description: "Kidney ailment or Diseases of Reproductive organs?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "test Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surger yDate",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Abnormal Thyroid Function/ Goiter or any Endocrine organ disorders?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "",
            description: "Diabetes/ High blood sugar/Sugar in urine?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ4-SQ4": {
            id: "MQ4-SQ4",
            icon_class: "",
            status: false,
            title: "",
            description: "Hypertension/ High blood pressure?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ5-SQ5": {
            id: "MQ5-SQ5",
            icon_class: "",
            status: false,
            title: "",
            description: "HIV Infection/AIDS or Positive test for HIV?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ6-SQ6": {
            id: "MQ6-SQ6",
            icon_class: "",
            status: false,
            title: "",
            description: "Nervous, Psychiatric or Mental or Sleep disorder?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ7-SQ7": {
            id: "MQ7-SQ7",
            icon_class: "",
            status: false,
            title: "",
            description: "Cancer, Tumour, Growth or Cyst of any kind?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ8-SQ8": {
            id: "MQ8-SQ8",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Chest Pain/ Heart Attack or any other Heart Disease/ Problem?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ9-SQ9": {
            id: "MQ9-SQ9",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Liver or Gall Bladder ailment/Jaundice/Hepatitis B or C?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ10-SQ10": {
            id: "MQ10-SQ10",
            icon_class: "",
            status: false,
            title: "",
            description: "Tuberculosis/ Asthma or any other Lung disorder?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ11-SQ11": {
            id: "MQ11-SQ11",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Ulcer (Stomach/ Duodenal), or any ailment of Digestive System?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ12-SQ12": {
            id: "MQ12-SQ12",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Any Blood disorder (example Anaemia, Haemophilia, Thalassaemia) or any genetic disorder?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ13-SQ13": {
            id: "MQ13-SQ13",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Stroke/ Paralysis/ Epilepsy (Fits) or any other Nervous disorder (Brain / Spinal Cord etc.)?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ14-SQ14": {
            id: "MQ14-SQ14",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Eye or vision disorders/ Ear/ Nose or Throat diseases ?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ15-SQ15": {
            id: "MQ15-SQ15",
            icon_class: "",
            status: false,
            title: "",
            description:
              "Arthritis, Spondylitis, Fracture or any other disorder of Muscle Bone/ Joint/ Ligament/ Cartilage?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ16-SQ16": {
            id: "MQ16-SQ16",
            icon_class: "",
            status: false,
            title: "",
            description: "Any other disease/condition not mentioned above?",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",

                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ1-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Exact Diagnosis",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F2": {
                    id: "MQ1-SQ1-F2",
                    title: "Surgery Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F3": {
                    id: "MQ1-SQ1-F3",
                    title: "Last Consulatation Date",
                    field_type: "DATEPICKER",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F4": {
                    id: "MQ1-SQ1-F4",
                    title: "Current Status",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F5": {
                    id: "MQ1-SQ1-F5",
                    title: "Life Of Management",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F6": {
                    id: "MQ1-SQ1-F6",
                    title: "Details Of Treatment",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ1-SQ1-F7": {
                    id: "MQ1-SQ1-F7",
                    title: "IsMedical QuestionOpted",
                    field_type: "DROPDOWN",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
        },
      },
    },
    MQ02: {
      main_question: {
        id: "MQ02",
        icon_class: "tobaco",
        title: "Has planned a surgery?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F7": {
                id: "MQ1-SQ1-F7",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ03: {
      main_question: {
        id: "MQ03",
        icon_class: "tobaco",
        title: "Was hospitalized in the past ?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "Surgery Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F3": {
                id: "MQ1-SQ1-F3",
                title: "Last Consulatation Date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F4": {
                id: "MQ1-SQ1-F4",
                title: "Current Status",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F5": {
                id: "MQ1-SQ1-F5",
                title: "Life Of Management",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F6": {
                id: "MQ1-SQ1-F6",
                title: "IsMedical QuestionOpted",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ04: {
      main_question: {
        id: "MQ04",
        icon_class: "tobaco",
        title: "Is Pregnant?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "pregnant delivery date",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ05: {
      main_question: {
        id: "MQ05",
        icon_class: "tobaco",
        title: "None of the above?",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
            field_data: {
              "MQ1-SQ1-F1": {
                id: "MQ1-SQ1-F1",
                title: "Exact Diagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              },
              "MQ1-SQ1-F2": {
                id: "MQ1-SQ1-F2",
                title: "IsMedical QuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              },
            },
          },
        },
      },
    },
    MQ06: {
      main_question: {
        id: "MQ06",
        icon_class: "tobaco",
        title: "Lifstyle Habit details List",
        description: "",
        status: false,
        member_data: {
          adult_one: {
            keyName: "adult_one",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          adult_two: {
            keyName: "adult_two",
            name: "",
            image_class: "adult",
            selection_status: false,
          },
          child_one: {
            keyName: "child_one",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_two: {
            keyName: "child_two",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_three: {
            keyName: "child_three",
            name: "",
            image_class: "child",
            selection_status: false,
          },
          child_four: {
            keyName: "child_four",
            name: "",
            image_class: "child",
            selection_status: false,
          },
        },
        sub_question: {
          "MQ1-SQ1": {
            id: "MQ1-SQ1",
            icon_class: "",
            status: false,
            title: "",
            description: "Cigarette",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ2-SQ2": {
            id: "MQ2-SQ2",
            icon_class: "",
            status: false,
            title: "",
            description: "Bidi(s)",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ3-SQ3": {
            id: "MQ3-SQ3",
            icon_class: "",
            status: false,
            title: "",
            description: "Tobacco Pouches",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ4-SQ4": {
            id: "MQ4-SQ4",
            icon_class: "",
            status: false,
            title: "",
            description: "Alcohol(Quantity)",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ5-SQ5": {
            id: "MQ5-SQ5",
            icon_class: "",
            status: false,
            title: "",
            description: "Gutka Pouches",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
          "MQ6-SQ6": {
            id: "MQ6-SQ6",
            icon_class: "",
            status: false,
            title: "",
            description: "Drugs(Quantity)",
            member_data: {
              adult_one: {
                keyName: "adult_one",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              adult_two: {
                keyName: "adult_two",
                name: "",
                image_class: "adult",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ1-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_one: {
                keyName: "child_one",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_two: {
                keyName: "child_two",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_three: {
                keyName: "child_three",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
              child_four: {
                keyName: "child_four",
                name: "",
                image_class: "child",
                selection_status: false,
                field_data: {
                  "MQ6-SQ1-F1": {
                    id: "MQ6-SQ1-F1",
                    title: "Day",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F2": {
                    id: "MQ6-SQ1-F2",
                    title: "Week",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F3": {
                    id: "MQ6-SQ1-F3",
                    title: "Month",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                  "MQ6-SQ1-F4": {
                    id: "MQ6-SQ1-F4",
                    title: "Year",
                    field_type: "TEXTFIELD",
                    value: { value: "", warning: false },
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};
