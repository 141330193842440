import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  FORMAT_DATE_DD_MM_YYYY,
  calculateAge,
  isEmpty,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { THealthBuyActionResopnse } from "../../../../types/Health/HealthQuotation/THealthBuyActionResponse";
import AddonDetails from "../../../../Page/Desktop/Health/CARE/AddonDetails/AddonDetails";
import MAddonDetails from "../../../../Page/Mobile/Health/CARE/MAddonDetails/MAddonDetails";
import { TCareAddon } from "../../../../types/Health/TCareAddon";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { Box } from "@mui/material";
import { CKYC_ROUTES } from "../../../../Router/Path/CKYCRoutes";

export default function AddonDetailsContainer() {
  const isMobile = useIsMobile();
  const { Health } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    QUOTE_LOADER,
  } = useAppSelector((state) => state.Health);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TCareAddon>(ADDON_DETAILS);
  const { CKYC_DETAILS } = useAppSelector((state) => state.CKYC);
  const [mandatoryAddon, setMandatoryAddon] = useState<[]>([]);


  const selfAge = calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value));
  const spouseAge = calculateAge(
    FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
  );
  // useEffect(() => {
  //   if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
  //     if (
  //       (selfAge <= 45 &&
  //         CKYC_DETAILS.kycDistrict.toLowerCase() === "delhi ncr") ||
  //       (selfAge <= 45 &&
  //         CKYC_DETAILS.kycDistrict.toLowerCase() === "surat")
  //     ) {
  //       setAddonStatus((prev) => ({
  //         ...prev,
  //         field_WB: true,
  //         AirAmbulanceCover: true,
  //         NCBSuper:true,
  //         field_AHC: true,
  //         CareShield: true,
  //       }));
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      setAddonStatus((prev) => ({
        ...prev,
        field_WB: true,
        AirAmbulanceCover: true,
      }));

      if (calculateAge(ADD_FORM.self_dob.value) <= 45) {
        setAddonStatus((prev) => ({
          ...prev,
          field_WB: true,
          AirAmbulanceCover: true,
        }));
      }
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 443) {
      if (CKYC_DETAILS.zone === "Zone 3" && PAGE_STATUS === false) {
        setAddonStatus((prev) => ({
          ...prev,
          field_OPD: true,
          field_OPD_data: { value: "5000", warning: false },
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      if (
        calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <= 45 ||
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) >= 76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <= 99)
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          field_WB: true,
          AirAmbulanceCover: true,
        }));
      }
    }
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      const selfAge = calculateAge(
        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)
      );
      const spouseAge = calculateAge(
        FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)
      );


      if (
        (PAGE_STATUS === false && selfAge >= 46 && selfAge <= 75) ||
        (!PAGE_STATUS === false && spouseAge >= 46 && spouseAge <= 75)
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: false,
        }));
      } else if (
        (selfAge >= 18 && selfAge < 46) ||
        selfAge > 75 ||
        (spouseAge >= 18 && spouseAge < 46) ||
        spouseAge > 75
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
        }));
      }

      if (
        (selfAge >= 76 && selfAge <= 99) ||
        selfAge > 75 ||
        (spouseAge >= 76 && spouseAge <= 99) ||
        spouseAge > 75
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
        }));
      }
    }

    if (SELECTED_QUOTE_DATA.productDetails.id === 2133) {
      if (
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) >= 76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.self_dob.value)) <=
          99) ||
        (calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)) >=
          76 &&
          calculateAge(FORMAT_DATE_DD_MM_YYYY(ADD_FORM.spouse_dob.value)) <=
          99) ||
        (ADD_FORM.age.value <= "45" && CKYC_DETAILS.ncb_tag === "Yes")
      ) {
        setAddonStatus((prev) => ({
          ...prev,
          NCBSuper: true,
        }));
      }
    }
  }, []);
  useEffect(() => {
    if (
      (CKYC_DETAILS.zone === "Zone 1" && PAGE_STATUS === false) ||
      (CKYC_DETAILS.zone === "Zone 2" && PAGE_STATUS === false)
    ) {
      setAddonStatus((prev) => ({
        ...prev,
        field_OPD_data: { value: "", warning: false },
      }));
    }
  }, []);
  const ADD_API_DATA = () => {
    const onSuccess = (res: any) => {
      if (res.results.error === false) {
        let quoteData = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));

        // Perform hard refresh on success
        // (window.location.reload as any)(true);
      }
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param: any = {
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
    };

    // HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
  };
  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "field_OPD" && value === false) {
      setAddonStatus((prevState) => ({
        ...prevState,
        field_OPD_data: { value: "", warning: false },
      }));
      //  dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
    } else if (attrName === "field_OPD" && value === true) {
      setAddonStatus((prevState) => ({
        ...prevState,
        field_OPD_data: { value: "5000", warning: false },
      }));
    } else if (attrName === "field_43") {
      setAddonStatus((prev) => ({
        ...prev,
        field_43: value,
        field_IC: false,
      }));
    } else if (attrName === "field_IC") {
      setAddonStatus((prev) => ({
        ...prev,
        field_IC: value,
        field_43: false,
        field_PED_tenure: { value: "1", warning: false },
      }));
    }
    setAddonStatus((prevState) => ({
      ...prevState,
      [attrName]:
        attrName === "GlobalCoverage" ||
          attrName === "field_PED_tenure" ||
          attrName === "field_OPD_data"
          ? { value: value, warning: false }
          : value,
    }));
  };

  const GET_ADDON_PREMIUM_CARE_HEARTH = () => {
    const onSuccess = (res: any) => {
      if (!res.error) {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        field_CS: addonStatus.field_CS === true ? 1 : 0,
        field_HomeCare: addonStatus.field_HomeCare === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_HEART(
      onSuccess,
      onError,
      param
    );
  };
  const GET_ADDON_PREMIUM_CARE_SUPREME = () => {
    const onSuccess = (res: any) => {
      if (!res.error) {
        dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        field_WB: addonStatus.field_WB === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
        field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
        field_43: addonStatus.field_43 === true ? 1 : 0,
        field_PED_TENURE: addonStatus.field_PED_tenure.value,
        field_CS: addonStatus.CareShield === true ? 1 : 0,
        field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
        field_AHC: addonStatus.field_AHC === true ? 1 : 0,
        field_IC: addonStatus.field_IC === true ? 1 : 0,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_SUPREME(
      onSuccess,
      onError,
      param
    );
  };

  const GET_ADDON_PREMIUM_CARE_SENIOR = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      setLoader(false);
      if (!res.error) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    setLoader(true);
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      pincode: CKYC_DETAILS.kycPincode,
      addon: {
        field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
        field_SS: addonStatus.SmartSelect === true ? 1 : 0,
        field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
        field_DK: addonStatus.field_DK === true ? 1 : 0,
        field_DUE: addonStatus.field_DUE === true ? 1 : 0,
        field_CS: addonStatus.CareShield === true ? 1 : 0,
        field_CPW: addonStatus.field_CPW === true ? 1 : 0,
        field_OPD: addonStatus.field_OPD === true ? 1 : 0,
        field_OPD_SI: addonStatus.field_OPD_data.value,
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIUM_CARE_SENIOR(
      onSuccess,
      onError,
      param
    );
  };

  const updatePreiumApi = () => {
    const onSuccess = (res: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      if (res.results.error === false) {
        setLoader(false);
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            ADDON_DETAILS: addonStatus,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
      console.log("err", err);
    };
    setLoader(true);
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        field_SS: addonStatus.SmartSelect === true ? "1" : "0",
        field_NCB: addonStatus.NCBSuper === true ? "1" : "0",
        field_35: addonStatus.AirAmbulanceCover === true ? "1" : "0",
        field_34: addonStatus.field_34 === true ? "1" : "0",
        field_CS: addonStatus.CareShield === true ? "1" : "0",
        field_UAR: "1",
        field_COPAY: addonStatus.field_COPAY === true ? "1" : "0",
        global_coverage: addonStatus.GlobalCoverage.value,
        field_OPD: addonStatus.field_OPD === true ? "1" : "0",
        field_AHC: addonStatus.field_AHC_Care_Advatage === true ? "1" : "0",
        field_43: addonStatus.field_43 === true ? "1" : "0",
      },
    };

    HEALTH_PROPOSAL_SERVICES.getPeriumByAddon(onSuccess, onError, param);
  };

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === 2651) {
      GET_ADDON_PREMIUM_CARE_SUPREME();
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 443) {
      GET_ADDON_PREMIUM_CARE_SENIOR();
    } else if (SELECTED_QUOTE_DATA.productDetails.id === 1551) {
      GET_ADDON_PREMIUM_CARE_HEARTH();
    } else {
      updatePreiumApi();
    }

  }, [addonStatus]);

  const validate = () => {
    dispatch(HealthSlice.actions.ADDON_DETAILS_DATA(addonStatus));
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        // ADD_API_DATA();
        setLoader(false);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    let param: any = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      pincode: CKYC_DETAILS.kycPincode,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      ...(SELECTED_QUOTE_DATA.productDetails.id === 2651
        ? {
          field_WB: addonStatus.field_WB === true ? 1 : 0,
          field_OPD: addonStatus.field_OPD === true ? 1 : 0,
          field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
          field_43: addonStatus.field_43 === true ? 1 : 0,
          field_PED_TENURE: addonStatus.field_PED_tenure.value,
          field_CS: addonStatus.CareShield === true ? 1 : 0,
          field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
          field_AHC: addonStatus.field_AHC === true ? 1 : 0,
          field_IC: addonStatus.field_IC === true ? 1 : 0,
        }
        : SELECTED_QUOTE_DATA.productDetails.id === 1551
          ? {
            field_CS: addonStatus.field_CS === true ? 1 : 0,
            field_HomeCare: addonStatus.field_HomeCare === true ? 1 : 0,
            field_OPD: addonStatus.field_OPD === true ? 1 : 0,
          }
          : SELECTED_QUOTE_DATA.productDetails.id === 443
            ? {
              field_NCB: addonStatus.NCBSuper === true ? 1 : 0,
              field_SS: addonStatus.SmartSelect === true ? 1 : 0,
              field_35: addonStatus.AirAmbulanceCover === true ? 1 : 0,
              field_DK: addonStatus.field_DK === true ? 1 : 0,
              field_DUE: addonStatus.field_DUE === true ? 1 : 0,
              field_CS: addonStatus.CareShield === true ? 1 : 0,
              field_CPW: addonStatus.field_CPW === true ? 1 : 0,
              field_OPD: addonStatus.field_OPD === true ? 1 : 0,
              field_OPD_SI: addonStatus.field_OPD_data.value,
            }
            : {
              field_SS: addonStatus.SmartSelect === true ? "1" : "0",
              field_NCB: addonStatus.NCBSuper === true ? "1" : "0",
              field_35: addonStatus.AirAmbulanceCover === true ? "1" : "0",
              field_34: addonStatus.field_34 === true ? "1" : "0",
              field_CS: addonStatus.CareShield === true ? "1" : "0",
              field_UAR: "1",
              field_COPAY: addonStatus.field_COPAY === true ? "1" : "0",
              global_coverage: addonStatus.GlobalCoverage.value,
              field_OPD: addonStatus.field_OPD === true ? "1" : "0",
              field_AHC: addonStatus.field_AHC_Care_Advatage === true ? "1" : "0",
              field_43: addonStatus.field_43 === true ? "1" : "0",
            }),
      section: "add_ons_details",
    };

    if (
      ADD_FORM_RESPONSE.sum_insured >= 20000000 &&
      (isEmpty(addonStatus.GlobalCoverage.value) ||
        addonStatus.GlobalCoverage.value === "Not Applicable")
    ) {
      toast.error("Select value in Global Coverage");
      return;
    }

    setLoader(true);
    if (PAGE_STATUS) {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    } else {
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
      );
    }

    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  useEffect(() => {
    const onSuccess = (res: any) => {
      if (!res.results.error) {
        setMandatoryAddon(res.results.response)
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };

    let param = {
      "quote_no": ADD_FORM_RESPONSE.quote_no
    }
    HEALTH_PROPOSAL_SERVICES.GET_MANDATORY_CARE_SUPREME_ADDON(onSuccess, onError, param)
  }, [])



  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          position="fixed"
          height="100%"
          width="100%"
          zIndex="991"
          top="0px"
          left="0px"
        />
      ) : null}
      {isMobile ? (
        <MAddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
        />
      ) : (
        <AddonDetails
          isLoading={loader}
          addonStatus={addonStatus}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          mandatoryAddon={mandatoryAddon}
        />
      )}
    </>
  );
}
