import Masonry from "@mui/lab/Masonry";
import { Box, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomButton from "../../../../../Component/CustomButton/CustomButton";
import RKTextField from "../../../../../Component/FieldTypes/RKTextField/RKTextField";
import HealthRedirectPopup from "../../../../../Component/HealthRedirectPopup/HealthRedirectPopup";
import Loader from "../../../../../Component/LoaderblackBg/Loader";
import HealthpreviewDetails from "../../../../../Component/PreviewDetailBox/HealthpreviewDetails/HealthpreviewDetails";
import MedicalDetailPreviewBoxCigna from "../../../../../Container/Health/CIGNA/MedicalDetailPreviewBoxCigna";
import { EMotorCompCode } from "../../../../../Enum/EMotorCompCode";
import "../../../../../SCSS/CommonSCSS/ProposalForms.scss";
import { ECompanyCode, EProductId } from "../../../../../Services/Enum/EHome";
import { useAppSelector } from "../../../../../Store/hooks";
import { formatAmountToCurrency } from "../../../../../SupportingFiles/HelpingFunction";
import { COLORS } from "../../../../../SupportingFiles/colors";
import MedicalDetailPreviewBoxAdityaBirla from "../../ADITYA_BIRLA/medicalDetails/MedicalDetailPreviewBoxAdityaBirla/MedicalDetailPreviewBoxAdityaBirla";
import MedicalDetailPreviewBoxBajaj from "../../BAJAJ/MedicalDetailPreviewBoxBajaj";
import MedicalDetailPreviewBoxGoDigit from "../../GODIGIT/MedicalDetailPreviewBoxGODigit/MedicalDetailPreviewBoxGODigit";
import MedicalDetailPreviewBoxOptimaSecure from "../../HDFC/MedicalDetailPreviewBoxOptimaSecure";
import MedicalDetailPreviewBoxHdfc from "../../HDFC/MedicalDetails/MedicalDetailPreviewBoxHdfc/MedicalDetailPreviewBoxHdfc";
import MedicalDetailPreviewBoxOptimaRestore from "../../HDFC/MedicalDetails/MedicalDetailPreviewBoxOptimaRestore";
import HealthFooter from "../../HealthFooter/HealthFooter";
import MedicalDetailPreviewBoxIcic from "../../ICIC/MedicalDetailPreviewBoxIcic/MedicalDetailPreviewBoxIcic";
import MedicalDetailPreviewBoxKotak from "../../Kotak/MedicalDetailKotak/MedicalDetailPreviewBoxKotak/MedicalDetailPreviewBoxKotak";
import MedicalDetailPreviewBoxNivabupa from "../../NIVA_BUPA/medicalDetails/MedicalDetailPreviewBoxNivabupa/MedicalDetailPreviewBoxNivabupa";
import MedicalDetailPreviewBoxNivabupaSen from "../../NIVA_BUPA/medicalDetails/MedicalDetailPreviewBoxNivabupa/MedicalDetailPreviewBoxNivabupaSen";
import MedicalDetailPreviewBoxRSA from "../../RSA/MedicalDetailPreviewBoxRSA";
import MedicalDetailPreviewBoxStar from "../../STAR/MedicalDetailPreviewBox/MedicalDetailPreviewBoxStar";
import MedicalDetailPreviewRedCarPet from "../../STAR/MedicalDetailPreviewBox/MedicalDetailPreviewRedCarpet";
import MedicalDetailPreviewBox from "../MedicalDetailPreviewBox/MedicalDetailPreviewBox";
import MedicalDetailPreviewBoxCareHeart from "../MedicalDetailPreviewBox/MedicalDetailPreviewBoxCareHeart";
import { toast } from "react-toastify";

function PreviewDetails({
  PreviewDetails,
  navigate,
  VERIFY_OTP,
  CREATE_PROPOSAL,
  loader,
  handleContinue,
  openRedirectPopup,
  setOpenRedirectPopup,
  paymentForm,
  paymentFormData,
}: {
  PreviewDetails: any;
  navigate: Function;
  VERIFY_OTP: Function;
  CREATE_PROPOSAL: Function;
  loader: boolean;
  handleContinue: Function;
  openRedirectPopup: boolean;
  setOpenRedirectPopup: Function;
  paymentForm: any;
  paymentFormData: any;
}) {
  const { SELECTED_QUOTE_DATA, ADDRESS_DETAILS, QUOTE_LOADER } = useAppSelector(
    (state) => state.Health
  );
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [otpValue, setOtpValue] = useState("");
  const handleUpdateOTP = (attrName: any, value: any) => {
    setOtpValue(value);
  };

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.CARE_ADVANTAGE) {
      toast.info(`Your premium is revised from (quote premium) to (proposal premium) due to mentioned PED`);
    }
  }, [])

  return (
    <>
      {QUOTE_LOADER ? (
        <Box
          sx={{
            backgroundColor: "rgba(20,20,20, 0.9)",
            height: "100%",
            width: "100%",
            position: "fixed",
            left: 0,
            top: "0px",
            zIndex: "991",
            flexDirection: "column",
            gap: "16px",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {/* <Loader size="small" /> */}
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <Loader size="large" />
            <h5
              style={{
                color: COLORS.white,
                textAlign: "center",
                marginTop: "4px",
              }}>
              Please wait...
            </h5>
          </Box>
        </Box>
      ) : (
        <Box className="proposalPreview">
          {/* OTP Modal start */}
          <Modal open={showOTPPopup} className="modalWrapper">
            <Box className="modalContent xsWidth kycPopups">
              <Grid container spacing={2}>
                <Grid xs={12} textAlign="center">
                  <img
                    alt=""
                    src="../images/enter-otp.svg"
                    width="160px"
                    className="mt-6"
                  />
                  <h4 className="popup-heading mb-2 mt-6">Enter OTP</h4>
                  <p className="grey_p">Enter the OTP to proceed</p>
                  <Link
                    to={""}
                    className="close-button"
                    onClick={() => {
                      setShowOTPPopup(false);
                      setOtpValue("");
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} textAlign="center">
                <Grid xs={8} xsOffset={2}>
                  <RKTextField
                    class_name="inputField"
                    title={"Enter OTP"}
                    value={otpValue}
                    attrName={"otpValue"}
                    value_update={handleUpdateOTP}
                    error_message={"Enter OTP"}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} marginTop={"24px"}>
                <Grid xs={12} style={{ textAlign: "center" }}>
                  <CustomButton
                    text_name="Create Proposal"
                    class_name="regularPrimaryBtn mb-3"
                    onClickFunction={VERIFY_OTP}
                  />
                </Grid>
              </Grid>
            </Box>
          </Modal>
          {/* OTP Modal End */}

          <h5 className="sectionTitle">
            Just make sure, all details are correct
          </h5>
          <Masonry columns={2} spacing={3} className="px-0 mx-0">
            {PreviewDetails?.map((data: any, index: any) =>
              data.detailView.length > 0 ? (
                <HealthpreviewDetails
                  key={index}
                  isMailingAddresssame={ADDRESS_DETAILS.isMailingAddressSame}
                  title={data?.title}
                  mailing_detail_view={data.mailing_detail_view}
                  preViewData={data?.detailView}
                  url={data.url === undefined ? "" : data.url}
                />
              ) : null
            )}
          </Masonry>
          {/* {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH_Global ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH_Plan ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ?
            <MedicalDetailPreviewBoxHdfc />
            : null} */}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE || SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_RESTORE_FAMILY_PLAN ? <MedicalDetailPreviewBoxOptimaRestore /> : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH_Global ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_HEALTH_Plan ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.HDFC_OPTIMA_SUPER_SECURE_Plan ? (
            <MedicalDetailPreviewBoxOptimaSecure />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.STAR_COMP_PRODUCT_ID ||
            SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.START_RED_CARPET ? (
            <MedicalDetailPreviewRedCarPet />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id ===
            EProductId.STAR_FHO_PRODUCT_ID ? (
            // <MedicalDetailPreviewFHO />
            <MedicalDetailPreviewBoxStar />
          ) : null}
          {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA &&
            SELECTED_QUOTE_DATA.productDetails.id === 2592) ||
            SELECTED_QUOTE_DATA.productDetails.id === 2593 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2594 ? (
            <MedicalDetailPreviewBoxNivabupa />
          ) : null}
          {(SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.RSA &&
            SELECTED_QUOTE_DATA.productDetails.id === 98) ||
            SELECTED_QUOTE_DATA.productDetails.id === 99 ||
            SELECTED_QUOTE_DATA.productDetails.id === 100 ? (
            <MedicalDetailPreviewBoxRSA />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.GO_DIGIT ? (
            <MedicalDetailPreviewBoxGoDigit />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.KOTAK ? (
            <MedicalDetailPreviewBoxKotak />
          ) : null}
          {(ECompanyCode.NIVA_BUPA &&
            SELECTED_QUOTE_DATA.productDetails.id === 2890) ||
            SELECTED_QUOTE_DATA.productDetails.id === 2891 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2892 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2893 ? (
            <MedicalDetailPreviewBoxNivabupaSen />
          ) : null}
          {(ECompanyCode.ADITY_BIRLA &&
            SELECTED_QUOTE_DATA.productDetails.id === 2902) ||
            SELECTED_QUOTE_DATA.productDetails.id === 2903 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2904 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2905 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2906 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2907 ||
            SELECTED_QUOTE_DATA.productDetails.id === 2908 ? (
            <MedicalDetailPreviewBoxAdityaBirla />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.BAJAJ ? (
            <MedicalDetailPreviewBoxBajaj />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.CIGNA ? (
            <MedicalDetailPreviewBoxCigna />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.CARE &&
            SELECTED_QUOTE_DATA.productDetails.id !== 1551 ? (
            <MedicalDetailPreviewBox />
          ) : null}
          {SELECTED_QUOTE_DATA.productDetails.id === 1551 ? (
            <MedicalDetailPreviewBoxCareHeart />
          ) : null}
          {SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.ICIC ? (
            <MedicalDetailPreviewBoxIcic />
          ) : null}

          {/* "" */}

          <HealthFooter
            loader={loader}
            attrName={"pageStatus"}
            textName={
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G016"
                ? "Proceed Proposal"
                : SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G009"
                  ? "Continue"
                  : "Make Payment"
            }
            value={1}
            forward={
              SELECTED_QUOTE_DATA?.CompanyDetails.company_code ===
                EMotorCompCode.RSA
                ? handleContinue
                : CREATE_PROPOSAL
            }
            backward={() => {
              navigate(-1);
            }}
            disableButton={
              formatAmountToCurrency(
                SELECTED_QUOTE_DATA?.premiumDetails.finalPremium
              ) === "₹ NaN" ||
                SELECTED_QUOTE_DATA?.premiumDetails.finalPremium === 0
                ? true
                : false
            }
          />
          <HealthRedirectPopup
            open={openRedirectPopup}
            setOpen={setOpenRedirectPopup}
            form={paymentForm}
            paymentFormData={paymentFormData}
          />
        </Box>
      )}
    </>
  );
}

export default PreviewDetails;
