import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AdultTwoDetails from "../../../../Page/Desktop/Health/CARE/AdultTwoDetails/AdultTwoDetails";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import {
  calculateAge,
  calculateAgeInDays,
  calculateBMI,
  isEmpty,
  validateFullName,
} from "../../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { TDropdown } from "../../../../types/Common/TDropdown";
import {
  TOtherMemberDetails,
  TTwoAdultMemberDetails,
} from "../../../../types/Health/ProposalDeatail/TProposalDetail";
import { toast } from "react-toastify";
import { ECompanyCode, EProductId } from "../../../../Services/Enum/EHome";
import MAdultTwoDetails from "../../../../Page/Mobile/Health/CARE/MAdultTwoDetails/MAdultTwoDetails";
import { TDropdownData } from "../../../../types/DropdownData/DropdownData";

export default function MemberDetailContainer() {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const {
    ADD_FORM,
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    ADD_FORM_RESPONSE,
    NOMINEE_RELATIONSHIP_STATUS,
    SELECTED_QUOTE_DATA,
  } = useAppSelector((state) => state.Health);
  const [memberFields, setMemberFields] = useState<TTwoAdultMemberDetails>(
    INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS
  );
  const { Health } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();
  const [occupationList, setOpccupationList] = useState<TDropdownData[]>([]);
  const [relationshipList, setRelationshipList] = useState<TDropdownData[]>([]);


  const getOccupationData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.results?.response?.map(
        (maritalData: any) => ({
          value:
            SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G023"
              ? `${maritalData.code}-${maritalData.title}`
              : maritalData.code,
          label: maritalData.title,
        })
      );
      setOpccupationList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
    };
    HEALTH_PROPOSAL_SERVICES.getOccupationData(onSuccess, onError, param);
  };

  const getRealtionData = () => {
    const onSuccess = (res: any) => {
      let data: TDropdown[] = res?.results?.response?.map(
        (maritalData: any) => ({
          label: maritalData.title,
          value: maritalData.value,
        })
      );
      // dispatch(
      //   HealthSlice.actions.HEALTH_DROPDOWNDATA({
      //     key: "ADULT2_RELATIONSHIP",
      //     value: data,
      //   })
      // );
      setRelationshipList(data);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      relationship: "adult2",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    HEALTH_PROPOSAL_SERVICES.getRelationData(onSuccess, onError, param);
  };

  const fieldsUpdateState = (attrName: string, value: any) => {
    let updatedImageClass = "";

    if (attrName === "relationship") {
      if (value === "Father") {
        updatedImageClass = "Father";
      } else if (value === "Mother") {
        updatedImageClass = "Mother";
      } else {
        updatedImageClass = "Spouse";
      }
    }

    // Determine warning based on the attribute name
    const warning =
      attrName === "name" ? !validateFullName(value) : isEmpty(value);

    setMemberFields((prev: any) => ({
      ...prev,
      [attrName]: {
        ...prev[attrName],
        value: value,
        warning: warning,
      },
      image_class: updatedImageClass,
    }));
  };

  const UPDATE_PROPOSER_NOMINEE_DATA = (
    ADULT_TWO_DETAILS: TTwoAdultMemberDetails
  ) => {
    const onSuccess = (res: any) => {
      console.log("res...", res?.results);
    };
    const onError = (err: any) => {
      console.log("err..", err);
    };
    let param = {
      adult2Relationship: ADULT_TWO_DETAILS.relationship.value,
      spouseName: ADULT_TWO_DETAILS.name.value,
      spouseDOB: ADULT_TWO_DETAILS.dob.value,
      spouseWeight: ADULT_TWO_DETAILS.weight.value,
      spouseHeightFeet: ADULT_TWO_DETAILS.heightFeet.value,
      spouseHeightInches: ADULT_TWO_DETAILS.heightInches.value,
      spouseOccupation: ADULT_TWO_DETAILS.occupation.value,
      spouseQualification: ADULT_TWO_DETAILS.qualification.value,
      section: "adult2_details",
      quote_no: ADD_FORM_RESPONSE.quote_no,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
    };
    HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
  };

  const validateForm = () => {
    let formValue = { ...memberFields };
    formValue = {
      ...formValue,
      dob: { ...formValue.dob, warning: isEmpty(formValue.dob.value) },
      heightFeet: {
        ...formValue.heightFeet,
        warning: isEmpty(formValue.heightFeet.value),
      },
      heightInches: {
        ...formValue.heightInches,
        warning: isEmpty(formValue.heightInches.value),
      },
      name: {
        ...formValue.name,
        warning: !validateFullName(formValue.name.value),
      },
      relationship: {
        ...formValue.relationship,
        warning: isEmpty(formValue.relationship.value),
      },
      weight: { ...formValue.weight, warning: isEmpty(formValue.weight.value) },
      occupation: {
        ...formValue.occupation,
        warning:
          isEmpty(formValue.occupation.value) &&
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA &&
            isEmpty(formValue.occupation.value)
            ? true
            : false,
      },
      qualification: {
        ...formValue.qualification,
        warning:
          isEmpty(formValue.qualification.value) &&
            SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
            ECompanyCode.NIVA_BUPA &&
            isEmpty(formValue.qualification.value)
            ? true
            : false,
      },
    };
    // if (
    //   SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.NIVA_BUPA
    // ) {
    //   formValue = {
    //     ...formValue,
    //     occupation: {
    //       ...formValue.occupation,
    //       warning: isEmpty(formValue.occupation.value),
    //     },
    //     qualification: {
    //       ...formValue.qualification,
    //       warning: isEmpty(formValue.qualification.value),
    //     },
    //   };
    // }
    setMemberFields({ ...formValue });
    if (
      !formValue.dob.warning &&
      !formValue.heightFeet.warning &&
      !formValue.heightInches.warning &&
      !formValue.name.warning &&
      !formValue.relationship.warning &&
      !formValue.occupation.warning &&
      !formValue.qualification.warning &&
      !formValue.weight.warning
    ) {
      if (NOMINEE_RELATIONSHIP_STATUS !== "OTHER") {
        //  dispatch(
        //    HealthSlice.actions.SAVE_INSURED_MEMBER_DETAILS({
        //      ...Health.INSURED_MEMBER_DETAILS,
        //      INSURED_MEMBER_DETAILS: {
        //        NOMINEE__DETAILS: {
        //          full_name: { value: formValue.name.value, warning: false },
        //          dob: { value: formValue.dob.value, warning: false },
        //          relationship: {
        //            value: formValue.relationship.value,
        //            warning: false,
        //          },
        //        },
        //      },
        //      PAGE_STATUS: false,
        //    })
        //  );
      }
      const total_child = ADD_FORM.children;

      UPDATE_PROPOSER_NOMINEE_DATA(formValue);
      let hasError = false;
      const bmi = calculateBMI(
        formValue.weight.value,
        formValue.heightFeet.value,
        formValue.heightInches.value
      );
      const roundedBMI = Math.round(bmi);
      console.log("roundedBMI", roundedBMI);
      let decision = "";
      // if (
      //   SELECTED_QUOTE_DATA.productDetails.id ===
      //   ECompanyCode.HDFC_HEALTH
      // ) {
      //   if (calculateAgeInDays(`${formValue.dob.value}`) >= 20090) {
      //     toast.error("Age must be below 55 years or equal to 55 years.");
      //     hasError = true;
      //   } else {
      //     // Additional logic for this condition
      //   }
      // }
      if (SELECTED_QUOTE_DATA.CompanyDetails.company_code === "G033") {
        if (roundedBMI <= 19 || roundedBMI > 35) {
          toast.error("BMI falls between 20 to 35 range!");
          hasError = true;
        }
      }
      if (
        SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.HDFC_HEALTH ||
        SELECTED_QUOTE_DATA.productDetails.id ===
        EProductId.HDFC_HEALTH_Global
      ) {
        if (calculateAge(formValue.dob.value) >= 65) {
          toast.error("Age must be below 65 years or equal to 65 years.");
          hasError = true;
        }
      }
      if (SELECTED_QUOTE_DATA.productDetails.id === 445) {
        if (roundedBMI >= 17 && roundedBMI <= 35) {
          decision = "STP";
          toast.success("BMI falls within STP range!");
        } else if (roundedBMI > 35 && roundedBMI <= 37) {
          const loadedBMI = roundedBMI * 1.1;
          decision = "NSTP";
          toast.warn(
            `BMI exceeded 35 but below or equal to 37. Applying 10% loading. New BMI: ${loadedBMI}`
          );
        } else if (roundedBMI < 17 || roundedBMI > 37) {
          decision = "Reject";
          toast.error("BMI falls outside acceptable range!");
          hasError = true;
        }
      }

      if (SELECTED_QUOTE_DATA.productDetails.id === EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.NIVA_BUPA_Senior_First_Platinum_Floater ||
        SELECTED_QUOTE_DATA.productDetails.id === EProductId.NIVA_BUPA_Senior_First_Platinum_IND
      ) {
        if (roundedBMI < 15 || roundedBMI > 37) {
          toast.error("BMI falls outside acceptable range!");
          hasError = true;
        }
      }

      // if (
      //   SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
      //   ECompanyCode.GO_DIGIT
      // ) {
      //   const age = calculateAge(memberFields.dob.value);
      //   const relationship = memberFields.relationship.value;
      //   if (
      //     (age < 35 || age > 60) &&
      //     (relationship === "Father" || relationship === "Mother")
      //   ) {
      //     toast.error("Adult Age Should be greater than 35 and less than 60");
      //     return;
      //   }
      // }
      if (PAGE_STATUS) {
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              ADULT_TWO_DETAILS: { ...formValue },
            },
          })
        );
        if (!hasError) {
          navigate(
            `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}`
          );
        }
      } else {
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            INSURED_MEMBER_DETAILS: {
              ...Health.INSURED_MEMBER_DETAILS,
              ADULT_TWO_DETAILS: { ...formValue },
            },
            PAGE_STATUS: false,
          })
        );
        if (total_child > 0) {
          if (!hasError) {
            navigate(
              `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.CHILD_ONE_DETAILS}`
            );
          }
        } else {
          if (!hasError) {
            if (
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.HDFC_HEALTH ||
              SELECTED_QUOTE_DATA.CompanyDetails.company_code === ECompanyCode.NIVA_BUPA || SELECTED_QUOTE_DATA.CompanyDetails.company_code ===
              ECompanyCode.ADITY_BIRLA
            ) {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.BANK_DETAILS}`
              );
            } else {
              navigate(
                `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDRESS_DETAILS}`
              );
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    getRealtionData();
    getOccupationData();
  }, []);

  return isMobile ? (
    <MAdultTwoDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
    />
  ) : (
    <AdultTwoDetails
      fieldsUpdateState={fieldsUpdateState}
      memberFields={memberFields}
      validateForm={validateForm}
      occupationList={occupationList}
      relationshipList={relationshipList}
    />
  );
}
