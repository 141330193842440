import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddonDetailsNIVABUPA from "../../../Page/Desktop/Health/NIVA_BUPA/AddonDetails/AddonDetailsNIVABUPA";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { EProductId } from "../../../Services/Enum/EHome";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  calculateAge,
  isEmpty,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { TNivaBupaAddonQuestion } from "../../../types/Health/HealthQuotation/TNivaBupaAddonQuestion";
import { TNIVABUPAAddons } from "../../../types/Health/ProposalDeatail/TNIVABUPA/TNIVABUPAAddon";
import { toast } from "react-toastify";
import MAddonDetailsNIVABUPA from "../../../Page/Mobile/Health/NIVA_BUPA/MAddonDetails/MAddonDetailsNIVABUPA";
import { CKYC_ROUTES } from "../../../Router/Path/CKYCRoutes";
import Loader from "../../../Component/Loader/Loader";

const AddonDetailsCOntainerNIVABUPA = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { Health, CKYC } = useAppSelector((state) => state);
  const {
    ADD_FORM,
    ADD_FORM_RESPONSE,
    ADDON_DETAILS_NIVA_BUPA,
    SELECTED_QUOTE_DATA,
    PAGE_STATUS,
    ADDON_QUESTION_NIVA_BUPA,
    INSURED_MEMBER_DETAILS,
    Preview_Loader,
    POLICY_TENURE,
  } = useAppSelector((state) => state.Health);
  const [loader, setLoader] = useState<boolean>(false);
  const [addonStatus, setAddonStatus] = useState<TNIVABUPAAddons>(
    ADDON_DETAILS_NIVA_BUPA
  );
  const [isCheckMemberSelect, setIsCheckMemberSelect] = useState<boolean>(true);

  const [addonQuestionData, setAddonQuestionData] =
    useState<TNivaBupaAddonQuestion>(ADDON_QUESTION_NIVA_BUPA);

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setAddonQuestionData(ADDON_QUESTION_NIVA_BUPA);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [ADDON_QUESTION_NIVA_BUPA]);

  useEffect(() => {
    if (
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_SENIOR_FIRST_GOLD_IND ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Platinum_IND ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Gold_Floater ||
      SELECTED_QUOTE_DATA.productDetails.id ===
      EProductId.NIVA_BUPA_Senior_First_Platinum_Floater
    ) {
      updatePreiumApiSenior();
    } else {
      updatePreiumApi();
    }
  }, [addonQuestionData]);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...ADDON_QUESTION_NIVA_BUPA };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Hospital Cash",
          description:
            "Get cash benefit on per day basis of hospitalization (up to 30 days) if hospitalised for more than 48 hours",
          status: false,
          field_data: {
            "MQ1-SQ1-F1": {
              id: "MQ1-SQ1-F1",
              title: "HVB Value",
              field_type: "DROPDOWN",
              value: { value: "0", warning: false },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Acute Care Opted",
          description:
            "Unlimited tele-consultations with Doctors (General Medical Practitioners, Specialists & Super Specialists). Also, covers pharmacy and diagnostics up to limits.",
          status: false,
          field_data: {
            "MQ1-SQ1-F1": {
              id: "MQ1-SQ1-F1",
              title: "BEST CONSULT",
              field_type: "CHECKBOX",
              value: { value: false, warning: false },
            },
            "MQ1-SQ1-F2": {
              id: "MQ1-SQ1-F2",
              title: "BEST CARE",
              field_type: "CHECKBOX",
              value: { value: false, warning: false },
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "Safeguard Opted",
          description:
            "Get access to additional benefits like- annual increase in coverage based on inflation rate, coverage for non-payable items and no impact on booster benefit.",
          status: false,
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "Safeguard+ Opted",
          description:
            "Get access to additional benefits like - annual increase in coverage based on inflation rate, Coverage for non-payable items (as per annexure 1), and protection of Booster+ benefit from small claims up to INR 1 Lakh",
          status: false,
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "Personal Accident Cover",
          description:
            "Get additional protection from Accidental Death, Permanent Total Disability and Permanent Partial Disability. Coverage Equal to 5 times of Base Sum Insured, Maximum up to INR 1 Crore.",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              // name: `${CKYC.CKYC_DETAILS.kycCustomerName}`,
              name: ADD_FORM.self_status ? "Adult 1" : "",
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: ADD_FORM.spouse_status ? "Adult 2" : "",
              image_class: "adult",
              selection_status: false,
            },
            // child_one: {
            //   keyName: "child_one",
            //   name:
            //     ADD_FORM.children !== 0 &&
            //       calculateAge(ADD_FORM.child_one_dob.value) >= 18
            //       ? "Child One"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            // },
            // child_two: {
            //   keyName: "child_two",
            //   name:
            //     ADD_FORM.children > 1 &&
            //       calculateAge(ADD_FORM.child_two_dob.value) >= 18
            //       ? "Child Two"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            // },
            // child_three: {
            //   keyName: "child_three",
            //   name:
            //     ADD_FORM.children > 2 &&
            //       calculateAge(ADD_FORM.child_three_dob.value) >= 18
            //       ? "Child three"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            // },
            // child_four: {
            //   keyName: "child_four",
            //   name:
            //     ADD_FORM.children > 3 &&
            //       calculateAge(ADD_FORM.child_four_dob.value) >= 18
            //       ? "Child four"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            // },
          },
        },
      },
      MQ06: {
        main_question: {
          id: "MQ06",
          icon_class: "tobaco",
          title: "Disease Management",
          description:
            "Get day 1 coverage for Diabetes and Hypertension. Along with up to 20% renewal discount. (*Variant under Smart Health+ Add On.)",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              // name: `${CKYC.CKYC_DETAILS.kycCustomerName}`,
              name: ADD_FORM.self_status ? "Adult 1" : "",
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Gold",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Platinum",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
              },
            },
            adult_two: {
              keyName: "adult_two",
              name: ADD_FORM.spouse_status ? "Adult 2" : "",
              image_class: "adult",
              selection_status: false,
              field_data: {
                "MQ1-SQ1-F1": {
                  id: "MQ1-SQ1-F1",
                  title: "Gold",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
                "MQ1-SQ1-F2": {
                  id: "MQ1-SQ1-F2",
                  title: "Platinum",
                  field_type: "CHECKBOX",
                  value: { value: false, warning: false },
                },
              },
            },
            // child_one: {
            //   keyName: "child_one",
            //   name:
            //     ADD_FORM.children !== 0 &&
            //       calculateAge(ADD_FORM.child_one_dob.value) >= 18
            //       ? "Child One"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            //   field_data: {
            //     "MQ1-SQ1-F1": {
            //       id: "MQ1-SQ1-F1",
            //       title: "Gold",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //     "MQ1-SQ1-F2": {
            //       id: "MQ1-SQ1-F2",
            //       title: "Platinum",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //   },
            // },
            // child_two: {
            //   keyName: "child_two",
            //   name:
            //     ADD_FORM.children > 1 &&
            //       calculateAge(ADD_FORM.child_two_dob.value) >= 18
            //       ? "Child Two"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            //   field_data: {
            //     "MQ1-SQ1-F1": {
            //       id: "MQ1-SQ1-F1",
            //       title: "Gold",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //     "MQ1-SQ1-F2": {
            //       id: "MQ1-SQ1-F2",
            //       title: "Platinum",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //   },
            // },
            // child_three: {
            //   keyName: "child_three",
            //   name:
            //     ADD_FORM.children > 2 &&
            //       calculateAge(ADD_FORM.child_three_dob.value) >= 18
            //       ? "Child three"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            //   field_data: {
            //     "MQ1-SQ1-F1": {
            //       id: "MQ1-SQ1-F1",
            //       title: "Gold",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //     "MQ1-SQ1-F2": {
            //       id: "MQ1-SQ1-F2",
            //       title: "Platinum",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //   },
            // },
            // child_four: {
            //   keyName: "child_four",
            //   name:
            //     ADD_FORM.children === 4 &&
            //       calculateAge(ADD_FORM.child_four_dob.value) >= 18
            //       ? "Child four"
            //       : "",
            //   image_class: "child",
            //   selection_status: false,
            //   field_data: {
            //     "MQ1-SQ1-F1": {
            //       id: "MQ1-SQ1-F1",
            //       title: "Gold",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //     "MQ1-SQ1-F2": {
            //       id: "MQ1-SQ1-F2",
            //       title: "Platinum",
            //       field_type: "CHECKBOX",
            //       value: { value: false, warning: false },
            //     },
            //   },
            // },
          },
        },
      },
    };
    setAddonQuestionData({ ...setMemberData });
  };

  console.log("dfvddf", calculateAge(ADD_FORM.child_one_dob.value));
  let flag = true;

  const updateMasterState = (attrName: any, value: any) => {
    flag = value;

    let manipulatedAddonQuestion: any = JSON.parse(
      JSON.stringify(addonQuestionData)
    );

    if (attrName === "dropdown") {
      manipulatedAddonQuestion["MQ01"].main_question.field_data[
        "MQ1-SQ1-F1"
      ].value = {
        value: value,
        warning: false,
      };
      setAddonQuestionData({ ...manipulatedAddonQuestion });
    }
    if (attrName[0] === "question" && attrName[1] === "MQ02") {
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F1"
      ].value = {
        value: true,
        warning: false,
      };
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F2"
      ].value = {
        value: false,
        warning: false,
      };

      setAddonQuestionData({ ...manipulatedAddonQuestion });
    }

    if (attrName[0] === "question" && attrName[1] === "MQ03") {
      manipulatedAddonQuestion["MQ03"].main_question.status = true;
      manipulatedAddonQuestion["MQ04"].main_question.status = false;

      setAddonQuestionData({ ...manipulatedAddonQuestion });
    }
    if (attrName[0] === "question" && attrName[1] === "MQ04") {
      manipulatedAddonQuestion["MQ03"].main_question.status = false;
      manipulatedAddonQuestion["MQ04"].main_question.status = true;

      setAddonQuestionData({ ...manipulatedAddonQuestion });
    }

    if (attrName[0] === "question") {
      manipulatedAddonQuestion = {
        ...manipulatedAddonQuestion,
        [attrName[1]]: {
          ...manipulatedAddonQuestion[attrName[1]],
          main_question: {
            ...manipulatedAddonQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      setAddonQuestionData({ ...manipulatedAddonQuestion });

      if (
        (value === false && attrName[1] === "MQ05") ||
        (value === false && attrName[1] === "MQ06")
      ) {
        manipulatedAddonQuestion[attrName[1]].main_question.member_data = {
          ...manipulatedAddonQuestion[attrName[1]].main_question.member_data,
          adult_one: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .adult_one,
            selection_status: false,
          },
          adult_two: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .adult_two,
            selection_status: false,
          },
          child_one: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .child_one,
            selection_status: false,
          },
          child_two: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .child_two,
            selection_status: false,
          },
          child_three: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .child_three,
            selection_status: false,
          },
          child_four: {
            ...manipulatedAddonQuestion[attrName[1]].main_question.member_data
              .child_four,
            selection_status: false,
          },
        };
      }
      if (value === false && attrName[1] === "MQ06") {
        const updateFieldDataValuesToFalse = (fieldData: any) => {
          Object.keys(fieldData).forEach((key) => {
            fieldData[key].value.value = false;
          });
        };

        const mainQuestion =
          manipulatedAddonQuestion[attrName[1]].main_question;
        const memberData = mainQuestion.member_data;

        updateFieldDataValuesToFalse(memberData.adult_one.field_data);
        updateFieldDataValuesToFalse(memberData.adult_two.field_data);
        updateFieldDataValuesToFalse(memberData.child_one.field_data);
        updateFieldDataValuesToFalse(memberData.child_two.field_data);
        updateFieldDataValuesToFalse(memberData.child_three.field_data);
        updateFieldDataValuesToFalse(memberData.child_four.field_data);

        mainQuestion.member_data = {
          ...memberData,
          adult_one: {
            ...memberData.adult_one,
            field_data: memberData.adult_one.field_data,
          },
          adult_two: {
            ...memberData.adult_two,
            selection_status: false,
            field_data: memberData.adult_two.field_data,
          },
          child_one: {
            ...memberData.child_one,
            selection_status: false,
            field_data: memberData.child_one.field_data,
          },
          child_two: {
            ...memberData.child_two,
            selection_status: false,
            field_data: memberData.child_two.field_data,
          },
          child_three: {
            ...memberData.child_three,
            selection_status: false,
            field_data: memberData.child_three.field_data,
          },
          child_four: {
            ...memberData.child_four,
            selection_status: false,
            field_data: memberData.child_four.field_data,
          },
        };
      }
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedAddonQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedAddonQuestion = {
          ...manipulatedAddonQuestion,
          [attrName[1]]: {
            ...manipulatedAddonQuestion[attrName[1]],
            main_question: {
              ...manipulatedAddonQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedAddonQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedAddonQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedAddonQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedAddonQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
      }
    }
    // Check if the first attribute is "mainMemberData"
    else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedAddonQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      ) {
        manipulatedAddonQuestion = {
          ...manipulatedAddonQuestion,
          [attrName[1]]: {
            ...manipulatedAddonQuestion[attrName[1]],
            main_question: {
              ...manipulatedAddonQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedAddonQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedAddonQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };
        setAddonQuestionData({ ...manipulatedAddonQuestion });
      }
    } else if (attrName[4] === "checkbox" && attrName[3] === "MQ1-SQ1-F1") {
      if (
        attrName[2] === "adult_one" ||
        attrName[2] === "adult_two" ||
        attrName[2] === "child_one" ||
        attrName[2] === "child_two" ||
        attrName[2] === "child_three" ||
        (attrName[2] === "child_four" && manipulatedAddonQuestion !== undefined)
      ) {
        manipulatedAddonQuestion["MQ06"].main_question.member_data[
          attrName[2]
        ].field_data["MQ1-SQ1-F1"].value = {
          value: value,
          warning: isEmpty(value),
        };
        manipulatedAddonQuestion["MQ06"].main_question.member_data[
          attrName[2]
        ].field_data["MQ1-SQ1-F2"].value = {
          value: false,
          warning: isEmpty(value),
        };

        setAddonQuestionData({ ...manipulatedAddonQuestion });
      }
    } else if (attrName[4] === "checkbox" && attrName[3] === "MQ1-SQ1-F2") {
      if (
        attrName[2] === "adult_one" ||
        attrName[2] === "adult_two" ||
        attrName[2] === "child_one" ||
        attrName[2] === "child_two" ||
        attrName[2] === "child_three" ||
        (attrName[2] === "child_four" && manipulatedAddonQuestion !== undefined)
      ) {
        manipulatedAddonQuestion["MQ06"].main_question.member_data[
          attrName[2]
        ].field_data["MQ1-SQ1-F1"].value = {
          value: false,
          warning: isEmpty(value),
        };
        manipulatedAddonQuestion["MQ06"].main_question.member_data[
          attrName[2]
        ].field_data["MQ1-SQ1-F2"].value = {
          value: value,
          warning: isEmpty(value),
        };

        setAddonQuestionData({ ...manipulatedAddonQuestion });
      }
    }
    // Update the state with the modified manipulatedAddonQuestion object
    setAddonQuestionData(manipulatedAddonQuestion);
    if (attrName === "best_consult") {
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F1"
      ].value = {
        value: value,
        warning: isEmpty(value),
      };
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F2"
      ].value = {
        value: false,
        warning: isEmpty(value),
      };
      setAddonQuestionData({ ...manipulatedAddonQuestion });
    } else if (attrName === "best_care") {
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F2"
      ].value = {
        value: value,
        warning: isEmpty(value),
      };
      manipulatedAddonQuestion["MQ02"].main_question.field_data[
        "MQ1-SQ1-F1"
      ].value = {
        value: false,
        warning: isEmpty(value),
      };
      setAddonQuestionData({ ...manipulatedAddonQuestion });
    } else if (attrName === "best_care_value") {
      setAddonStatus((prev) => ({
        ...prev,
        best_care_value: value,
      }));
    } else if (attrName[0] === "question" && attrName[1] === "MQ02") {
      setAddonStatus((prev) => ({
        ...prev,
        best_care_value: "",
        best_consult: false,
        best_care: false,
      }));
    } else if (attrName === "platinum_adult_one") {
      setAddonStatus((prev) => ({
        ...prev,
        platinum_adult_one: true,
        gold_adult_one: false,
      }));
    } else if (attrName === "platinum_adult_two") {
      setAddonStatus((prev) => ({
        ...prev,
        platinum_adult_two: true,
        gold_adult_two: false,
      }));
    } else if (attrName === "gold_adult_one") {
      setAddonStatus((prev) => ({
        ...prev,
        platinum_adult_one: false,
        gold_adult_one: true,
      }));
    } else if (attrName === "gold_adult_two") {
      setAddonStatus((prev) => ({
        ...prev,
        platinum_adult_two: false,
        gold_adult_two: true,
      }));
    } else if (attrName[0] === "question" && attrName[1] === "MQ06") {
      setAddonStatus((prev) => ({
        ...prev,
        platinum_adult_two: false,
        gold_adult_two: false,
        platinum_adult_one: false,
        gold_adult_one: false,
      }));
    }
  };
  const updatePreiumApi = () => {
    console.log("resNevaADD", "res?.premiumDetails");
    const onSuccess = (res: any) => {
      setLoader(false);
      console.log("resNevaADD", res?.premiumDetails);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            // ADDON_QUESTION_NIVA_BUPA: addonQuestionData,
            QUOTE_LOADER: false,
          })
        );
      }
      if (res.results.error === true) {
        toast.error(res.results.message)
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: 0,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            // ADDON_QUESTION_NIVA_BUPA: addonQuestionData,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      setLoader(false);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };
    setLoader(true)
    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param: any = {
      sum_insured: `${SELECTED_QUOTE_DATA.sumInsured}`,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      mobile: `${CKYC.CKYC_DETAILS.kycMobile}`,
      adult: ADD_FORM.self_status === true ? "2" : "1",
      child: `${ADD_FORM.children}`,
      gender: `${CKYC.VERIFY_KYC_FORM.gender.value}`,
      term: `${POLICY_TENURE}`,
      cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
        0
        ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
        (parseInt(ADD_FORM?.daughter_count?.value) || 0)
        }C`
        : ""
        }`,
      age: calculateAge(`${CKYC.CKYC_DETAILS.kycDOB}`),
      pincode: `${CKYC.CKYC_DETAILS.kycPincode}`,
      city: `${CKYC.CKYC_DETAILS.kycCity}`,
      state: `${CKYC.CKYC_DETAILS.kycState}`,
      product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      addon: {
        hospital_benifit: addonQuestionData.MQ01.main_question.status
          ? "Y"
          : "N", //Hospital Cash
        hcb: addonQuestionData?.MQ01?.main_question?.field_data?.["MQ1-SQ1-F1"]
          ?.value?.value,
        acute_care: addonQuestionData.MQ02.main_question.status ? "Y" : "N", //Acute Care Opted
        addonOption: addonQuestionData.MQ02.main_question.status
          ? `${addonStatus.best_care_value}`
          : "", //₹ 5000 ₹ 10000 ₹ 15000 ₹ 20000
        safeguard_opted: addonQuestionData.MQ03.main_question.status
          ? "Y"
          : "N", //Safeguard Opted
        safeguard_plus_opted: addonQuestionData.MQ04.main_question.status
          ? "Y"
          : "N", //Safeguard+ Opted
        personal_accident: addonQuestionData.MQ05.main_question.status
          ? "Y"
          : "N", // Personal Accident Cover
        adult_personal_accident: addonQuestionData.MQ05.main_question
          .member_data?.adult_one.selection_status
          ? "Y"
          : "N",
        spouse_personal_accident: addonQuestionData.MQ05.main_question
          .member_data?.adult_two.selection_status
          ? "Y"
          : "N",
        // child1_personal_accident: addonQuestionData.MQ05.main_question
        //   .member_data?.child_one.selection_status
        //   ? "Y"
        //   : "N",
        // child2_personal_accident: addonQuestionData.MQ05.main_question
        //   .member_data?.child_two.selection_status
        //   ? "Y"
        //   : "N",
        // child3_personal_accident: addonQuestionData.MQ05.main_question
        //   .member_data?.child_three.selection_status
        //   ? "Y"
        //   : "N",
        // child4_personal_accident: addonQuestionData.MQ05.main_question
        //   .member_data?.child_four.selection_status
        //   ? "Y"
        //   : "N",
        disease_management: addonQuestionData.MQ06.main_question.status
          ? "Y"
          : "N", // Disease Management
        chb: "N",
        adult_disease_management: addonQuestionData.MQ06.main_question
          .member_data?.adult_one.selection_status
          ? "Y"
          : "N",
        spouse_disease_management: addonQuestionData.MQ06.main_question
          .member_data?.adult_two.selection_status
          ? "Y"
          : "N",
        // child1_disease_management: addonQuestionData.MQ06.main_question
        //   .member_data?.child_one.selection_status
        //   ? "Y"
        //   : "N",
        // child2_disease_management: addonQuestionData.MQ06.main_question
        //   .member_data?.child_two.selection_status
        //   ? "Y"
        //   : "N",
        // child3_disease_management: addonQuestionData.MQ06.main_question
        //   .member_data?.child_three.selection_status
        //   ? "Y"
        //   : "N",
        // child4_disease_management: addonQuestionData.MQ06.main_question
        //   .member_data?.child_four.selection_status
        //   ? "Y"
        //   : "N",
      },
      popData: {
        pop_disease_management: addonQuestionData.MQ06.main_question.status
          ? "Y"
          : "N",
        pop_adult_disease_management:
          addonQuestionData.MQ06?.main_question?.member_data?.adult_one
            ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            addonQuestionData.MQ06?.main_question?.member_data?.adult_one
              ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            ? ""
            : addonQuestionData.MQ06?.main_question?.member_data?.adult_one
              ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
              ? "G"
              : "P",
        pop_spouse_disease_management:
          addonQuestionData.MQ06?.main_question?.member_data?.adult_two
            ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            addonQuestionData.MQ06?.main_question?.member_data?.adult_two
              ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            ? ""
            : addonQuestionData.MQ06?.main_question?.member_data?.adult_two
              ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
              ? "G"
              : "P",
        // pop_child_one_disease_management:
        //   addonQuestionData.MQ06?.main_question?.member_data?.child_one
        //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        //     addonQuestionData.MQ06?.main_question?.member_data?.child_one
        //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
        //     ? ""
        //     : addonQuestionData.MQ06?.main_question?.member_data?.child_one
        //       ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
        //       ? "G"
        //       : "P",
        // pop_child_two_disease_management:
        //   addonQuestionData.MQ06?.main_question?.member_data?.child_two
        //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        //     addonQuestionData.MQ06?.main_question?.member_data?.child_two
        //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
        //     ? ""
        //     : addonQuestionData.MQ06?.main_question?.member_data?.child_two
        //       ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
        //       ? "G"
        //       : "P",
        // pop_child_three_disease_management:
        //   addonQuestionData.MQ06?.main_question?.member_data?.child_three
        //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        //     addonQuestionData.MQ06?.main_question?.member_data?.child_three
        //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
        //     ? ""
        //     : addonQuestionData.MQ06?.main_question?.member_data?.child_three
        //       ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
        //       ? "G"
        //       : "P",
        // pop_child_four_disease_management:
        //   addonQuestionData.MQ06?.main_question?.member_data?.child_four
        //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        //     addonQuestionData.MQ06?.main_question?.member_data?.child_four
        //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
        //     ? ""
        //     : addonQuestionData.MQ06?.main_question?.member_data?.child_four
        //       ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === true
        //       ? "G"
        //       : "P",
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIMIUM_NIVA_BUPA(
      onSuccess,
      onError,
      param
    );
  };

  const updatePreiumApiSenior = () => {
    const onSuccess = (res: any) => {
      console.log("resNevaADD", res?.premiumDetails);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));

      if (res.results.error === false) {
        let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
        quoteData = {
          ...quoteData,
          premiumDetails: {
            ...quoteData.premiumDetails,
            finalPremium: res.results.response.premiumDetails.finalPremium,
          },
        };

        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            SELECTED_QUOTE_DATA: quoteData,
            // ADDON_QUESTION_NIVA_BUPA: addonQuestionData,
            QUOTE_LOADER: false,
          })
        );
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
      dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(false));
    };

    dispatch(HealthSlice.actions.UPDATE_QUOTE_LOADER_STATUS(true));
    let param = {
      product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      adult: ADD_FORM.spouse_status ? "2" : "1",
      child: ADD_FORM.children,
      gender: `${CKYC.VERIFY_KYC_FORM.gender.value}`,
      term: `${POLICY_TENURE}`,
      addon: {
        SafeGaurdOpted: addonQuestionData.MQ03.main_question.status ? "1" : "0",
      },
    };

    HEALTH_PROPOSAL_SERVICES.GET_ADDON_PREMIMIUM_NIVA_BUPA_SENIOR(
      onSuccess,
      onError,
      param
    );
  };

  const validate = () => {
    // if (
    //   (ADD_FORM.spouse_status === false &&
    //     ADD_FORM.children === 0 &&
    //     addonQuestionData.MQ05.main_question.status === true &&
    //     addonQuestionData.MQ05.main_question.member_data?.adult_one
    //       .selection_status === false) ||
    //   (ADD_FORM.spouse_status === false &&
    //     ADD_FORM.children === 0 &&
    //     addonQuestionData.MQ06.main_question.status === true &&
    //     addonQuestionData.MQ06.main_question.member_data?.adult_one
    //       .selection_status === false)
    // )
    if (
      (addonQuestionData.MQ05.main_question.status === true &&
        addonQuestionData.MQ05.main_question.member_data?.adult_one
          .selection_status === false &&
        addonQuestionData.MQ05.main_question.member_data?.adult_two
          .selection_status === false)
      //    &&
      // addonQuestionData.MQ05.main_question.member_data?.child_one
      //   .selection_status === false &&
      // addonQuestionData.MQ05.main_question.member_data?.child_two
      //   .selection_status === false &&
      // addonQuestionData.MQ05.main_question.member_data?.child_three
      //   .selection_status === false &&
      // addonQuestionData.MQ05.main_question.member_data?.child_four
      //   .selection_status === false) 
      ||
      (addonQuestionData.MQ06.main_question.status === true &&
        addonQuestionData.MQ06.main_question.member_data?.adult_one
          .selection_status === false &&
        addonQuestionData.MQ06.main_question.member_data?.adult_two
          .selection_status === false
        // addonQuestionData.MQ06.main_question.member_data?.child_one
        //   .selection_status === false &&
        // addonQuestionData.MQ06.main_question.member_data?.child_two
        //   .selection_status === false &&
        // addonQuestionData.MQ06.main_question.member_data?.child_three
        //   .selection_status === false &&
        // addonQuestionData.MQ06.main_question.member_data?.child_four
        //   .selection_status === false
      )
    ) {
      toast.error("Select Member");
    } else if (
      (addonQuestionData.MQ06.main_question.member_data?.adult_one
        .selection_status === true &&
        addonQuestionData.MQ06?.main_question?.member_data?.adult_one
          ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        addonQuestionData.MQ06?.main_question?.member_data?.adult_one
          ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false) ||
      (addonQuestionData.MQ06.main_question.member_data?.adult_two
        .selection_status === true &&
        addonQuestionData.MQ06?.main_question?.member_data?.adult_two
          ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
        addonQuestionData.MQ06?.main_question?.member_data?.adult_two
          ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false)
      // (addonQuestionData.MQ06.main_question.member_data?.child_one
      //   .selection_status === true &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_one
      //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_one
      //     ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false) ||
      // (addonQuestionData.MQ06.main_question.member_data?.child_two
      //   .selection_status === true &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_two
      //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_two
      //     ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false) ||
      // (addonQuestionData.MQ06.main_question.member_data?.child_three
      //   .selection_status === true &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_three
      //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
      //   addonQuestionData.MQ06?.main_question?.member_data?.child_three
      //     ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false)
    ) {
      toast.error("Please Check Plan");
    } else if (
      addonQuestionData.MQ02.main_question.status === true &&
      addonQuestionData.MQ02?.main_question?.field_data?.["MQ1-SQ1-F1"]?.value
        ?.value === false &&
      addonQuestionData.MQ02?.main_question?.field_data?.["MQ1-SQ1-F2"]?.value
        ?.value === false
    ) {
      toast.error("Please Check BEST CONSULT or Best Care");
    } else if (
      addonQuestionData.MQ02?.main_question?.field_data?.["MQ1-SQ1-F2"]?.value
        ?.value === true &&
      addonStatus.best_care_value === ""
    ) {
      toast.error("Select Best Care value");
    } else {
      if (addonQuestionData.MQ01?.main_question?.status === true) {
        if (addonQuestionData.MQ01?.main_question?.field_data) {
          if (
            addonQuestionData.MQ01?.main_question?.field_data["MQ1-SQ1-F1"]
              ?.value?.value === "0"
          ) {
            toast.error("Select HVB Value");
            return;
          }
        }
      }
      const onSuccess = (res: any) => {
        if (!res.results.error) {
          setLoader(false);
        }
      };
      const onError = (err: any) => {
        console.log("err", err);
      };
      setLoader(true);
      let param: any = {
        sum_insured: `${SELECTED_QUOTE_DATA.sumInsured}`,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        mobile: `${CKYC.CKYC_DETAILS.kycMobile}`,
        adult: ADD_FORM.self_status === true ? "2" : "1",
        child: `${ADD_FORM.children}`,
        gender: `${CKYC.VERIFY_KYC_FORM.gender.value}`,
        term: `${POLICY_TENURE}`,
        section: "add_ons_details",
        cover: `${ADD_FORM?.spouse_status ? "2A" : "1A"}${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0) >
          0
          ? `${(parseInt(ADD_FORM?.son_count?.value) || 0) +
          (parseInt(ADD_FORM?.daughter_count?.value) || 0)
          }C`
          : ""
          }`,
        age: calculateAge(`${CKYC.CKYC_DETAILS.kycDOB}`),
        pincode: `${CKYC.CKYC_DETAILS.kycPincode}`,
        city: `${CKYC.CKYC_DETAILS.kycCity}`,
        state: `${CKYC.CKYC_DETAILS.kycState}`,
        product_code: SELECTED_QUOTE_DATA?.productDetails?.product_code,
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        ...(SELECTED_QUOTE_DATA.productDetails.id === 2890 ||
          SELECTED_QUOTE_DATA.productDetails.id === 2891 ||
          SELECTED_QUOTE_DATA.productDetails.id === 2892 ||
          SELECTED_QUOTE_DATA.productDetails.id === 2893
          ? {
            safeguard_opted: addonQuestionData.MQ03.main_question.status
              ? "1"
              : "0",
          }
          : {
            hospital_benefit: addonQuestionData.MQ01.main_question.status
              ? "Y"
              : "N", // Hospital Cash
            acute_care: addonQuestionData.MQ02.main_question.status
              ? "Y"
              : "N", // Acute Care Opted
            addonOption: `${addonStatus.best_care_value}`, // ₹ 5000 ₹ 10000 ₹ 15000 ₹ 20000
            safeguard_opted: addonQuestionData.MQ03.main_question.status
              ? "Y"
              : "N", // Safeguard Opted
            safeguard_plus_opted: addonQuestionData.MQ04.main_question.status
              ? "Y"
              : "N", // Safeguard+ Opted
            personal_accident: addonQuestionData.MQ05.main_question.status
              ? "Y"
              : "N", // Personal Accident Cover
            adult_personal_accident: addonQuestionData.MQ05.main_question
              .member_data?.adult_one.selection_status
              ? "Y"
              : "N",
            spouse_personal_accident: addonQuestionData.MQ05.main_question
              .member_data?.adult_two.selection_status
              ? "Y"
              : "N",
            // child1_personal_accident: addonQuestionData.MQ05.main_question
            //   .member_data?.child_one.selection_status
            //   ? "Y"
            //   : "N",
            // child2_personal_accident: addonQuestionData.MQ05.main_question
            //   .member_data?.child_two.selection_status
            //   ? "Y"
            //   : "N",
            // child3_personal_accident: addonQuestionData.MQ05.main_question
            //   .member_data?.child_three.selection_status
            //   ? "Y"
            //   : "N",
            // child4_personal_accident: addonQuestionData.MQ05.main_question
            //   .member_data?.child_four.selection_status
            //   ? "Y"
            //   : "N",
            disease_management: addonQuestionData.MQ06.main_question.status
              ? "Y"
              : "N", // Disease Management
            chb: "N",
            hcb: addonQuestionData?.MQ01?.main_question?.field_data?.[
              "MQ1-SQ1-F1"
            ]?.value?.value,
            adult_disease_management: addonQuestionData.MQ06.main_question
              .member_data?.adult_one.selection_status
              ? "Y"
              : "N",
            spouse_disease_management: addonQuestionData.MQ06.main_question
              .member_data?.adult_two.selection_status
              ? "Y"
              : "N",
            // child1_disease_management: addonQuestionData.MQ06.main_question
            //   .member_data?.child_one.selection_status
            //   ? "Y"
            //   : "N",
            // child2_disease_management: addonQuestionData.MQ06.main_question
            //   .member_data?.child_two.selection_status
            //   ? "Y"
            //   : "N",
            // child3_disease_management: addonQuestionData.MQ06.main_question
            //   .member_data?.child_three.selection_status
            //   ? "Y"
            //   : "N",
            // child4_disease_management: addonQuestionData.MQ06.main_question
            //   .member_data?.child_four.selection_status
            //   ? "Y"
            //   : "N",
            pop_disease_management: addonQuestionData.MQ06.main_question
              .status
              ? "Y"
              : "N",
            pop_adult_disease_management:
              addonQuestionData.MQ06?.main_question?.member_data?.adult_one
                ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
                addonQuestionData.MQ06?.main_question?.member_data?.adult_one
                  ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
                ? ""
                : addonQuestionData.MQ06?.main_question?.member_data
                  ?.adult_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value ===
                  true
                  ? "G"
                  : "P",
            pop_spouse_disease_management:
              addonQuestionData.MQ06?.main_question?.member_data?.adult_two
                ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
                addonQuestionData.MQ06?.main_question?.member_data?.adult_two
                  ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
                ? ""
                : addonQuestionData.MQ06?.main_question?.member_data
                  ?.adult_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value ===
                  true
                  ? "G"
                  : "P",
            // pop_child_one_disease_management:
            //   addonQuestionData.MQ06?.main_question?.member_data?.child_one
            //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            //     addonQuestionData.MQ06?.main_question?.member_data?.child_one
            //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            //     ? ""
            //     : addonQuestionData.MQ06?.main_question?.member_data
            //       ?.child_one?.field_data?.["MQ1-SQ1-F1"]?.value?.value ===
            //       true
            //       ? "G"
            //       : "P",
            // pop_child_two_disease_management:
            //   addonQuestionData.MQ06?.main_question?.member_data?.child_two
            //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            //     addonQuestionData.MQ06?.main_question?.member_data?.child_two
            //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            //     ? ""
            //     : addonQuestionData.MQ06?.main_question?.member_data
            //       ?.child_two?.field_data?.["MQ1-SQ1-F1"]?.value?.value ===
            //       true
            //       ? "G"
            //       : "P",
            // pop_child_three_disease_management:
            //   addonQuestionData.MQ06?.main_question?.member_data?.child_three
            //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            //     addonQuestionData.MQ06?.main_question?.member_data?.child_three
            //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            //     ? ""
            //     : addonQuestionData.MQ06?.main_question?.member_data
            //       ?.child_three?.field_data?.["MQ1-SQ1-F1"]?.value
            //       ?.value === true
            //       ? "G"
            //       : "P",
            // pop_child_four_disease_management:
            //   addonQuestionData.MQ06?.main_question?.member_data?.child_four
            //     ?.field_data?.["MQ1-SQ1-F1"]?.value?.value === false &&
            //     addonQuestionData.MQ06?.main_question?.member_data?.child_four
            //       ?.field_data?.["MQ1-SQ1-F2"]?.value?.value === false
            //     ? ""
            //     : addonQuestionData.MQ06?.main_question?.member_data
            //       ?.child_four?.field_data?.["MQ1-SQ1-F1"]?.value?.value ===
            //       true
            //       ? "G"
            //       : "P",
          }),
      };
      dispatch(
        HealthSlice.actions.ADDON_DETAILS_DATA_NIVA_BUPA(addonQuestionData)
      );
      if (PAGE_STATUS) {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
        );
      } else {
        navigate(
          `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
          // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${CKYC_ROUTES.CKYC}?type=HP&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA.productDetails.id}`
        );
      }

      HEALTH_PROPOSAL_SERVICES.getCapturedRecordData(onSuccess, onError, param);
    }
  };

  return (
    <>
      {loader && <Loader />}
      {isMobile ? (
        <MAddonDetailsNIVABUPA
          addonQuestionData={addonQuestionData}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          addonStatus={addonStatus}
        />
      ) : (
        <AddonDetailsNIVABUPA
          addonQuestionData={addonQuestionData}
          updateMasterState={updateMasterState}
          validate={validate}
          PAGE_STATUS={PAGE_STATUS}
          isLoading={loader}
          addonStatus={addonStatus}
        />
      )}
    </>
  );
};

export default AddonDetailsCOntainerNIVABUPA;
