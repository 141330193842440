import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CIGNACKYC from "../../../Page/Desktop/CKYC/Cigna/CIGNACKYC";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  isEmpty,
  validateFullName,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TVerifyCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import MCIGNACKYC from "../../../Page/Mobile/CKYC/Cigna/MCIGNACKYC";
const CignaCKYCContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { VERIFY_KYC_FORM } = useAppSelector((state) => state.CKYC);
  const { ADD_FORM_RESPONSE, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Health
  );
  const { CKYC, Health } = useAppSelector((state) => state);
  // useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [formDetails, setFormDetails] =
    useState<TVerifyCKYCForm>(VERIFY_KYC_FORM);
  const isMobile = useIsMobile();

  useEffect(() => {
    GET_DROPDOWN_MASTER("POV");
  }, []);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;

      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );

        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const Health_params = {
      company_code: SELECTED_QUOTE_DATA?.CompanyDetails?.company_code,
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, Health_params);
  };

  const updateMasterState = (attrName: string, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "dob") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "doc_id") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };
  const validateForm = () => {
    let data: TVerifyCKYCForm = { ...formDetails };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      doc_type: {
        ...data.doc_type,
        warning: isEmpty(data.doc_type.value),
      },
      doc_id: {
        ...data.doc_id,
        warning: isEmpty(data.doc_id.value),
      },
    };
    setFormDetails(data);
    let hasError =
      data.full_name.warning ||
      data.doc_type.warning ||
      data.doc_id.warning ||
      data.dob.warning;

    if (!hasError) {
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: any) => {
    const onSuccess = (res: any) => {
      setIsLoading(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
          const kycManualURL = response.kycManualURL;
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              //   MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        } else {
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        setShowManualPopup(true);
      }
    };

    const onError = (err: any) => {};

    let dto: any = {
      cusType: data.customer_type.value,
      quote_no: ADD_FORM_RESPONSE.quote_no,
      dobForKyc: data.dob.value,
      docNameForKyc: data.doc_type.value,
      docIDNoForKyc: data.doc_id.value,
      section: "premium_details",
    };

    setIsLoading(true);
    CKYCServcies.VERIFY_CIGNA_CKYC(onSuccess, onError, dto);
  };
  const CONTINUE_TO_PROPOSAL = () => {
    dispatch(
      HealthSlice.actions.BULK_UPLOAD({
        ...Health,
        INSURED_MEMBER_DETAILS: {
          ...Health.INSURED_MEMBER_DETAILS,
          PROPOSER_DETAILS: {
            ...Health.INSURED_MEMBER_DETAILS.PROPOSER_DETAILS,
            name: {
              value: CKYC.CKYC_DETAILS.kycCustomerName,
              warning: false,
            },
            dob: {
              value: CKYC.CKYC_DETAILS.kycDOB,
              warning: false,
            },
            mobile: { value: CKYC.CKYC_DETAILS.kycMobile, warning: false },
            emergencyPhone: { value: "", warning: false },
            email: { value: CKYC.CKYC_DETAILS.kycEmail, warning: false },
            aadhar: { value: "", warning: false },
            gender: {
              value: VERIFY_KYC_FORM?.gender?.value
                ? VERIFY_KYC_FORM.gender.value
                : "",
              warning: false,
            },
            maritalStatus: { value: "", warning: false },
            weight: { value: "", warning: false },
            heightFeet: { value: "", warning: false },
            heightInches: { value: "", warning: false },
            occupation: { value: "", warning: false },
            relationship: { value: "", warning: false },
            proposer_name: { value: "", warning: false },
          },
        },
        ADDRESS_DETAILS: {
          ...Health.ADDRESS_DETAILS,
          pincode: { value: CKYC.CKYC_DETAILS.kycPincode, warning: false },
          city: CKYC.CKYC_DETAILS.kycCity,
          state: CKYC.CKYC_DETAILS.kycState,
          addressLineOne: {
            value: CKYC.CKYC_DETAILS.kycAddress1,
            warning: false,
          },
          addressLineTwo: {
            value: CKYC.CKYC_DETAILS.kycAddress2,
            warning: false,
          },
          isMailingAddressSame: true,
          mailingPinCode: { value: "", warning: false },
          mailingCity: "",
          mailingState: "",
          mailingAddressLineOne: { value: "", warning: false },
          mailingAddressLineTwo: { value: "", warning: false },
        },
      })
    );
    navigate(
      // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADULT_ONE_DETAILS}`
      `${`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.ADDON_DETAILS}`}`
    );
  };

  return (
    <>
      {isMobile ? (
        <MCIGNACKYC
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
        />
      ) : (
        <CIGNACKYC
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
        />
      )}
    </>
  );
};

export default CignaCKYCContainer;
