import { useEffect, useMemo, useRef, useState } from "react";
import ProposalBasicDetails from "../../../Page/Desktop/Term/ShortPay/Tata/ProposalBasicDetails";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TTermProposerData } from "../../../types/TTermSlice";
import {
  FIND_LABEL_OF_VALUE,
  FORMAT_YYYY_MM_DD,
  isEmpty,
  validateAadharNumber,
  validateEmail,
  validateFullName,
  validateMobileNumber,
  validatePAN,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import { toast } from "react-toastify";
import { TermSlice } from "../../../Store/Slice_Reducer/Term/TermSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { TERM_SERVICES } from "../../../Services/Term/TermServices";
import MProposalBasicDetails from "../../../Page/Mobile/Term/Tata/MProposalBasicDetails";
import { TDropdown } from "../../../types/Common/TDropdown";
import { GetAPI } from "../../../Axios/AxiosInstance";
import { URL_CONSTANTS } from "../../../URLCollection/URL";

const ProposalBasicDetailConatiner = () => {
  const { Term } = useAppSelector((state) => state);
  const isMobile = useIsMobile();
  const { PROPOSER_DATA, SELECTED_QUOTE_DATA } = useAppSelector(
    (state) => state.Term
  );
  const dropdownData = useAppSelector((state) => state.Term.DROPDOWN_DATA);
  const [loader, setLoader] = useState<boolean>(false);
  const [proposerDetails, setProposerDetails] =
    useState<TTermProposerData>(PROPOSER_DATA);
  const dispatch = useAppDispatch();
  const { ADD_FORM_RESPONSE } = useAppSelector((state) => state.Term);
  const [address, setaddress] = useState<any>();
  const [basePlan, setBasePlan] = useState<string>();
  const [paymentMode, setPaymentMode] = useState<string>();
  const ADD_FORM = useAppSelector((state) => state.Term.ADD_FORM);
  const formRef = useRef<HTMLFormElement | null>(null);
  const [saleriedSelected, setSaleriedSelected] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState(false);
  const handleOpen = () => setOpenModal(true);

  function formatDate(inputDate: string) {
    const [day, month, year] = inputDate.split("-");
    return `${year}-${month}-${day}`;
  }

  const LifeMilestoneData = [
    {
      value: "7",
      label: "Marriage",
    },
    {
      value: "8",
      label: "Birth/Adoption of first child",
    },
    {
      value: "9",
      label: "Taking Home Loan",
    },
    {
      value: "10",
      label: "Starting first Job",
    },
  ];

  const GET_ADDRESS = (onSuccess: Function) => {
    GetAPI.call(
      URL_CONSTANTS.GET_ADDRESS + `${proposerDetails.pincode.value}`,
      (res: any) => setaddress(res.data.results.response)
      // onError
    );
  };

  useEffect(() => {
    GET_ADDRESS(setaddress);
  }, []);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.product_code === "LTTA21") {
      if (
        SELECTED_QUOTE_DATA.premiumDetails.term ==
        SELECTED_QUOTE_DATA.premiumDetails.pay_term
      ) {
        setBasePlan("PRSRV1N1");
      } else if (SELECTED_QUOTE_DATA.premiumDetails.pay_term === "1") {
        setBasePlan("PRSSV1N1");
      } else {
        setBasePlan("PRSLV1N1");
      }
    } else if (SELECTED_QUOTE_DATA.productDetails.product_code === "LTTA22") {
      if (
        SELECTED_QUOTE_DATA.premiumDetails.term ==
        SELECTED_QUOTE_DATA.premiumDetails.pay_term
      ) {
        setBasePlan("PRSRV1N2");
      } else if (SELECTED_QUOTE_DATA.premiumDetails.pay_term === "1") {
        setBasePlan("PRSSV1N2");
      } else {
        setBasePlan("PRSLV1N2");
      }
    }
  }, [SELECTED_QUOTE_DATA]);

  useEffect(() => {
    if (SELECTED_QUOTE_DATA.productDetails.frequency === "1") {
      setPaymentMode("A");
    } else if (SELECTED_QUOTE_DATA.productDetails.frequency === "12") {
      setPaymentMode("M");
    } else if (SELECTED_QUOTE_DATA.productDetails.frequency === "4") {
      setPaymentMode("Q");
    } else if (SELECTED_QUOTE_DATA.productDetails.frequency === "2") {
      setPaymentMode("S");
    } else {
      setPaymentMode("O");
    }
  }, [SELECTED_QUOTE_DATA]);
  const env = process.env.REACT_APP_ENV; // Fetch the environment variable

  // Define environment-specific variables
  let agentCode: string = "",
    subOfficeCode: string = "",
    employeeCode: string = "",
    employeeName: string = "",
    referredBy: string = "",
    productPitched: string = "",
    businessTypeId: string = "",
    redirectionUrl: string = "";

  if (env === "DEV") {
    agentCode = "10710846";
    subOfficeCode = "CBI1382";
    employeeCode = "10710846";
    employeeName = "Agent Name";
    referredBy = "1234";
    productPitched = "373";
    businessTypeId = "JioSolution";
    redirectionUrl =
      "https://uatsellonline.tataaia.com/wps/PA_TATAAIA_SO/GenericRedirection";
  } else if (env === "STG" || env === "PROD") {
    agentCode = "5667665";
    subOfficeCode = "RRI0005";
    employeeCode = "5667665";
    employeeName = "Agent Name";
    referredBy = "1234";
    productPitched = "373";
    businessTypeId = "JioSolution";
    redirectionUrl =
      "https://sellonline.tataaia.com/wps/PA_TATAAIA_SO/GenericRedirection";
  }

  const requestData = useMemo(
    () => ({
      REQUEST: `<?xml version="1.0" encoding="UTF-8"?>
      <faml>
           <PAN_NO>${proposerDetails.pan_no.value}</PAN_NO>
           <CURNT_CITY>${address?.city_name}</CURNT_CITY>
           <CURNT_STATE>${address?.state_name}</CURNT_STATE>
           <COUNTRY>IN</COUNTRY>
           <OCCUPATION_CD>${proposerDetails.occupation.value}</OCCUPATION_CD>
           <RESIDENT_STATUS>RI</RESIDENT_STATUS>
           <EDUCATION_CD>${proposerDetails.qualification.value}</EDUCATION_CD>
           <BIRTH_DATE>${proposerDetails.dob.value}</BIRTH_DATE>
           <CURNT_ZIP_CODE>${proposerDetails.pincode.value}</CURNT_ZIP_CODE>
           <EMAIL_ID>${proposerDetails.email.value}</EMAIL_ID>
           <GENDER_CD>${
             proposerDetails.gender.value === "M" ? "1" : "2"
           }</GENDER_CD>
           <MOBILE_NO>${proposerDetails.mobile.value}</MOBILE_NO>
           <LEAD_NAME>${proposerDetails.full_name.value}</LEAD_NAME>
           <BUY_FOR>Self</BUY_FOR>
           <GROSS_INCOME>${
             SELECTED_QUOTE_DATA?.premiumDetails?.income
           }</GROSS_INCOME>
           <TOBACCO_FLAG>${ADD_FORM.smoke}</TOBACCO_FLAG>
           <INSURED_BIRTH_DATE>${proposerDetails.dob.value}</INSURED_BIRTH_DATE>
           <INSURED_GENDER_CD>${
             proposerDetails.gender.value === "M" ? "1" : "2"
           }</INSURED_GENDER_CD>
           <INSURED_NAME>${proposerDetails.full_name.value}</INSURED_NAME>
           <ISWHOLELIFE>N</ISWHOLELIFE>
           <CHECKSUM></CHECKSUM>
           <SA>${proposerDetails.sum_assured.value}</SA>
           <PLAN_OPTION>${basePlan}</PLAN_OPTION>
           <POLICY_MODE>${paymentMode}</POLICY_MODE>
           <POLICY_TERM>${SELECTED_QUOTE_DATA.premiumDetails.term}</POLICY_TERM>
           <PPT>${SELECTED_QUOTE_DATA.premiumDetails.pay_term}</PPT>
           <SALARIED_DISCOUNT_FLAG>${
             proposerDetails.occupation.value === "1" ? "Y" : "N"
           }</SALARIED_DISCOUNT_FLAG>
           <INSURED_CORPORATE_EMAIL>${
             proposerDetails.corporate_email_id.value
           }</INSURED_CORPORATE_EMAIL>
           <SALARIED_CONSENT_FLAG>${
             proposerDetails.full_name.value === true ? "Y" : "N"
           }</SALARIED_CONSENT_FLAG>
           <LIFE_STAGE_DISCOUNT>${
             proposerDetails.lifeMileStoneDiscount.value === true ? "Y" : "N"
           }</LIFE_STAGE_DISCOUNT>
           <LIFE_STAGE_MILESTONE>${FIND_LABEL_OF_VALUE(
             LifeMilestoneData,
             proposerDetails?.lifeMileStoneType?.value
           )}</LIFE_STAGE_MILESTONE>
           <LIFE_STAGE_MILESTONE_DATE>${FORMAT_YYYY_MM_DD(
             proposerDetails.lifeMileStonedate.value
           )}</LIFE_STAGE_MILESTONE_DATE>
           <DIGITAL_DISCOUNT_FLAG>Y</DIGITAL_DISCOUNT_FLAG>
           <RIDER_SA></RIDER_SA>
           <REFERRED_BY>${referredBy}</REFERRED_BY>
           <TRANSACTION_DATE>08042024</TRANSACTION_DATE>
           <PRODUCT_PITCHED>${productPitched}</PRODUCT_PITCHED>
           <BUSINESS_TYPE_ID>${businessTypeId}</BUSINESS_TYPE_ID>
           <AGENT_CD>${agentCode}</AGENT_CD>
           <SUB_OFFICE_CODE>${subOfficeCode}</SUB_OFFICE_CODE>
           <BQP_CODE>12345</BQP_CODE>
           <EMPLOYEE_CODE>${employeeCode}</EMPLOYEE_CODE>
           <EMPLOYEE_NAME>${employeeName}</EMPLOYEE_NAME>
      </faml>`,
      fldOrgTxn: "TAT",
    }),
    [
      proposerDetails,
      address,
      basePlan,
      paymentMode,
      SELECTED_QUOTE_DATA,
      ADD_FORM,
    ]
  );

  type ValueTypes = keyof TTermProposerData;
  const updateMasterState = (attrName: ValueTypes, value: any) => {
    console.log(attrName, "attrName", value);
    if (attrName === "full_name") {
      setProposerDetails((prev) => ({
        ...prev,
        full_name: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "email") {
      setProposerDetails((prev) => ({
        ...prev,
        email: { value: value, warning: !validateEmail(value) },
      }));
    } else if (attrName === "aadhar_number") {
      setProposerDetails((prev) => ({
        ...prev,
        aadhar_number: { value: value, warning: !validateAadharNumber(value) },
      }));
    } else if (attrName === "pan_no") {
      setProposerDetails((prev) => ({
        ...prev,
        pan_no: { value: value, warning: !validatePAN(value) },
      }));
    } else if (attrName === "emergency_number") {
      setProposerDetails((prev) => ({
        ...prev,
        emergency_number: {
          value: value,
          warning: !validateMobileNumber(value),
        },
      }));
    } else if (attrName === "pincode") {
      setProposerDetails((prev) => ({
        ...prev,
        pincode: { value: value.toString(), warning: !validatePincode(value) },
      }));
    } else if (attrName === "qualification") {
      setProposerDetails((prev) => ({
        ...prev,
        qualification: { value: value, warning: isEmpty(value) },
      }));
    } else if (attrName === "occupation") {
      console.log(proposerDetails, "proposerDetails56", value);
      setProposerDetails((prev) => ({
        ...prev,
        occupation: { value: value, warning: isEmpty(value) },
      }));
      if (value != 1) {
        setProposerDetails((prev) => ({
          ...prev,
          lifeMileStoneDiscount: {
            value: false,
            warning: false,
          },
        }));
        setProposerDetails((prev) => ({
          ...prev,
          isSalariedDiscount: {
            value: false,
            warning: isEmpty(false),
          },
        }));
        setProposerDetails((prev) => ({
          ...prev,
          lifeMileStoneType: {
            value: "",
            warning: isEmpty(value),
          },
          lifeMileStonedate: {
            value: "",
            warning: isEmpty(value),
          },
        }));
      }

      if (value === 1) {
        setProposerDetails((prev) => ({
          ...prev,
          isSalariedDiscount: {
            value: true,
            warning: isEmpty(false),
          },
        }));
      }
    } else if (attrName === "corporate_email_id") {
      const emailRegex =
        /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
      const excludedDomains = [
        "gmail.com",
        "yahoo.com",
        "rediff.com",
        "hotmail.com",
      ];
      if (!emailRegex.test(value)) {
        setProposerDetails((prev) => ({
          ...prev,
          corporate_email_id: {
            ...prev.corporate_email_id,
            warning: true,
          },
        }));
      } else {
        const domain = value.split("@")[1].toLowerCase();
        if (excludedDomains.includes(domain)) {
          setProposerDetails((prev) => ({
            ...prev,
            corporate_email_id: {
              value: value,
              warning: false,
            },
          }));
          handleOpen();
        } else {
          setProposerDetails((prev) => ({
            ...prev,
            corporate_email_id: {
              value: value,
              warning: false,
            },
          }));
        }
      }
    } else if (attrName === "isSalariedDiscount") {
      setProposerDetails((prev) => ({
        ...prev,
        isSalariedDiscount: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "isSalariedConsent") {
      setProposerDetails((prev) => ({
        ...prev,
        isSalariedConsent: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    } else if (attrName === "lifeMileStoneDiscount") {
      setProposerDetails((prev) => ({
        ...prev,
        lifeMileStoneDiscount: {
          value: value,
          warning: isEmpty(value),
        },
      }));
      if (value === false) {
        setProposerDetails((prev) => ({
          ...prev,
          lifeMileStoneType: {
            value: "",
            warning: isEmpty(value),
          },
          lifeMileStonedate: {
            value: "",
            warning: isEmpty(value),
          },
        }));
      }
    } else if (attrName === "lifeMileStoneType") {
      setProposerDetails((prev) => ({
        ...prev,
        lifeMileStoneType: {
          value: value,
          warning: isEmpty(value),
        },
      }));
      setProposerDetails((prev) => ({
        ...prev,
        lifeMileStonedate: {
          value: "",
          warning: isEmpty(value),
        },
      }));
    } else {
      setProposerDetails((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning: isEmpty(value),
        },
      }));
    }
  };

  const UPDATE_PROPOSER_DETAILS = () => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [SELECTED_QUOTE_DATA.premiumDetails?.product_id],
      product_code: SELECTED_QUOTE_DATA.premiumDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      isSalariedDiscount: proposerDetails.occupation.value === "1" ? "Y" : "N",
      lifeMileStoneDiscount:
        proposerDetails.lifeMileStoneDiscount.value === true &&
        proposerDetails.lifeMileStoneType.value > 1
          ? "Y"
          : "N",
      lifeStageEvent: proposerDetails.lifeMileStoneType.value,
      SPECIALDOB: proposerDetails.lifeMileStonedate.value
        ? formatDate(proposerDetails.lifeMileStonedate.value)
        : "",
    };

    const onSuccess = (res: any) => {
      dispatch(
        TermSlice.actions.SET_RIDERS_BENEFITS_DATA(
          res.response[0]?.premiumDetails
        )
      );
      if (formRef.current) {
        formRef.current.submit();
      }
    };
    const onError = () => {
      setLoader(false);
    };

    setLoader(true);
    // TERM_SERVICES.UPDATE_PROPOSER_DETAILS(onSuccess, onError, params);

    TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
  };

  const validateForm = () => {
    let data: TTermProposerData = {
      ...proposerDetails,
    };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data?.dob?.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data?.gender?.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      pan_no: {
        ...data.pan_no,
        warning: isEmpty(data.pan_no.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data?.occupation?.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data?.qualification?.value),
      },
      isSalariedDiscount: {
        ...data.isSalariedDiscount,
        warning: isEmpty(data?.isSalariedDiscount?.value),
      },
      corporate_email_id: {
        ...data.corporate_email_id,
        warning: saleriedSelected
          ? isEmpty(data?.corporate_email_id?.value)
          : false,
      },
    };
    setProposerDetails(data);
    let hasError = false;
    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.gender.warning ||
      data.mobile.warning ||
      data.email.warning ||
      data.occupation.warning ||
      data.qualification.warning ||
      (saleriedSelected && data.corporate_email_id.warning)
    ) {
      hasError = true;
    }
    if (saleriedSelected && data.isSalariedConsent.value === false) {
      hasError = true;
      toast.error(
        "Please agree to 'I confirm that I am a salaried individual and my employer details provided by me are correct'"
      );
    }
    if (saleriedSelected && data.corporate_email_id.warning) {
      hasError = true;
    }
    if (
      data?.lifeMileStoneDiscount?.value &&
      (data?.lifeMileStoneType?.warning || data?.lifeMileStonedate?.warning)
    ) {
      hasError = true;
    }
    if (data.mobile.value === data.emergency_number.value) {
      toast.error("Mobile no. and emergency no. should be different.");
      hasError = hasError || data.mobile.value === data.emergency_number.value;
    }
    if (!hasError) {
      dispatch(
        TermSlice.actions.BULK_UPDATE({ ...Term, PROPOSER_DATA: { ...data } })
      );
      UPDATE_PROPOSER_DETAILS();
    }
  };

  console.log(requestData, "requestData");

  const validate_for_premium_refresh = (params: TTermProposerData) => {
    let data: TTermProposerData = {
      ...params,
    };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data?.dob?.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data?.gender?.value),
      },
      mobile: {
        ...data.mobile,
        warning: !validateMobileNumber(data.mobile.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      occupation: {
        ...data.occupation,
        warning: isEmpty(data?.occupation?.value),
      },
      qualification: {
        ...data.qualification,
        warning: isEmpty(data?.qualification?.value),
      },
      isSalariedDiscount: {
        ...data.isSalariedDiscount,
        warning: isEmpty(data?.isSalariedDiscount?.value),
      },
    };

    let valid = true;

    if (
      data.full_name.warning ||
      data.dob.warning ||
      data.gender.warning ||
      data.mobile.warning ||
      data.email.warning ||
      data.pan_no.warning ||
      data.occupation.warning ||
      data.qualification.warning
    ) {
      valid = false;
    }

    if (
      data?.lifeMileStoneDiscount?.value &&
      (data?.lifeMileStoneType?.warning || data?.lifeMileStonedate?.warning)
    ) {
      valid = false;
    }

    if (data?.lifeMileStoneDiscount?.value && data?.lifeMileStoneType) {
      valid = false;
    }

    if (
      data?.lifeMileStoneDiscount?.value &&
      data?.lifeMileStoneType?.value &&
      data?.lifeMileStonedate?.value
    ) {
      valid = true;
    }

    return valid;
  };

  useEffect(() => {
    let params = {
      quote_no: ADD_FORM_RESPONSE.quote_no,
      product_ids: [SELECTED_QUOTE_DATA.premiumDetails?.product_id],
      product_code: SELECTED_QUOTE_DATA.premiumDetails.product_code,
      company_code: SELECTED_QUOTE_DATA.companyDetails.company_code,
      isSalariedDiscount: proposerDetails.occupation.value === "1" ? "Y" : "N",
      lifeMileStoneDiscount:
        proposerDetails.lifeMileStoneDiscount.value === true &&
        proposerDetails.lifeMileStoneType.value > 1
          ? "Y"
          : "N",
      lifeStageEvent: proposerDetails.lifeMileStoneType.value,
      SPECIALDOB: proposerDetails.lifeMileStonedate.value
        ? formatDate(proposerDetails.lifeMileStonedate.value)
        : "",
    };

    const onSuccess = (res: any) => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
      dispatch(
        TermSlice.actions.SET_RIDERS_BENEFITS_DATA(
          res.response[0]?.premiumDetails
        )
      );
    };
    const onError = () => {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(false));
    };

    console.log(validate_for_premium_refresh(proposerDetails), "prabh56");

    if (validate_for_premium_refresh(proposerDetails)) {
      dispatch(TermSlice.actions.UPDATE_LOADER_STATUS(true));
      TERM_SERVICES.GET_REDIRECTION_URL(onSuccess, onError, params);
    }
  }, [proposerDetails]);

  const PROPOSER_OCCUPATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: "LTTA21",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };

    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));

      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "OCCUPATION_DATA",
          value: data,
        })
      );
    };

    const onError = () => {};

    TERM_SERVICES.PROPOSER_OCCUPATION_DETAILS(onSuccess, onError, params);
  };
  const PROPOSER_QUALIFICATION_DETAILS = () => {
    let params = {
      product_type_code: "TP",
      product_code: "LTTA21",
      buy_online_code: SELECTED_QUOTE_DATA.productDetails.buy_online_code,
    };
    const onSuccess = (res: any) => {
      const results = res.results;
      const response = results.response;

      let data: TDropdown[] = response.map((item: any) => ({
        value: item.code,
        label: item.title,
      }));

      dispatch(
        TermSlice.actions.SET_DROPDOWN_DATA({
          key: "QUALIFICATION_DATA",
          value: data,
        })
      );
    };
    const onError = () => {};
    TERM_SERVICES.PROPOSER_QUALIFICATION_DETAILS(onSuccess, onError, params);
  };
  useEffect(() => {
    PROPOSER_OCCUPATION_DETAILS();
    PROPOSER_QUALIFICATION_DETAILS();
  }, []);

  useEffect(() => {
    if (
      FIND_LABEL_OF_VALUE(
        dropdownData?.OCCUPATION_DATA,
        proposerDetails?.occupation.value
      ) === "Salaried"
    ) {
      setSaleriedSelected(true);
    } else {
      setSaleriedSelected(false);
    }
  }, [proposerDetails?.occupation.value]);

  return (
    <>
      {isMobile ? (
        <MProposalBasicDetails
          validateForm={validateForm}
          updateMasterState={updateMasterState}
          proposerDetails={proposerDetails}
          loader={loader}
          saleriedSelected={saleriedSelected}
          openModal={openModal}
          setOpenModal={setOpenModal}
          LifeMilestoneData={LifeMilestoneData}
        />
      ) : (
        <ProposalBasicDetails
          validateForm={validateForm}
          updateMasterState={updateMasterState}
          proposerDetails={proposerDetails}
          loader={loader}
          saleriedSelected={saleriedSelected}
          openModal={openModal}
          setOpenModal={setOpenModal}
          LifeMilestoneData={LifeMilestoneData}
        />
      )}
      <div style={{ display: "none" }}>
        <form method="POST" action={redirectionUrl} ref={formRef}>
          <textarea
            name="REQUEST"
            value={requestData.REQUEST}
            readOnly
            style={{ display: "none" }}
          />
          <input
            type="hidden"
            name="fldOrgTxn"
            value={requestData.fldOrgTxn}
            readOnly
            style={{ display: "none" }}
          />
        </form>
      </div>
    </>
  );
};

export default ProposalBasicDetailConatiner;
