import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CKYCServcies } from "../../../Services/CKYC/CKYCServices";
import { CKYCSlice } from "../../../Store/Slice_Reducer/CKYC/CKYCSlice";
import { CommonDropdownSlice } from "../../../Store/Slice_Reducer/CommonDropdown/CommonDropdownSlice";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import {
  FIND_LABEL_OF_VALUE,
  isEmpty,
  validateFullName,
  validatePincode,
} from "../../../SupportingFiles/HelpingFunction";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { TManualCKYCForm } from "../../../types/CKYC/TCKYC";
import { TDropdown } from "../../../types/Common/TDropdown";
import MIffcoTokioManualKYC from "../../../Page/Mobile/CKYC/IffcoTokio/MIffcoTokioManualKYC";
import IffcoTokioManualKYC from "../../../Page/Desktop/CKYC/IffcoTokio/IffcoTokioManualKYC";
import { CarSlice } from "../../../Store/Slice_Reducer/Car/CarSlice";
import { CAR_ROUTES } from "../../../Router/Path/CarRoutes";
import {
  pushDataLayer,
  pushMotorDataLayer,
  sendEventToNativeApp,
} from "../../../utils/analytics";
import { CLIENTS } from "../../../URLCollection/Clients";

const IffcoTokioManualKYCContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { CKYC, Car } = useAppSelector((state) => state);
  const [manualKycToggle, setManualKycToggle] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCKYCDetailsPopup, setShowCKYCDetailsPopup] =
    useState<boolean>(false);
  const [showManualPopup, setShowManualPopup] = useState<boolean>(false);
  const [formDetails, setFormDetails] = useState<TManualCKYCForm>({
    customer_type: { value: "Individual", warning: false },
    dob: { value: "", warning: false },
    doc_type_id_proof: { value: "", warning: false },
    doc_no_id_proof: { value: "", warning: false },
    doc_id_proof_image: { value: "", warning: false },
    full_name: { value: "", warning: false },
    gender: { value: "", warning: false },
    doc_address_proof_image: { value: "", warning: false },
    doc_type_address_proof: { value: "", warning: false },
    relatedPersonName: { value: "", warning: false },
    relationshipType: { value: "", warning: false },
    doc_address_id_proof: { value: "", warning: false },
    photoDocument: { value: "", warning: false },
    kycPincode: { value: "", warning: false },
    addressPHouse: { value: "", warning: false },
    addressPCity: { value: "", warning: false },
    addressPState: { value: "", warning: false },
  });

  const [pageStatus, setPageStatus] = useState<string>("");

  useEffect(() => {
    const windowURL = window.location.search;
    const params = new URLSearchParams(windowURL);
    const type = params.get("type");

    if (type) {
      setPageStatus(type);
    }
  }, []);

  const isMobile = useIsMobile();

  const FAMILY_RELATIONSHIP = [
    { label: "Father", value: "1" },
    { label: "Spouse", value: "2" },
    { label: "Mother", value: "3" },
  ];

  // family realtionship
  useEffect(() => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({
            value: data.city_id,
            label: data.city_name,
          });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "FAMILY_RELATIONSHIP",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {
      setIsLoading(false);
    };
    CKYCServcies.GET_FAMILY_RELATIONSHIP(onSuccess, onError);
  }, []);

  //   documnet for verification
  useEffect(() => {
    GET_DROPDOWN_MASTER("POI");
  }, []);

  const GET_DROPDOWN_MASTER = (TYPE: "POI" | "POA" | "POV") => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (!error) {
        const response = results.response;
        const manupulated_data: TDropdown[] = Object.entries(response).map(
          ([key, value]) => ({
            label: `${value}`,
            value: `${key}`,
          })
        );
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: TYPE,
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const Health_params = {
      company_code: "G015",
      list_for: TYPE,
    };
    CKYCServcies.CKYC_DROPDOWN_MASTER(onSuccess, onError, Health_params);
  };

  //   get city by pincode
  useEffect(() => {
    if (formDetails?.kycPincode?.value) {
      GET_CITY_BY_PINCODE();
    }
  }, [formDetails?.kycPincode?.value]);

  const GET_CITY_BY_PINCODE = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      updateMasterState("addressPState", results?.response?.state_name);
      updateMasterState("addressPCity", results.response.city[0].city_name);
      if (!error) {
        const response = results.response?.city;
        const manupulated_data: TDropdown[] = [];
        for (const data of response) {
          manupulated_data.push({ value: data.city_id, label: data.city_name });
        }
        dispatch(
          CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
            key: "CITY",
            value: manupulated_data,
          })
        );
      }
    };
    const onError = (error: any) => {};
    const pincode_params = formDetails?.kycPincode?.value;
    CKYCServcies.GET_CITY_BY_PINCODE(onSuccess, onError, pincode_params);
  };

  useEffect(() => {
    if (!formDetails?.kycPincode?.value) {
      dispatch(
        CommonDropdownSlice.actions.UPDATE_DROPDOWN_DATA({
          key: "CITY",
          value: [],
        })
      );
    }
  }, [formDetails?.kycPincode?.value]);

  const updateMasterState = (attrName: any, value: any) => {
    if (attrName === "full_name") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: !validateFullName(value) },
      }));
    } else if (attrName === "customer_type") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
        dob: { value: "", warning: false },
      }));
    } else if (attrName === "Pincode") {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: {
          value: value,
          warning:
            attrName === "Pincode" ? !validatePincode(value) : isEmpty(value),
        },
      }));
    } else {
      setFormDetails((prev) => ({
        ...prev,
        [attrName]: { value: value, warning: isEmpty(value) },
      }));
    }
  };

  const validateForm = () => {
    let data: TManualCKYCForm = { ...formDetails };
    data = {
      ...data,
      full_name: {
        ...data.full_name,
        warning: !validateFullName(data.full_name.value),
      },
      dob: {
        ...data.dob,
        warning: isEmpty(data.dob.value),
      },
      customer_type: {
        ...data.customer_type,
        warning: isEmpty(data.customer_type.value),
      },
      gender: {
        ...data.gender,
        warning: isEmpty(data.gender.value),
      },
      relatedPersonName: {
        value: data?.relatedPersonName?.value,
        warning: isEmpty(data?.relatedPersonName?.value),
      },
      doc_type_id_proof: {
        ...data.doc_type_id_proof,
        warning: isEmpty(data.doc_type_id_proof.value),
      },
      kycPincode: {
        value: data?.kycPincode?.value,
        warning: !validatePincode(data?.kycPincode?.value),
      },
      doc_no_id_proof: {
        ...data.doc_no_id_proof,
        warning: isEmpty(data.doc_no_id_proof.value),
      },
      doc_id_proof_image: {
        ...data.doc_id_proof_image,
        warning: isEmpty(data.doc_id_proof_image.value),
      },
      doc_address_proof_image: {
        ...data.doc_address_proof_image,
        warning: isEmpty(data.doc_address_proof_image.value),
      },
      photoDocument: {
        value: data?.photoDocument?.value,
        warning: isEmpty(data.photoDocument?.value),
      },
    };
    setFormDetails(data);
    let hasError =
      data.full_name.warning || data.dob.warning || data.kycPincode?.warning;

    if (!hasError) {
      if (window.location.hostname === CLIENTS.JIO) {
        pushMotorDataLayer(
          "jioinsure_flow_details",
          "ckyc",
          "proceed",
          pageStatus === "TW" ? "two wheeler" : pageStatus?.toLocaleLowerCase(),

          Car.REDIRECTED ? "JFS app" : "Insure website"
        );
        const eventProps = {
          action: "ckyc",
          click: "continue",
          type:
            pageStatus === "TW"
              ? "two wheeler"
              : pageStatus?.toLocaleLowerCase(),
          EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
        };
        sendEventToNativeApp("jioinsure_flow_details", eventProps);
      }
      VERIFY_CKYC_DETAILS(data);
    }
  };

  const VERIFY_CKYC_DETAILS = (data: any) => {
    const onSuccess = (res: any) => {
      setIsLoading(false);
      const results = res.results;
      const error = results.error;
      if (!error) {
        const message = results.message;
        const response = results.response;
        if (message === "No record found") {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details failure",
              `${message}`,
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),

              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details failure",
              message: `${message}`,
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          const kycManualURL = response.kycManualURL;
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              MANUAL_KYC_THIRD_PARTY_URL: kycManualURL,
            })
          );
          setShowManualPopup(true);
        } else {
          if (window.location.hostname === CLIENTS.JIO) {
            pushMotorDataLayer(
              "jioinsure_flow_details",
              "ckyc details success",
              "na",
              pageStatus === "TW"
                ? "two wheeler"
                : pageStatus?.toLocaleLowerCase(),

              Car.REDIRECTED ? "JFS app" : "Insure website"
            );
            const eventProps = {
              action: "ckyc",
              status: "ckyc details success",
              message: "na",
              type:
                pageStatus === "TW"
                  ? "two wheeler"
                  : pageStatus?.toLocaleLowerCase(),
              EntrySource: Car.REDIRECTED ? "JFS app" : "Insure website",
            };
            sendEventToNativeApp("jioinsure_flow_details", eventProps);
          }
          dispatch(
            CKYCSlice.actions.BULK_UPDATE({
              ...CKYC,
              VERIFY_KYC_FORM: { ...data },
              CKYC_DETAILS: response,
            })
          );
          setShowCKYCDetailsPopup(true);
        }
      } else {
        setShowManualPopup(true);
      }
    };
    const onError = (err: any) => {};
    let dto = {
      quote_no: Car.ADD_FORM_RESPONSE.quote_no,
      insuranceType: "Car",
      companyCode: "MOTOR-TOKIO",
      ckycdetails: "doc",
      cusType: data.customer_type.value,
      genderForKyc: data.gender.value,
      kycCusName: data.full_name.value,
      dobForKyc: data.dob.value,
      relationshipType: FIND_LABEL_OF_VALUE(
        FAMILY_RELATIONSHIP,
        data.relationshipType.value
      ),
      docNameForKyc: data.doc_type_id_proof.value,
      docIDNoForKyc: data.doc_no_id_proof.value,
      otherDocNameForKyc: data.doc_type_address_proof.value,
      otherDocIDNoForKyc: data.doc_address_id_proof.value,
      kycPincode: data.kycPincode.value,
      addressPHouse: data.addressPHouse.value,
      addressPCity: data.addressPCity.value,
      addressPState: data.addressPState.value,
      document: data.doc_id_proof_image.value,
      otherDocument: data.doc_address_proof_image.value,
      photoDocument: data.photoDocument.value,
    };
    setIsLoading(true);
    CKYCServcies.IFFCO_TOKIO_MANUAL_KYC(onSuccess, onError, dto);
  };

  const CONTINUE_TO_PROPOSAL = () => {
    dispatch(
      CarSlice.actions.BULK_UPDATE({
        ...Car,
        OWNER_DETAILS: {
          ...Car.OWNER_DETAILS,
          name: {
            value: CKYC.CKYC_DETAILS.kycCustomerName,
            warning: false,
          },
          gender: {
            value: CKYC.VERIFY_KYC_FORM.gender.value,
            warning: false,
          },
          dob: {
            value: CKYC.CKYC_DETAILS.kycDOB,
            warning: false,
          },
          mobile: {
            value: Car.ADD_FORM.mobile.value,
            warning: false,
          },
        },
        ADDRESS_DETAILS: {
          ...Car.ADDRESS_DETAILS,
          addressLineOne: {
            value: CKYC.CKYC_DETAILS.kycAddress1,
            warning: false,
          },
          addressLineTwo: {
            value: `${CKYC.CKYC_DETAILS.kycAddress2}, ${CKYC.CKYC_DETAILS.kycAddress3}`,
            warning: false,
          },
          pincode: {
            value: CKYC.CKYC_DETAILS.kycPincode,
            warning: false,
          },
          state: CKYC.CKYC_DETAILS.kycState,
          city: CKYC.CKYC_DETAILS.kycCity,
        },
      })
    );
    navigate(
      `${CAR_ROUTES.PROPOSAL_PAGE}/${CAR_ROUTES.OWNER_DETAILS}?type=CAR&companyCode=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails.company_code}&quote_no=${Car?.ADD_FORM_RESPONSE.quote_no}&product_id=${Car?.SELECTED_QUOTE_DATA.quotationDetail?.productDetails.id}`
    );
  };

  return (
    <>
      {isMobile ? //   isLoading={isLoading} // <MIffcoTokioManualKYC
      //   showCKYCDetailsPopup={showCKYCDetailsPopup}
      //   setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
      //   showManualPopup={showManualPopup}
      //   setShowManualPopup={setShowManualPopup}
      //   updateMasterState={updateMasterState}
      //   formDetails={formDetails}
      //   validateForm={validateForm}
      //   CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
      //   manualKycToggle={manualKycToggle}
      //   checkPan={checkPan}
      // />
      null : (
        <IffcoTokioManualKYC
          isLoading={isLoading}
          showCKYCDetailsPopup={showCKYCDetailsPopup}
          setShowCKYCDetailsPopup={setShowCKYCDetailsPopup}
          showManualPopup={showManualPopup}
          setShowManualPopup={setShowManualPopup}
          updateMasterState={updateMasterState}
          formDetails={formDetails}
          validateForm={validateForm}
          CONTINUE_TO_PROPOSAL={CONTINUE_TO_PROPOSAL}
          manualKycToggle={manualKycToggle}
        />
      )}
    </>
  );
};

export default IffcoTokioManualKYCContainer;
