import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../../Store/hooks";
import { TCareMedicalQuestion } from "../../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import { DateFun, isEmpty } from "../../../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../../../Store/Slice_Reducer/Health/HealthSlice";
import { HEALTH_PROPOSAL_SERVICES } from "../../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../../Router/Path/HealthRoutes";
import MedicalDetails from "../../../../Page/Desktop/Health/CARE/MedicalDetails/MedicalDetails";
import MMedicalDetails from "../../../../Page/Mobile/Health/CARE/MMedicalDetails/MMedicalDetails";
import { THealthQuote } from "../../../../types/Health/HealthQuotation/THealthQuote";
import { toast } from "react-toastify";

export default function MedicalDetailsContainer() {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_CARE,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_CARE);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [change_status, setchange_status] = useState(false);

  const setMemberDataAsPerUserSelection = () => {
    let setMemberData: any = { ...MEDICAL_QUESTION_DATA_CARE };
    setMemberData = {
      MQ01: {
        main_question: {
          id: "MQ01",
          icon_class: "tobaco",
          title: "Pre-Existing Diseases",
          description:
            "Does any person(s) to be insured has any Pre-Existing diseases?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },

          sub_question: {
            "MQ1-SQ1": {
              id: "MQ1-SQ1",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Diabetes Mellitus type 1 or Diabetes on insulin or Diabetes associated with blindness or chronic foot ulcer?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                  selection_status: false,
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ2-SQ2": {
              id: "MQ2-SQ2",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Are you suffering from Hypertension/High Blood Pressure?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F2": {
              //     id: "MQ1-SQ1-F2",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ3-SQ3": {
              id: "MQ3-SQ3",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Liver Diseases?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F3": {
              //     id: "MQ1-SQ1-F3",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ4-SQ4": {
              id: "MQ4-SQ4",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from HIV/AIDS/STD?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F4": {
              //     id: "MQ1-SQ1-F4",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ5-SQ5": {
              id: "MQ5-SQ5",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Cancer?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F5": {
              //     id: "MQ1-SQ1-F5",
              //     title: "Existing Since",
              //     field_type: "DATEPICKER",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ6-SQ6": {
              id: "MQ6-SQ6",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Cardiac Disease?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F6": {
              //     id: "MQ1-SQ1-F6",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ7-SQ7": {
              id: "MQ7-SQ7",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Arthritis/Joint pain?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F7": {
              //     id: "MQ1-SQ1-F7",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ8-SQ8": {
              id: "MQ8-SQ8",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Kidney Disease?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ9-SQ9": {
              id: "MQ9-SQ9",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Paralysis/stroke?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ10-SQ10": {
              id: "MQ10-SQ10",
              icon_class: "",
              status: false,
              title: "",
              description: "Are you suffering from Congenital Disorder?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ11-SQ11": {
              id: "MQ11-SQ11",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Any Respiratory disease / Disease of Lungs, Pleura and airway (including but not limited to Asthma / Tuberculosis / Pleural effusion / Bronchitis / Emphysema)?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ12-SQ12": {
              id: "MQ12-SQ12",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Any disorders of the endocrine system (including but not limited to Pituitary / Parathyroid / adrenal gland disorders)?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ13-SQ13": {
              id: "MQ13-SQ13",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Has any of the Proposed to be Insured consulted/taken treatment or recommended to take investigations/medication/surgery other than for childbirth/minor injuries?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ14-SQ14": {
              id: "MQ14-SQ14",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Has any of the Proposed to be Insured been hospitalized or has been under any prolonged treatment for any illness/injury or has undergone surgery other than for childbirth/minor injuries?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ1-SQ1-F2": {
                      id: "MQ1-SQ1-F2",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
            "MQ15-SQ15": {
              id: "MQ15-SQ15",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Do You smoke, consume alcohol, or chew tobacco, ghutka or paan or use any recreational drugs? If ‘Yes’ then please provide the frequency & amount consumed?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ15-SQ15-F1": {
                      id: "MQ15-SQ15-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",
                      value: { value: "", warning: false },
                    },
                    "MQ15-SQ15-F2": {
                      id: "MQ15-SQ15-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
            },
            "MQ16-SQ16": {
              id: "MQ16-SQ16",
              icon_class: "",
              status: false,
              title: "",
              description:
                "Any other diseases or ailments not mentioned above?",
              member_data: {
                adult_one: {
                  keyName: "adult_one",
                  name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                adult_two: {
                  keyName: "adult_two",
                  name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
                  image_class: "adult",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_one: {
                  keyName: "child_one",
                  name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_two: {
                  keyName: "child_two",
                  name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_three: {
                  keyName: "child_three",
                  name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
                child_four: {
                  keyName: "child_four",
                  name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
                  image_class: "child",
                  selection_status: false,
                  field_data: {
                    "MQ16-SQ16-F1": {
                      id: "MQ16-SQ16-F1",
                      title: "Existing Since",
                      field_type: "DATEPICKER",

                      value: { value: "", warning: false },
                    },
                    "MQ16-SQ16-F2": {
                      id: "MQ16-SQ16-F2",
                      title: "Description",
                      field_type: "TEXTFIELD",
                      value: { value: "", warning: false },
                    },
                  },
                },
              },
              // field_data: {
              //   "MQ1-SQ1-F8": {
              //     id: "MQ1-SQ1-F8",
              //     field_type: "DATEPICKER",
              //     title: "Existing Since",
              //     value: { value: "", warning: false },
              //   },
              // },
            },
          },
        },
      },
      MQ02: {
        main_question: {
          id: "MQ02",
          icon_class: "tobaco",
          title: "Hospitalized",
          description:
            "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ03: {
        main_question: {
          id: "MQ03",
          icon_class: "tobaco",
          title: "claim",
          description:
            "Has any of the new person(s) to be insured been diagnosed / hospitalized for any illness / injury during the last 48 months?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ04: {
        main_question: {
          id: "MQ04",
          icon_class: "tobaco",
          title: "Declined/Cancelled",
          description:
            "Has any proposal for Health Insurance of the new person(s) to be insured, been declined, cancelled or charged a higher premium?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
      MQ05: {
        main_question: {
          id: "MQ05",
          icon_class: "tobaco",
          title: "Other health insurance policy",
          description:
            "Is any of the person(s) to be insured, already covered under any other health insurance policy of Religare Health Insurance?",
          status: false,
          member_data: {
            adult_one: {
              keyName: "adult_one",
              name: INSURED_MEMBER_DETAILS.ADULT_ONE_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            adult_two: {
              keyName: "adult_two",
              name: INSURED_MEMBER_DETAILS.ADULT_TWO_DETAILS.name.value,
              image_class: "adult",
              selection_status: false,
            },
            child_one: {
              keyName: "child_one",
              name: INSURED_MEMBER_DETAILS.CHILD_ONE_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_two: {
              keyName: "child_two",
              name: INSURED_MEMBER_DETAILS.CHILD_TWO_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
            child_three: {
              keyName: "child_three",
              name: INSURED_MEMBER_DETAILS.CHILD_THREE_DETAILS.name.value,

              image_class: "child",
              selection_status: false,
            },
            child_four: {
              keyName: "child_four",
              name: INSURED_MEMBER_DETAILS.CHILD_FOUR_DETAILS.name.value,
              image_class: "child",
              selection_status: false,
            },
          },
        },
      },
    };
    setMedicalQuestionData({ ...setMemberData });
  };

  useEffect(() => {
    if (PAGE_STATUS === true || Preview_Loader === true) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_CARE);
    } else {
      setMemberDataAsPerUserSelection();
    }
  }, [MEDICAL_QUESTION_DATA_CARE]);
  let flag = true;
  console.log(change_status, "sss", flag);

  useEffect(() => { }, []);
  const updateMasterState = (attrName: any, value: any) => {
    // debugger
    flag = value;

    if (attrName[0] == "question" && attrName[1] == "MQ01" && value == true) {
      toast.info("Any pre-existing health condition disclosed during the application process may affect the premium amount")
    }

    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };
      if (value === false) {
        // Update selection_status of members
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data = {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data,
          adult_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_one,
            selection_status: false,
          },
          adult_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .adult_two,
            selection_status: false,
          },
          child_one: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_one,
            selection_status: false,
          },
          child_two: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_two,
            selection_status: false,
          },
          child_three: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_three,
            selection_status: false,
          },
          child_four: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question.member_data
              .child_four,
            selection_status: false,
          },
        };
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);

      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };

      if (value === false) {
        // Check if the attribute name is neither "MQ15-SQ15" nor "MQ16-SQ16"
        if (attrName[2] !== "MQ15-SQ15" && attrName[2] !== "MQ16-SQ16") {
          Object.values(updatedSubQuestion[attrName[2]].member_data).forEach(
            (member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "Existing Since",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
            }
          );
        }
        // If attribute name is "MQ15-SQ15" or "MQ16-SQ16", update specific fields for all members
        else {
          const memberDataKeys = Object.keys(
            updatedSubQuestion[attrName[2]].member_data
          );

          memberDataKeys.forEach((memberKey) => {
            updatedSubQuestion[attrName[2]].member_data[memberKey] = {
              ...updatedSubQuestion[attrName[2]].member_data[memberKey],
              selection_status: false,
              field_data: {
                ...updatedSubQuestion[attrName[2]].member_data[memberKey]
                  .field_data,
                [`${attrName[2]}-F1`]: {
                  id: `${attrName[2]}-F1`,
                  title: "Existing Since",
                  field_type: "DATEPICKER",
                  value: { value: "", warning: false },
                },
                [`${attrName[2]}-F2`]: {
                  id: `${attrName[2]}-F2`,
                  title: "Description",
                  field_type: "TEXTFIELD",
                  value: { value: "", warning: false },
                },
              },
            };
          });
        }
      }

      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };

      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };

      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField") {
      if (
        attrName[4] === "adult_one" ||
        attrName[4] === "adult_two" ||
        attrName[4] === "child_one" ||
        attrName[4] === "child_two" ||
        attrName[4] === "child_three" ||
        (attrName[4] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
        ].member_data[attrName[4]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
  };

  const validateForm = () => {
    // Ensure medicalQuestionData is not null or undefined
    if (!medicalQuestionData) {
      return; // Exit early if medicalQuestionData is null or undefined
    }
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;

      if (mainQuestion) {
        // Check if mainQuestion has subQuestions
        if (mainQuestion.sub_question) {
          Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
            if (subQuestion && subQuestion.status) {
              const hasFalseSelectionStatus = Object.values(subQuestion.member_data).some(
                (memberdata: any) => !memberdata.selection_status
              );
              const hasTrueSelectionStatus = Object.values(subQuestion.member_data).some(
                (memberdata: any) => memberdata.selection_status
              );

              if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
                hasError = true;
                toast.error("Select Sub-Question members");
              }
            }
          });
        } else if (mainQuestion.status && mainQuestion.member_data) {
          // No subQuestions, handle member_data directly
          const hasFalseSelectionStatus = Object.values(mainQuestion.member_data).some(
            (memberdata: any) => !memberdata.selection_status
          );
          const hasTrueSelectionStatus = Object.values(mainQuestion.member_data).some(
            (memberdata: any) => memberdata.selection_status
          );

          if (hasFalseSelectionStatus && !hasTrueSelectionStatus) {
            hasError = true;
            toast.error("Select members");
          }
        }
      }
    });

    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;
      if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (
                  memberdata &&
                  memberdata.selection_status &&
                  memberdata.field_data
                ) {
                  Object.values(memberdata.field_data).forEach(
                    (field_data: any) => {
                      if (field_data && field_data.value?.value === "") {
                        let clonedValue = {
                          ...field_data.value,
                          warning: true,
                        };

                        if (field_data.value) {
                          field_data.value = clonedValue;
                        }
                        hasError = true;
                      }
                    }
                  );
                }
              }
            );
          }
        });
      }
    });

    if (medicalQuestionData.MQ01.main_question.status) {
      if (
        medicalQuestionData.MQ01.main_question.status &&
        medicalQuestionData.MQ01.main_question.sub_question &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ1-SQ1"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ3-SQ3"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ5-SQ5"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ2-SQ2"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ4-SQ4"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ6-SQ6"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ7-SQ7"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ8-SQ8"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ9-SQ9"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ10-SQ10"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ11-SQ11"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ12-SQ12"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ13-SQ13"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ14-SQ14"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ15-SQ15"]?.status &&
        !medicalQuestionData.MQ01.main_question.sub_question["MQ16-SQ16"]?.status
      ) {
        toast.error("Please Select Sub Question");
        hasError = true;
      }
    }

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_CARE(data));

      //api calling
      const onSuccess = (res: any) => {
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        console.log("err..", err);
      };
      console.log(
        data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
          ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value,
        "dfdgdfe"
      );
      let param: any = {
        company_code: SELECTED_QUOTE_DATA.CompanyDetails.company_code,
        quote_no: ADD_FORM_RESPONSE.quote_no,
        product_code: SELECTED_QUOTE_DATA.productDetails.product_code,
        product_type_code: SELECTED_QUOTE_DATA.productDetails.product_type_code,
        medicalPreExistingDiseases:
          `${data.MQ01?.main_question.status}` === "true" ? "Yes" : "No",
        medicalDiabetesDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalDiabetes:
          `${data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_diabetes_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ1-SQ1"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalHypertensionDiseases
        medicalHypertensionDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"].member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalHypertension:
          `${data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_hypertension_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ2-SQ2"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalLiverDiseases
        medicalLiverDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalLiver:
          `${data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_liver_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ3-SQ3"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalHIVDiseases
        medicalHIVDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"].member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalHIV:
          `${data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_hiv_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ4-SQ4"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalCancerDiseases
        medicalCancerDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalCancer:
          `${data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_cancer_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ5-SQ5"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalCardiac
        medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalCardiac:
          `${data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_cardiac_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ6-SQ6"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        medicalJointDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalJoint:
          `${data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_joint_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ7-SQ7"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalKidneyDiseases
        medicalKidneyDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalKidney:
          `${data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_kidney_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ8-SQ8"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalParalysisDiseases
        medicalParalysisDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalParalysis:
          `${data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_paralysis_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ9-SQ9"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalCongenital
        medicalCongenitalDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalCongenital:
          `${data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_congenital_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ10-SQ10"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalRespiratory
        medicalRespiratoryDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalRespiratory:
          `${data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_respiratory_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ11-SQ11"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalEndocrine
        medicalEndocrineDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalEndocrine:
          `${data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_endocrine_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ12-SQ12"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        //medicalMedication
        medicalMedicationDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalMedication:
          `${data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_medication_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ13-SQ13"]?.member_data
            ?.child_four?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalProlonged
        medicalProlongedDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        spouse_medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.adult_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child1_medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_one?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child2_medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_two?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child3_medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_three?.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        child4_medicalProlonged:
          `${data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_prolonged_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ14-SQ14"]?.member_data
            ?.child_four.field_data?.["MQ1-SQ1-F2"]?.value?.value
        ),
        // medicalAlcohol
        medicalAlcoholDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.adult_one?.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.adult_one?.field_data?.["MQ15-SQ15-F2"]?.value?.value,
        spouse_medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.adult_two?.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        spouse_alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.adult_two?.field_data?.["MQ15-SQ15-F2"]?.value?.value,
        child1_medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_one?.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        child1_alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_one?.field_data?.["MQ15-SQ15-F2"]?.value?.value,
        child2_medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_two?.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        child2_alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_two?.field_data?.["MQ15-SQ15-F2"]?.value?.value,
        child3_medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_three?.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        child3_alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_three?.field_data?.["MQ15-SQ15-F2"]?.value?.value,
        child4_medicalAlcohol:
          `${data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data?.child_four.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_alcohol_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_four.field_data?.["MQ15-SQ15-F1"]?.value?.value
        ),
        child4_alcohol_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ15-SQ15"]?.member_data
            ?.child_four?.field_data?.["MQ15-SQ15-F2"]?.value?.value,

        // _medicalOtherDiseases
        medicalOtherDiseasesDiseases:
          `${data.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.status}` ===
            "true"
            ? "Yes"
            : "No",
        medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.adult_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.adult_one?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),

        other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.adult_one?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        spouse_medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.adult_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        spouse_other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.adult_two?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),
        spouse_other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.adult_two?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        child1_medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.child_one?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child1_other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_one?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),
        child1_other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_one?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        child2_medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.child_two?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child2_other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_two?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),
        child2_other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_two?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        child3_medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.child_three?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child3_other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_three?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),
        child3_other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_three?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        child4_medicalOtherDiseases:
          `${data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data?.child_four?.selection_status}` ===
            "true"
            ? "Yes"
            : "No",
        child4_other_diseases_month_year: DateFun(
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_four?.field_data?.["MQ16-SQ16-F1"]?.value?.value
        ),
        child4_other_diseases_desc:
          data?.MQ01?.main_question?.sub_question?.["MQ16-SQ16"]?.member_data
            ?.child_four?.field_data?.["MQ16-SQ16-F2"]?.value?.value,
        //Hospitalized
        medicalHospitalizedDiseases:
          data?.MQ02?.main_question?.status === true ? "Yes" : "No",
        medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "No",
        spouse_medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child1_medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "No",
        child2_medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child3_medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.child_three
            .selection_status === true
            ? "Yes"
            : "No",
        child4_medicalHospitalized:
          data?.MQ02?.main_question?.member_data?.child_four
            .selection_status === true
            ? "Yes"
            : "No",
        //Claim
        medicalClaimDiseases:
          data?.MQ03?.main_question?.status === true ? "Yes" : "No",
        medicalClaim:
          data?.MQ03?.main_question?.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "No",
        spouse_medicalClaim:
          data?.MQ03?.main_question?.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child1_medicalClaim:
          data?.MQ03?.main_question?.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "No",
        child2_medicalClaim:
          data?.MQ03?.main_question?.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child3_medicalClaim:
          data?.MQ03?.main_question?.member_data?.child_three
            .selection_status === true
            ? "Yes"
            : "No",
        child4_medicalClaim:
          data?.MQ03?.main_question?.member_data?.child_four
            .selection_status === true
            ? "Yes"
            : "No",
        //Declined/Cancelled
        medicalCancelDiseases:
          data?.MQ04?.main_question?.status === true ? "Yes" : "No",
        medicalCancel:
          data?.MQ04?.main_question?.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "No",
        spouse_medicalCancel:
          data?.MQ04?.main_question?.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child1_medicalCancel:
          data?.MQ04?.main_question?.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "No",
        child2_medicalCancel:
          data?.MQ04?.main_question?.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child3_medicalCancel:
          data?.MQ04?.main_question?.member_data?.child_three
            .selection_status === true
            ? "Yes"
            : "No",
        child4_medicalCancel:
          data?.MQ05?.main_question?.member_data?.child_four
            .selection_status === true
            ? "Yes"
            : "No",
        //Other health insurance policy
        medicalOtherPolicyDiseases:
          data?.MQ05?.main_question?.status === true ? "Yes" : "No",
        medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.adult_one.selection_status ===
            true
            ? "Yes"
            : "No",
        spouse_medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.adult_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child1_medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.child_one.selection_status ===
            true
            ? "Yes"
            : "No",
        child2_medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.child_two.selection_status ===
            true
            ? "Yes"
            : "No",
        child3_medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.child_three
            .selection_status === true
            ? "Yes"
            : "No",
        child4_medicalOtherPolicy:
          data?.MQ05?.main_question?.member_data?.child_four
            .selection_status === true
            ? "Yes"
            : "No",
        medical_info: "Yes",
      };

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(`${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`);
      // `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
    }
  };

  return isMobile ? (
    <MMedicalDetails
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  ) : (
    // null
    <MedicalDetails
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
