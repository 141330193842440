import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import { useAppDispatch, useAppSelector } from "../../../Store/hooks";
import { TCareMedicalQuestion } from "../../../types/Health/HealthQuotation/TCareMedicalQuestion";
import { isEmpty } from "../../../SupportingFiles/HelpingFunction";
import { THealthQuote } from "../../../types/Health/HealthQuotation/THealthQuote";
import { HEALTH_PROPOSAL_SERVICES } from "../../../Services/HealthServices/HealthPropsalServices";
import { HEALTH_ROUTE } from "../../../Router/Path/HealthRoutes";
import Medicaldetailsoptimarsecure from "../../../Page/Desktop/Health/HDFC/Medicaldetailsoptimarsecure";
import MMedicaldetailsoptimarsecure from "../../../Page/Mobile/Health/HDFC/MMedicaldetailsoptimarsecure";
import { HealthSlice } from "../../../Store/Slice_Reducer/Health/HealthSlice";
import { HDFC_MEDICAL_REQUEST } from "./HDFCMedicalRequest";
import { toast } from "react-toastify";

export default function MedicalDetailsContainerOptimaSecure() {
  const isMobile = useIsMobile();
  const {
    INSURED_MEMBER_DETAILS,
    PAGE_STATUS,
    MEDICAL_QUESTION_DATA_OPTIMA_SECURE,
    SELECTED_QUOTE_DATA,
    ADD_FORM_RESPONSE,
    Preview_Loader,
  } = useAppSelector((state) => state.Health);
  const [medicalQuestionData, setMedicalQuestionData] =
    useState<TCareMedicalQuestion>(MEDICAL_QUESTION_DATA_OPTIMA_SECURE);
  console.log("medicalQuestionData", medicalQuestionData)
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [change_status, setchange_status] = useState(false);

  function deepCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  const updateMemberNames = (
    data: TCareMedicalQuestion,
    memberDetails: any
  ) => {
    // Create a deep copy of the data object to ensure immutability

    const updatedData = deepCopy(data);

    // Update the main question member names
    Object.values(updatedData).forEach((question: any) => {
      Object.values(question.main_question.member_data).forEach(
        (member: any) => {
          const memberKeyName = member.keyName.toUpperCase() + "_DETAILS";
          if (
            memberDetails[memberKeyName] &&
            memberDetails[memberKeyName].name &&
            memberDetails[memberKeyName].name.value
          ) {
            // Update the name property of the member in the new object
            member.name = memberDetails[memberKeyName].name.value;
          } else {
            member.name = "";
          }
        }
      );
    });

    // Update the sub question member names
    Object.values(updatedData).forEach((question: any) => {
      question.main_question.sub_question &&
        Object.values(question.main_question.sub_question).forEach(
          (sub_data: any) => {
            sub_data.member_data &&
              Object.values(sub_data.member_data).forEach((member: any) => {
                const memberKeyName = member.keyName.toUpperCase() + "_DETAILS";
                if (
                  memberDetails[memberKeyName] &&
                  memberDetails[memberKeyName].name &&
                  memberDetails[memberKeyName].name.value
                ) {
                  // Update the name property of the member in the new object
                  member.name = memberDetails[memberKeyName].name.value;
                } else {
                  member.name = "";
                }
              });
          }
        );
    });

    return updatedData; // Return the updated data
  };

  console.log(medicalQuestionData?.MQ06?.main_question, "medicalQuestionData");

  useEffect(() => {
    if (PAGE_STATUS) {
      setMedicalQuestionData(MEDICAL_QUESTION_DATA_OPTIMA_SECURE);
    } else {
      const updatedData = updateMemberNames(
        MEDICAL_QUESTION_DATA_OPTIMA_SECURE,
        INSURED_MEMBER_DETAILS
      );
      setMedicalQuestionData(updatedData);
    }
  }, []);
  let flag = true;

  const updateMasterState = (attrName: any, value: any) => {
    console.log(attrName, "attrName", medicalQuestionData);
    flag = value;
    let manipulatedMedicalQuestion: any = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    console.log(attrName, "attrName", manipulatedMedicalQuestion, value)
    if (attrName[0] === "question") {
      console.log(manipulatedMedicalQuestion[attrName[1]], "Before Update");
      manipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: {
            ...manipulatedMedicalQuestion[attrName[1]].main_question,
            status: value,
          },
        },
      };

      if (value === false && attrName[1] === "MQ02") {
        if (attrName[1] !== "MQ17-SQ17" && attrName[1] !== "MQ17-SQ17") {
          manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
            Object.values(
              manipulatedMedicalQuestion[attrName[1]]?.main_question
                ?.member_data
            ).forEach((member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F1"] = {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "SurgeryDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F3"] = {
                id: "MQ1-SQ1-F3",
                title: "LastConsulatationDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F4"] = {
                id: "MQ1-SQ1-F4",
                title: "CurrentStatus",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F5"] = {
                id: "MQ1-SQ1-F5",
                title: "LifeOfManagement",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F6"] = {
                id: "MQ1-SQ1-F6",
                title: "DetailsOfTreatment",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F7"] = {
                id: "MQ1-SQ1-F7",
                title: "IsMedicalQuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
            });
        }
      } else {
        if (value === false && attrName[1] === "MQ03") {
          manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
            Object.values(
              manipulatedMedicalQuestion[attrName[1]]?.main_question
                ?.member_data
            ).forEach((member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F1"] = {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "SurgeryDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F3"] = {
                id: "MQ1-SQ1-F3",
                title: "LastConsulatationDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F4"] = {
                id: "MQ1-SQ1-F4",
                title: "CurrentStatus",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F5"] = {
                id: "MQ1-SQ1-F5",
                title: "LifeOfManagement",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F6"] = {
                id: "MQ1-SQ1-F6",
                title: "IsMedicalQuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
            });
        } else if (value === false && attrName[1] === "MQ04") {
          manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
            Object.values(
              manipulatedMedicalQuestion[attrName[1]]?.main_question
                ?.member_data
            ).forEach((member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F1"] = {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "SurgeryDate",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
            });
        } else if (value === false && attrName[1] === "MQ05") {
          manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data &&
            Object.values(
              manipulatedMedicalQuestion[attrName[1]]?.main_question
                ?.member_data
            ).forEach((member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F1"] = {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "IsMedicalQuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
            });
        }
      }
      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    } else if (attrName[0] === "subQuestion") {
      setchange_status(flag);
      let updatedSubQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question,
        [attrName[2]]: {
          ...manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
          ],
          status: value,
        },
      };
      if (value === false) {
        // Check if the attribute name is neither "MQ15-SQ15" nor "MQ16-SQ16"
        if (attrName[2] !== "MQ17-SQ17" && attrName[2] !== "MQ17-SQ17") {
          Object.values(updatedSubQuestion[attrName[2]].member_data).forEach(
            (member: any) => {
              member.selection_status = false;
              member.field_data["MQ1-SQ1-F1"] = {
                id: "MQ1-SQ1-F1",
                title: "ExactDiagnosis",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F2"] = {
                id: "MQ1-SQ1-F2",
                title: "SurgeryDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F3"] = {
                id: "MQ1-SQ1-F3",
                title: "LastConsulatationDate",
                field_type: "DATEPICKER",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F4"] = {
                id: "MQ1-SQ1-F4",
                title: "CurrentStatus",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F5"] = {
                id: "MQ1-SQ1-F5",
                title: "LifeOfManagement",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F6"] = {
                id: "MQ1-SQ1-F6",
                title: "DetailsOfTreatment",
                field_type: "TEXTFIELD",
                value: { value: "", warning: false },
              };
              member.field_data["MQ1-SQ1-F7"] = {
                id: "MQ1-SQ1-F7",
                title: "IsMedicalQuestionOpted",
                field_type: "DROPDOWN",
                value: { value: "", warning: false },
              };
            }
          );
        }
      }
      let updatedMainQuestion = {
        ...manipulatedMedicalQuestion[attrName[1]].main_question,
        sub_question: updatedSubQuestion,
      };
      let updatedManipulatedMedicalQuestion = {
        ...manipulatedMedicalQuestion,
        [attrName[1]]: {
          ...manipulatedMedicalQuestion[attrName[1]],
          main_question: updatedMainQuestion,
        },
      };
      setMedicalQuestionData(updatedManipulatedMedicalQuestion);
    } else if (attrName[0] === "memberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question.sub_question[
          attrName[2]
        ]?.member_data[attrName[3]] !== undefined
      ) {
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              sub_question: {
                ...manipulatedMedicalQuestion[attrName[1]].main_question
                  .sub_question,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    .sub_question?.[attrName[2]],
                  member_data: {
                    ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                      .sub_question?.[attrName[2]].member_data,
                    [attrName[3]]: {
                      ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                        .sub_question?.[attrName[2]].member_data?.[attrName[3]],
                      selection_status: value,
                    },
                  },
                },
              },
            },
          },
        };
        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    } else if (attrName[0] === "mainMemberData") {
      if (
        manipulatedMedicalQuestion[attrName[1]]?.main_question?.member_data[
        attrName[2]
        ] !== undefined
      )
        manipulatedMedicalQuestion = {
          ...manipulatedMedicalQuestion,
          [attrName[1]]: {
            ...manipulatedMedicalQuestion[attrName[1]],
            main_question: {
              ...manipulatedMedicalQuestion[attrName[1]]?.main_question,
              member_data: {
                ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                  .member_data,
                [attrName[2]]: {
                  ...manipulatedMedicalQuestion[attrName[1]]?.main_question
                    ?.member_data?.[attrName[2]],
                  selection_status: value,
                },
              },
            },
          },
        };

      setMedicalQuestionData(manipulatedMedicalQuestion);
    } else if (attrName[0] === "mainMemberDataField" && attrName[1] == "MQ06") {
      if (
        attrName[4] === "adult_one" ||
        attrName[4] === "adult_two" ||
        attrName[4] === "child_one" ||
        attrName[4] === "child_two" ||
        attrName[4] === "child_three" ||
        (attrName[4] === "child_four" &&
          manipulatedMedicalQuestion !== undefined)
      ) {
        manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
          attrName[2]
        ].member_data[attrName[4]].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      } else {
        if (
          attrName[2] === "adult_one" ||
          attrName[2] === "adult_two" ||
          attrName[2] === "child_one" ||
          attrName[2] === "child_two" ||
          attrName[2] === "child_three" ||
          (attrName[2] === "child_four" &&
            manipulatedMedicalQuestion !== undefined)
        ) {
        }
        manipulatedMedicalQuestion[attrName[1]].main_question.member_data[
          attrName[2]
        ].field_data[attrName[3]].value = {
          value: value,
          warning: isEmpty(value),
        };

        setMedicalQuestionData({ ...manipulatedMedicalQuestion });
      }
    }
    else if (attrName[0] === "mainMemberDataField" && attrName[1] == "MQ06") {

      manipulatedMedicalQuestion[attrName[1]].main_question.sub_question[
        attrName[4]
      ].member_data[attrName[2]].field_data[attrName[3]].value = {
        value: value,
        warning: isEmpty(value),
      };

      setMedicalQuestionData({ ...manipulatedMedicalQuestion });
    }
  };

  const validateForm = () => {
    // Deep copy the medicalQuestionData object to avoid state mutations
    let data: TCareMedicalQuestion = JSON.parse(
      JSON.stringify(medicalQuestionData)
    );
    let hasError = false;



    Object.values(data).forEach((element: any) => {
      const mainQuestion = element?.main_question;

      console.log(mainQuestion, "mainQuestion")

      // if (data?.mainQuestion?.id === "MQ06" && medicalQuestionData.MQ02.main_question.status
      // ) {
      //   // console.log(medicalQuestionData.MQ02.main_question.sub_question["MQ1-SQ1"].status, "prabh3")
      //   if (
      //     medicalQuestionData.MQ06.main_question.status &&
      //     medicalQuestionData.MQ06.main_question.sub_question &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ1-SQ1"]?.status &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ2-SQ2"]?.status &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ3-SQ3"]?.status &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ4-SQ4"]?.status &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ5-SQ5"]?.status &&
      //     !medicalQuestionData.MQ06.main_question.sub_question["MQ6-SQ6"]?.status
      //   ) {
      //     toast.error("Please Select Sub Question");
      //     hasError = true;
      //   }
      // }


      if (mainQuestion && mainQuestion.status && mainQuestion.sub_question) {
        Object.values(mainQuestion.sub_question).forEach((subQuestion: any) => {
          if (subQuestion && subQuestion.status) {
            Object.values(subQuestion.member_data).forEach(
              (memberdata: any) => {
                if (
                  memberdata &&
                  memberdata.selection_status &&
                  memberdata.field_data
                ) {
                  Object.values(memberdata.field_data).forEach(
                    (field_data: any) => {
                      if (field_data && field_data.value?.value === "") {
                        let clonedValue = {
                          ...field_data.value,
                          warning: true,
                        };
                        if (field_data.value) {
                          field_data.value = clonedValue;
                        }

                        hasError = true;
                      }
                    }
                  );
                }
              }
            );
          }
        });
      } else {
        if (mainQuestion && mainQuestion.member_data) {
          Object.values(mainQuestion.member_data).forEach((memberdata: any) => {
            if (
              memberdata &&
              memberdata.selection_status &&
              memberdata.field_data
            ) {
              Object.values(memberdata.field_data).forEach(
                (field_data: any) => {
                  if (field_data && field_data.value?.value === "") {
                    let clonedValue = {
                      ...field_data.value,
                      warning: true,
                    };

                    if (field_data.value) {
                      field_data.value = clonedValue;
                    }
                    hasError = true;
                  }
                }
              );
            }
          });
        }
      }
    });

    setMedicalQuestionData(data);
    if (!hasError) {
      dispatch(
        HealthSlice.actions.SAVE_MEDICAL_QUESTION_DATA_HDFC_HEALTH(data)
      );

      //api calling
      const onSuccess = (res: any) => {
        if (res.results.error === false) {
          let quoteData: THealthQuote = SELECTED_QUOTE_DATA;
          quoteData = {
            ...quoteData,
            premiumDetails: {
              ...quoteData.premiumDetails,
              finalPremium: res.results.response.premiumDetails.finalPremium,
            },
          };
          dispatch(HealthSlice.actions.SAVE_SELECTED_QUOTE_DATA(quoteData));
        }
      };
      const onError = (err: any) => {
        console.log("err..", err);
      };

      const param = HDFC_MEDICAL_REQUEST(
        data,
        ADD_FORM_RESPONSE,
        SELECTED_QUOTE_DATA
      );

      HEALTH_PROPOSAL_SERVICES.getMedicalData(onSuccess, onError, param);
      navigate(
        `${HEALTH_ROUTE.PROPOSAL_PAGE}/${HEALTH_ROUTE.PREVIEW_DETAILS}?type=HP&companyCode=${SELECTED_QUOTE_DATA?.CompanyDetails.company_code}&quote_no=${ADD_FORM_RESPONSE.quote_no}&product_id=${SELECTED_QUOTE_DATA?.productDetails.id}`
      );
    }
  };

  return isMobile ? (
    <MMedicaldetailsoptimarsecure
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  ) : (
    <Medicaldetailsoptimarsecure
      medicalQuestionData={medicalQuestionData}
      updateMasterState={updateMasterState}
      validateForm={validateForm}
      PAGE_STATUS={PAGE_STATUS}
    />
  );
}
